import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import { DatePicker, Select, Space } from "antd";
import * as FileSaver from "file-saver";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useGetBreakdownListQuery, useGetImporterListQuery } from "../../../state/api";
const { RangePicker } = DatePicker;
const BreakdownDetails = () => {
  const [pageNo, setPageNo] = useState(0);
  const [pageLength, setPageLength] = useState(5);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const importerListDataForDropdown = useGetImporterListQuery({ pageLength: 9999, pageNo: 0, search: "" });
  const [ImporterListForDropdownMenu, setImporterListForDropdownMenu] = useState([]);
  const [importerNameForFilter, setImporterNameForFilter] = useState("");
  const { data: breakDownList } = useGetBreakdownListQuery({
    importerName: importerNameForFilter,
    startDate: startDate,
    endDate: endDate,
    pageLength: pageLength,
    pageNo: pageNo
  });

  const breakdownHeaders = [
    "Date",
    "CN Code",
    "Product Code",
    "Product Name",
    "Mass in MT",
    "Facility Name",
    "Facility UUID"
  ];
  const onChange = (dates, dateStrings) => {
    const startDateUTC = new Date(dateStrings[0]).toISOString();
    const endDateUTC = new Date(dateStrings[1]).toISOString();
    setStartDate(startDateUTC);
    setEndDate(endDateUTC);
  };

  const exportToExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    FileSaver.saveAs(blob, fileName);
  };

  const handleExport = () => {
    const data = breakDownList?.data?.list;
    const fileName = "BreakDownList.xlsx";
    exportToExcel(data, fileName);
  };

  useEffect(() => {
    const sortedActiveListWithIdAndLabel = importerListDataForDropdown?.data?.data?.data
      ?.filter((item) => item.status === "Active")
      .map((item) => {
        return item.importerName;
      });
    if (sortedActiveListWithIdAndLabel) {
      setImporterListForDropdownMenu(sortedActiveListWithIdAndLabel);
    }
  }, [importerListDataForDropdown]);

  const handleImporterChange = (e) => {
    setImporterNameForFilter(e);
  };

  const [breakDownListState, setBreakDownList] = useState([]);
  useEffect(() => {
    setBreakDownList(breakDownList?.data?.list);
  }, [breakDownList]);

  useEffect(() => {}, [breakDownListState]);

  const tableRows = breakDownListState?.map((item, index) => (
    <TableRow key={item._id}>
      <TableCell
        sx={{
          minWidth: "100px",
          width: "fit-content",
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit"
        }}
        className="text-center"
      >
        <div>{new Date(item.date).toLocaleDateString()}</div>
      </TableCell>
      <TableCell
        sx={{
          minWidth: "100px",
          width: "fit-content",
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit"
        }}
        className="text-center"
      >
        {item.cncode}
      </TableCell>
      <TableCell
        sx={{
          minWidth: "140px",
          width: "fit-content",
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit"
        }}
        className="text-center"
      >
        {item.productCode}
      </TableCell>
      <TableCell
        sx={{
          minWidth: "150px",
          width: "fit-content",
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit"
        }}
        className="text-center"
      >
        {item?.productName}
      </TableCell>
      <TableCell
        sx={{
          minWidth: "100px",
          width: "fit-content",
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit"
        }}
        className="text-center"
      >
        {item?.weight}
      </TableCell>
      <TableCell
        sx={{
          minWidth: "150px",
          width: "fit-content",
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit"
        }}
        className="text-center"
      >
        {item?.facilityName}
      </TableCell>
      <TableCell
        sx={{
          minWidth: "150px",
          width: "fit-content",
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit"
        }}
        className="text-center"
      >
        {item?._id}
      </TableCell>
    </TableRow>
  ));

  const noDataMessage = (
    <TableRow>
      <TableCell colSpan={7}>
        {importerNameForFilter === ""
          ? "Choose Importer Name and Date Range to view data of a specific Importer"
          : "No results found. Please search using different fields"}
      </TableCell>
    </TableRow>
  );

  return (
    <div>
      <div className="scopeDesc">
        <p className="page-header-text">Breakdown Details</p>
        <p style={{ fontSize: "16px" }}>By entering the importer name and EORI Code get the Breakdown Details</p>
      </div>
      <div className="mt-3 report-detail-container">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-1 flex-fill" style={{ maxWidth: "80%" }}>
            <div className="filters-container">
              <Select
                onChange={handleImporterChange}
                placeholder="Importer Name"
                optionFilterProp="facility"
                size="large"
              >
                {ImporterListForDropdownMenu?.map((item, index) => {
                  return <Select.Option value={item}>{item}</Select.Option>;
                })}
              </Select>
              <div>
                <Space style={{ height: "100%" }} direction="vertical" size={20}>
                  <RangePicker format="YYYY-MM-DD" onChange={onChange} style={{ height: "2.4rem" }} />
                </Space>
              </div>
            </div>
          </div>
          <div className="ml-0">
            <button onClick={handleExport} className="mx-0 btn btn-success">
              Download list in Excel
            </button>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <TableContainer style={{ background: "white" }}>
          <Table
            sx={{ minWidth: 650, minHeight: breakDownList?.data?.list?.length === 0 ? 600 : "" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {breakdownHeaders?.map((item, key) => (
                  <TableCell
                    key={key + "0"}
                    className={`fw-bold ${item === "Date" ? "text-center" : "text-center"} ${
                      item === "Generate Form" ? "sticky-table-cell" : ""
                    }`}
                    sx={{ right: "4rem" }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className="text-nowrap">
              {breakDownList?.data?.list?.length > 0 ? tableRows : noDataMessage}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  count={breakDownList?.data?.totalCount ? breakDownList?.data?.totalCount : 0}
                  page={pageNo ? pageNo : 0}
                  rowsPerPageOptions={[5, 10, 25]}
                  rowsPerPage={pageLength ? pageLength : 0}
                  onPageChange={(e, value) => setPageNo(value)}
                  onRowsPerPageChange={(e) => {
                    setPageLength(e.target.value);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
export default BreakdownDetails;
