export const dataQulaityEndPoint = (build) => ({
      
    getDataQuality:build.query({
        query:(arg)=>({
         url: `quality/esg`,
         params: { ...arg }
        }),
        
        providesTags: ["Esg Cards"]
    }),

    getEnvDataQuality:build.query({
        query:(arg)=>({
         url: `quality`,
         params: { ...arg }
        }),
        providesTags: ["Cards"]
    }),

     getEnvBreakdownData:build.query({
        query:(arg)=>({
            url: `quality/breakdown`,
            params: { ...arg }
            }),
            providesTags: ["Env Cards"]
        }),

     getEsgBreakdown:build.query({
        query:(arg)=>({
            url: `quality/breakdown/esg`,
            params: { ...arg }
            }),
            providesTags: ["BreakDown Cards"]
        }),
    
     getDataQualityFilterData:build.query({
        query:(arg)=>({
            url:`quality/filter`,
            params:{...arg}
        }),
        providesTags: ["Filter Cards"]
     }),
     
     getEsgFilterData:build.query({
        query:(arg)=>({
            url:`quality/kpi/filter`,
            params:{...arg}
        }),
        providesTags: ["Esg Filter Cards"]
     })



})