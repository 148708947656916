import { createSlice } from "@reduxjs/toolkit";

const initialCorruptionState = {
    
  formWiseData: {
    antiCorruption: {

      operations:{
        accordionTitle:"Operations assessed for risks related to corruption",
        operationsAssessed: [
            {
              heading: "Operation assessed for the risk of corruption",
              totalNumber: "",
              percentage: ""
            }
          ],
    
          significantRiskForeword: "",
      },

      communicationAndTraining:{
        accordionTitle:"Communication and training about anti-corruption policies and procedures",
        
        governanceBodyMembers: [
            {
              region: "",
              totalNumber: "",
              percentage: "",
              some:""
            }
          ],
  
        employeeCategoryAndRegion: [
            {
              region: "",
              employeeCategory: "",
              gender: "",
              totalNumber: "",
              percentage: ""
            }
          ],
  
        businessPartnerCategory: [
            {
              region: "",
              businessPartnerCategory: "",
              totalNumber: "",
              percentage: ""
            }
          ],
  
  
        totalRecievedTraningBodyMembers: [
            {
              region: "",
              totalNumber: "",
              percentage: ""
            }
          ],
  
  
        totalRecievedTraningEmployees: [
            {
              region: "",
              employeeCategory: "",
              gender: "",
              totalNumber: "",
              percentage: ""
            }
          ],
      }, 

      actionsTaken:{
        accordionTitle:"Confirmed incidents of corruption and actions taken",
        confirmedIncidents: [
          {
            heading: "Confirmed incidents of corruption (CIC)",
            totalnumberOfCIC: "",
            natureOfCIC: "",
            totalNumberEmployeesDismissed: "",
            totalNumberEmployeesDisciplined: "",
            totalNumberPartnersTerminated: ""
          }
        ],
  
        publiclegal: ""
      },

    }
  }
};
export const corruptionSlice = createSlice({
  name: "corruption",
  initialState: initialCorruptionState,
  reducers: {
    setFormWiseData: (state, action) => {
      return {
        ...state,
        formWiseData: action.payload
      };
    }
  }
});
export const { setFormWiseData } = corruptionSlice.actions;

export default corruptionSlice.reducer;
