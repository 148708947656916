export const offsetEndpoints = (build) => ({
  getOffsetProjects: build.query({
    query: (arg) => ({
      url: `/offset/list`,
      params: { ...arg }
    }),
    providesTags: ["Offsetprojects"]
  }),

  getOffsetFilters: build.query({
    query: (arg) => ({
      url: `/offset/filter`,
      params: { ...arg }
    }),
    providesTags: ["Offsetfilters"]
  }),

  getOffsetYearFilter: build.query({
    query: (arg) => ({
      url: `/offset/years`,
      params: { ...arg }
    }),
    providesTags: ["Offsetyears"]
  }),

  getOffsetTransactionFilter: build.query({
    query: (arg) => ({
      url: `/offset/transaction/filter`,
      params: { ...arg }
    }),
    providesTags: ["Offsettransactionfilters"]
  }),

  getOffsetTransactions: build.query({
    query: (arg) => ({
      url: `/offset/transaction`,
      params: { ...arg }
    }),
    providesTags: ["Offsettransactions"]
  }),
  getTotalOffset: build.query({
    query: (arg) => ({
      url: `/offset/totalOffset`,
      params: { ...arg }
    }),
    providesTags: ["Totaloffset"]
  }),

  postOffsetTransactionData: build.mutation({
    query: (postBody) => ({
      url: `offset/transaction`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Offsettransactions", "Offsettransactionfilters"]
  })
});
