import { MoreVert } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
import { Select } from "antd";
import moment from "moment";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import editIcon from "../../../../src/images/editIcon.svg";
import trash from "../../../../src/images/trash.svg";
import copy from "../../../../src/images/copy.svg";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import DeleteModal from "../../../components/ModalDelete";
import { useSnackbar } from "../../../components/snackbar/SnackbarContext";
import {
  useDeleteInvoiceMutation,
  useDeleteProductCBAMMutation,
  useGetImporterListQuery,
  useGetInvoiceListQuery,
  useGetInvoiceQuery,
  useGetProductDropdownListQuery,
  useGetProductListQuery,
  useLazyGetUserDetailByIdQuery
} from "../../../state/api";
import AddInvoiceModal from "./Component/AddInvoiceModal.jsx";
import "./Invoice.scss";
import { useSelector } from "react-redux";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";
const weekFormat = "MM/DD";
const monthFormat = "YYYY/MM";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [ImporterListForDropdownMenu, setImportrLostfroDropdownMenu] = useState([]);
  const importerListDataForDropdown = useGetImporterListQuery({ pageLength: 9999, pageNo: 0, search: "" });
  const { data: ProductListData, refetch: refetchProductList } = useGetProductDropdownListQuery();
  const [addInvoiceModal, setAddInvoiceModal] = useState(false);
  const [isGenerateForm, setIsGenerateForm] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState();
  const [productCount, setProductCount] = useState(0);
  const getProductList = useGetProductListQuery({ pageLength: 9999, pageNo: 0, search: "" });
  const [deleteProduct] = useDeleteProductCBAMMutation();
  const { openSnackbar } = useSnackbar();
  const [tableInvoiceData, setTableInvoiceData] = useState([]);
  const [productName, setproductName] = useState("");
  const [importerName, setimporterName] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const orgHeaders = ["Date", "Invoice No.", "Importer Name", "Product Name", "Net weight", "Generate Form", "Action"];
  const menuOptions = ["Edit", "Duplicate", "Delete"];
  const headers = ["Date", "Invoice No", "Importer Name", "Product Name", "Net Weight", "Generate Form", "Action"];
  const { data: FetchedInvoiceData, refetch: refetchInvoiceData } = useGetInvoiceListQuery({
    pageLength,
    pageNo,
    search: searchTerm,
    importerName,
    productName,
    startDate,
    endDate
  });
  const [deleteInvoice] = useDeleteInvoiceMutation();
  const { data: InvoiceDataById, refetch } = useGetInvoiceQuery(currentInvoice, {
    skip: !currentInvoice
  });

  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null)
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery()

  useEffect(() => {
    getUser({ id: userId._id }, true)
  }, [])

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data)
    }
  }, [userResult.data?.data])

  useEffect(() => {
    setTableInvoiceData(FetchedInvoiceData?.data?.invoices);
  }, [FetchedInvoiceData?.data?.invoices]);

  useEffect(() => {
    const sortedActiveListWithIdandLabel = importerListDataForDropdown?.data?.data?.data
      ?.filter((item) => item.status === "Active") // Filter items with status "Active"
      .map((item) => {
        return item.importerName;
      });
    if (sortedActiveListWithIdandLabel) {
      console.log("sortedActiveListWithIdandLabel", sortedActiveListWithIdandLabel);
      setImportrLostfroDropdownMenu(sortedActiveListWithIdandLabel);
    }
  }, [importerListDataForDropdown]);

  useEffect(() => {
    // setProductData(getProductList.data?.data?.products);
    setProductCount(getProductList.data?.data?.totalCount);
  }, [getProductList.data]);

  useEffect(() => {
    if (!anchorEl && !addInvoiceModal && !isDeleteOpen) setCurrentInvoice(null);
  }, [anchorEl, addInvoiceModal, isDeleteOpen]);

  const { RangePicker } = DatePicker;
  const onChange = (dates, dateStrings) => {
    // Convert the selected dates to UTC format
    const startDateUTC = moment(dates[0]).utc().format();
    const endDateUTC = moment(dates[1]).utc().format();
    setstartDate(startDateUTC);
    setEndDate(endDateUTC);
    console.log("Selected Dates in UTC:", startDateUTC, endDateUTC);
    refetchInvoiceData();
  };

  const tableRows = tableInvoiceData?.map((item, index) => (
    <TableRow key={item._id}>
      <TableCell sx={{ fontSize: "14px", color: "#16161e", textAlign: "left" }}>
        {new Date(item.invoiceDate).toLocaleDateString()}
      </TableCell>
      <TableCell sx={{ fontSize: "14px", color: "#16161e" }}>{item.invoiceNumber}</TableCell>
      <TableCell sx={{ fontSize: "14px", color: "#16161e" }}>{item.importer.importerName}</TableCell>
      {/* <TableCell sx={{ fontSize: "14px", color: "#16161e" }}>{item?.orders?.product?.productName}</TableCell> */}
      <TableCell className="text-center">
        {item?.orders?.product?.length > 1 ? (
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "auto"
            }}
          >
            <Tooltip
              title={item?.orders?.product.map((item, index) => (
                <div>{item?.productName}</div>
              ))}
            >
              <div
                style={{
                  paddingLeft: ".7rem",
                  paddingRight: ".7rem",
                  paddingTop: ".3rem",
                  paddingBottom: ".3rem",
                  backgroundColor: "#F2F3F7",
                  color: item.status === "Inactive" ? "#C6CBD9" : "#535362",
                  borderRadius: "12px",
                  marginRight: ".3rem",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                Products{" "}
                <div
                  style={{
                    borderRadius: "50%",
                    // padding: ".5rem",
                    backgroundColor: "#7E7E8F",
                    color: "white",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: ".5rem"
                  }}
                >
                  {item?.orders?.product?.length}
                </div>
              </div>
            </Tooltip>
          </div>
        ) : (
          item?.orders?.product?.map((products, index) => (
            <span
              key={index}
              style={{
                paddingLeft: ".7rem",
                paddingRight: ".7rem",
                paddingTop: ".3rem",
                paddingBottom: ".3rem",
                backgroundColor: "#F2F3F7",
                color: item.status === "Inactive" ? "#C6CBD9" : "#535362",
                borderRadius: "12px",
                marginRight: ".3rem"
              }}
            >
              {products?.productName}
            </span>
          ))
        )}
      </TableCell>
      <TableCell sx={{ fontSize: "14px", color: "#16161e" }}>{`${item.netWeight} Ton`}</TableCell>
      <TableCell className="sticky-table-cell" sx={{ right: "3.5rem" }}>
        {item?.uploadUri?.length > 2 ? (
          <a href={item?.uploadUri} download={`Invoice no. ${InvoiceDataById?.data?.importer?.importerName}`}>
            <button
              style={{
                border: "0px solid #1671ca",
                padding: "4px 12px",
                background: "#1F93EF",
                color: "white",
                borderRadius: "8px",
                fontWeight: "500"
              }}
            >
              Download
            </button>
          </a>
        ) : (
          <button
            onClick={() => {
              setIsGenerateForm(true);
              setAddInvoiceModal(true);
              setCurrentInvoice(item._id);
            }}
            style={{
              border: "1px solid #1671ca",
              padding: "4px 12px",
              background: "white",
              color: "#1671ca",
              borderRadius: "8px",
              fontWeight: "500"
            }}
          >
            Generate
          </button>
        )}
      </TableCell>
      {user?.userGroup?.permissions?.cbam?.editInvoice &&
        <TableCell className="sticky-table-cell" sx={{ right: "0" }}>
          <IconButton
            aria-label="more"
            id="long-button"
            key={item._id}
            aria-haspopup="true"
            onClick={(event) => handleOptionsOpen(event, item)}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button"
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(false)}
            PaperProps={{
              style: {
                maxHeight: 40 * 4.5,
                width: "20ch"
              }
            }}
          >
            {[
              {
                icon: editIcon, // Assuming you have an EditIcon component
                text: "Edit Invoice"
              },
              {
                icon: copy, // Assuming you have a DuplicateIcon component
                text: "Duplicate Invoice"
              },
              {
                icon: trash, // Assuming you have a DeleteIcon component
                text: "Delete Invoice"
              }
            ]?.map((option) => (
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", paddingLeft: "1rem" }}>
                {" "}
                <img src={option.icon} alt="" />
                <MenuItem sx={{ marginLeft: "-.6rem" }} key={option} onClick={() => handleOptions(option.text)}>
                  {option.text}
                </MenuItem>
              </div>
            ))}
          </Menu>
        </TableCell>}
    </TableRow>
  ));

  const noDataMessage = (
    <TableRow>
      <TableCell colSpan={7}>
        {searchTerm === ""
          ? "Click on Add new button to add an Invoice in the list"
          : "No results found. Please search using different fields"}
      </TableCell>
    </TableRow>
  );

  const handleImporterChange = (e) => {
    console.log("rcfkbjfsd", e);
    setimporterName(e);
  };

  const handleProductChange = (e) => {
    console.log("gfddfsfsdd", e);
    setproductName(e);
  };

  const handleOptions = (option) => {
    setIsGenerateForm(false);
    if (option === "Edit Invoice") {
      setAddInvoiceModal(true);
    }
    if (option === "Delete Invoice") {
      setIsDeleteOpen(true);
    }
    if (option === "Duplicate Invoice") {
      // openSnackbar("Product Duplicated Successfully", "success");
      setIsDuplicate(true);
      setAddInvoiceModal(true);
    }
    setAnchorEl(null);
  };

  const handleOptionsOpen = (event, item) => {
    console.log("id is : ", item._id);
    setCurrentInvoice(item._id);
    setAnchorEl(event.currentTarget);
  };

  const handleSearchChange = (e) => {
    console.log("handleSearchChange", e.target.value);
    setSearchTerm(e.target.value);
  };

  const handleProductDelete = async () => {
    const response = await deleteInvoice(currentInvoice);
    // console.log("to deleted currentInvoice", response);
    openSnackbar(response?.data?.message, "success");
    setIsDeleteOpen(false);
  };

  return (
    <div className="invoice-container">
      <DeleteModal
        openModal={isDeleteOpen}
        setModalOpen={setIsDeleteOpen}
        currentItem={currentInvoice}
        handleFileDelete={handleProductDelete}
        heading={"Are you sure you want to delete this invoice?"}
        itemName={tableInvoiceData?.filter((item) => item._id === currentInvoice)[0]?.invoiceNumber}
        productId={currentInvoice}
        setProductId={setCurrentInvoice}
        isDuplicate={isDuplicate}
        setIsDuplicate={setIsDuplicate}
      />
      <AddInvoiceModal
        modalOpen={addInvoiceModal}
        setModalOpen={setAddInvoiceModal}
        invoiceId={currentInvoice}
        setProductId={setCurrentInvoice}
        isDuplicate={isDuplicate}
        // cnCodeList={data?.data}
        setIsDuplicate={setIsDuplicate}
        isGenerateForm={isGenerateForm}
        productInitState={invoiceData?.filter((item) => item.id === currentInvoice)[0]}
      />
      <div className="scopeDesc">
        <p className="page-header-text">Invoice List</p>
        <p style={{ fontSize: "16px" }}>Add list of all your invoices below</p>
      </div>
      <div className="mt-3 report-detail-container">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-1 flex-fill" style={{ maxWidth: "80%" }}>
            <div className="me-10 flex-fill" style={{ width: "15rem" }}>
              <TextField
                id="search"
                type="search"
                label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ background: "white", border: "none", width: "15rem" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ cursor: "pointer" }}>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{
                  style: { top: "-6" }
                }}
                className="ps-search-bar"
              />
            </div>
            <div className="filters-container" style={{ width: "74%" }}>
              <Select
                onChange={handleImporterChange}
                placeholder="Importer Name"
                optionFilterProp="facility"
                size="large"
              >
                <Select.Option value={""}>All</Select.Option>
                {ImporterListForDropdownMenu?.map((item, index) => {
                  return <Select.Option value={item}>{item}</Select.Option>;
                })}
              </Select>
              <Select
                onChange={handleProductChange}
                placeholder="Product Name"
                optionFilterProp="facility"
                size="large"
              >
                <Select.Option key="all" value="">
                  All
                </Select.Option>
                {ProductListData?.data?.map(
                  (item, index) =>
                    // Check if the length of the item is greater than 2
                    item?.length > 2 && (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    )
                )}
              </Select>
              <div>
                <Space style={{ height: "100%" }} direction="vertical" size={20}>
                  <RangePicker
                    format="YYYY-MM-DD"
                    onChange={onChange}
                    style={{ height: "2.4rem" }} // Make the RangePicker take full height
                  />
                </Space>
              </div>
            </div>
          </div>
          <div className="ml-0">
            <button
              className="mx-0 btn btn-success"
              onClick={() => {
                setAddInvoiceModal(true);
                setIsGenerateForm(false);
              }}
            >
              + Add New
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <TableContainer style={{ background: "white" }}>
          <Table sx={{ minWidth: 650, minHeight: tableInvoiceData?.length === 0 ? 600 : "" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.slice(0, 6).map((item, key) => (
                  <TableCell
                    key={key + "0"}
                    className={`fw-bold ${item === "Date" ? "text-start" : "text-center"} ${item === "Generate Form" ? "sticky-table-cell" : ""
                      }`}
                    sx={{ right: "4rem" }}
                  >
                    {item}
                  </TableCell>
                ))}
                {user?.userGroup?.permissions?.cbam?.editInvoice &&
                  <TableCell className="sticky-table-cell" sx={{ right: "0" }}>
                    Action
                  </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>{tableInvoiceData?.length > 0 ? tableRows : noDataMessage}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={FetchedInvoiceData?.data?.totalCount ? FetchedInvoiceData?.data?.totalCount : 0}
                  page={pageNo ? pageNo : 0}
                  rowsPerPageOptions={[5, 10, 25]}
                  rowsPerPage={pageLength ? pageLength : 0}
                  onPageChange={(e, value) => setPageNo(value)}
                  onRowsPerPageChange={(e) => {
                    console.log(e.target.value);
                    setPageLength(e.target.value);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Invoice;
