export const ImporterEndPoint = (build) => ({
  createImporter: build.mutation({
     query: (body) => ({
       url: "cbam/importers",
       method: "POST",
       body: body
     }),
     invalidatesTags: ["Importers"] // Invalidate the cached list of importers after creating a new one
  }),
  getImporterList: build.query({
     query: (arg) => ({
       url: `cbam/importers`,
       method: "GET",
       params: { ...arg }
     }),
     providesTags: ["Importers"]
  }),
  updateImporter: build.mutation({
     query: ({ id, body }) => ({
       url: `cbam/importers/update/${id}`,
       method: "PATCH",
       body: body
     }),
     invalidatesTags: ["Importers"] // Invalidate the cached list of importers after updating
  }),
  deleteImporter: build.mutation({
     query: (id) => ({
       url: `cbam/importers/delete/${id}`,
       method: "DELETE"
     }),
     invalidatesTags: ["Importers"] // Invalidate the cached list of importers after deleting
  }),
  getImporterDetails: build.query({
     query: (id) => ({
       url: `cbam/importers/details/${id}`
     }),
     providesTags: ["Importers"]
  })
 });
 