import React, { useState, useEffect } from "react";
import "./Accordion.scss";
const Accordion = ({ title, children, index, style, contentStyle,}) => {
  const [isActive, setIsActive] = useState(0);

  const toggle = (i) => {
    setIsActive(isActive === i ? null : i);
  };
    

  return (
    <div className="accordion-custom">
      <div className="item">
        <div className="title" onClick={() => toggle(index)}>
          <h6 className="line-divider ">
            <span className="span-line-divider" style={style}>
              <img
                className={isActive === index ? "horizontal" : "vertical"}
                src={"/images/icons/dropdown-icon-blue.svg"}
                alt="arrow"
              />
              {title}
            </span>
          </h6>
        </div>
        <div className={isActive === index ? "content show" : "content"} style={contentStyle}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
