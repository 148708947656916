import React from 'react';
import './Chip.scss';

const Chip = ({ label, isActive, onClick }) => {
  return (
    <div
      className={`chip ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default Chip;
