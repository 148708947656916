import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Accordion from "../../../../../components/Accordion/Accordion";
import { setFormWiseData } from "../../../../../state/antiCorruptionSlice";
import { useLazyGetUserDetailByIdQuery } from "../../../../../state/api";
import { Select } from "antd";
import { numberRegex } from "../../../../../helper/Rejex";

const employeeCategory = [
  "Permanent Employee",
  "Temporary Employees",
  "Non-guaranteed hours employees",
  "Full-time employees",
  "Part-time employees"
];

const gender = ["Male", "Female"];

const businessPartnerCategory = [
  "Suppliers",
  "Agents",
  "Lobbyist",
  "Joint venture & consortia partners",
  "Governments",
  "Customers",
  "Clients",
  "Any other, specify"
];

const Curroption = ({ formEditable, editMode }) => {
  const dispatch = useDispatch();

  const { formWiseData } = useSelector((state) => state.corrupt);

  let govtData = JSON.parse(JSON.stringify(formWiseData));

  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  const handleOperationsAssessedChange = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "percentage") {
        if (value <= 100) {
          govtData.antiCorruption.operations.operationsAssessed[index][name] = value;
        }
      } else {
        govtData.antiCorruption.operations.operationsAssessed[index][name] = value;
      }
    }
    dispatch(setFormWiseData(govtData));
  };

  const handleCommunicationAndTrainingForewordChange = (e) => {
    govtData.antiCorruption.operations.significantRiskForeword = e.target.value;
    dispatch(setFormWiseData(govtData));
  };

  const handleGovernanceBodyMembersChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "region") {
      govtData.antiCorruption.communicationAndTraining.governanceBodyMembers[index][name] = value;
    } else if (numberRegex.test(value) || value === "") {
      if (name === "percentage") {
        if (value <= 100) {
          govtData.antiCorruption.communicationAndTraining.governanceBodyMembers[index][name] = value;
        }
      } else {
        govtData.antiCorruption.communicationAndTraining.governanceBodyMembers[index][name] = value;
      }
    }
    dispatch(setFormWiseData(govtData));
  };

  const addNewRowGovernanceBodyMembers = (e) => {
    let addNewRowObj = {
      region: "",
      totalNumber: "",
      percentage: ""
    };
    govtData.antiCorruption.communicationAndTraining.governanceBodyMembers.push(addNewRowObj);
    dispatch(setFormWiseData(govtData));
  };

  const handleEmployeeCategoryAndRegionChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "region" || name === "employeeCategory" || name === "gender") {
      govtData.antiCorruption.communicationAndTraining.employeeCategoryAndRegion[index][name] = value;
    } else if (numberRegex.test(value) || value === "") {
      if (name === "percentage") {
        if (value <= 100) {
          govtData.antiCorruption.communicationAndTraining.employeeCategoryAndRegion[index][name] = value;
        }
      } else {
        govtData.antiCorruption.communicationAndTraining.employeeCategoryAndRegion[index][name] = value;
      }
    }

    dispatch(setFormWiseData(govtData));
  };

  const addNewRowEmployeeCategoryAndRegion = () => {
    let addNewRowObj = {
      region: "",
      employeeCategory: "",
      gender: "",
      percentage: ""
    };
    govtData.antiCorruption.communicationAndTraining.employeeCategoryAndRegion.push(addNewRowObj);
    dispatch(setFormWiseData(govtData));
  };

  const handleBusinessPartnerCategoryChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "region" || name === "businessPartnerCategory") {
      govtData.antiCorruption.communicationAndTraining.businessPartnerCategory[index][name] = value;
    } else if (numberRegex.test(value) || value === "") {
      if (name === "percentage") {
        if (value <= 100) {
          govtData.antiCorruption.communicationAndTraining.businessPartnerCategory[index][name] = value;
        }
      } else {
        govtData.antiCorruption.communicationAndTraining.businessPartnerCategory[index][name] = value;
      }
    }

    dispatch(setFormWiseData(govtData));
  };

  const addNewRowBusinessPartnerCategory = () => {
    let addNewRowObj = {
      region: "",
      businessPartnerCategory: "",
      totalNumber: "",
      percentage: ""
    };
    govtData.antiCorruption.communicationAndTraining.businessPartnerCategory.push(addNewRowObj);
    dispatch(setFormWiseData(govtData));
  };

  const handleTrainingBodyMembersChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "region") {
      govtData.antiCorruption.communicationAndTraining.totalRecievedTraningBodyMembers[index][name] = value;
    } else if (numberRegex.test(value) || value === "") {
      if (name === "percentage") {
        if (value <= 100) {
          govtData.antiCorruption.communicationAndTraining.totalRecievedTraningBodyMembers[index][name] = value;
        }
      } else {
        govtData.antiCorruption.communicationAndTraining.totalRecievedTraningBodyMembers[index][name] = value;
      }
    }
    dispatch(setFormWiseData(govtData));
  };

  const addNewRowTrainingBodyMembers = () => {
    let addNewRowObj = {
      region: "",
      totalNumber: "",
      percentage: ""
    };
    govtData.antiCorruption.communicationAndTraining.totalRecievedTraningBodyMembers.push(addNewRowObj);
    dispatch(setFormWiseData(govtData));
  };

  const handleTotalRecievedTraningEmployeesChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "region" || name === "employeeCategory" || name === "gender") {
      govtData.antiCorruption.communicationAndTraining.totalRecievedTraningEmployees[index][name] = value;
    } else if (numberRegex.test(value) || value === "") {
      if (name === "percentage") {
        if (value <= 100) {
          govtData.antiCorruption.communicationAndTraining.totalRecievedTraningEmployees[index][name] = value;
        }
      } else {
        govtData.antiCorruption.communicationAndTraining.totalRecievedTraningEmployees[index][name] = value;
      }
    }

    dispatch(setFormWiseData(govtData));
  };

  const addNewRowTotalRecievedTraningEmployees = () => {
    let addNewRowObj = {
      region: "",
      employeeCategory: "",
      gender: "",
      totalNumber: "",
      percentage: ""
    };
    govtData.antiCorruption.communicationAndTraining.totalRecievedTraningEmployees.push(addNewRowObj);
    dispatch(setFormWiseData(govtData));
  };

  const handleConfirmedIncidentsChange = (e, index) => {
    govtData.antiCorruption.actionsTaken.confirmedIncidents[index][e.target.name] = e.target.value;
    dispatch(setFormWiseData(govtData));
  };

  const handlePubliclegalChange = (e) => {
    govtData.antiCorruption.actionsTaken.publiclegal = e.target.value;
    dispatch(setFormWiseData(govtData));
  };

  // const deleteRow = (rowIndex, name) => {
  //   if (rowIndex > 0) {
  //     let tempArray = [...govtData.antiCorruption[name]];
  //     tempArray.splice(rowIndex, 1);
  //     govtData.antiCorruption[name] = tempArray;
  //     dispatch(setFormWiseData(govtData));
  //   }
  // };
  const deleteRow = (parentKey, arrayKey, rowIndex) => {
    const parentObject = govtData.antiCorruption?.[parentKey];
    const dataArray = parentObject?.[arrayKey];
    if (Array.isArray(dataArray)) {
      if (rowIndex >= 0 && rowIndex < dataArray.length) {
        const updatedArray = dataArray.filter((_, index) => index !== rowIndex);

        const updatedParentObject = {
          ...parentObject,
          [arrayKey]: updatedArray
        };

        const updatedData = {
          ...govtData,
          antiCorruption: {
            ...govtData.antiCorruption,
            [parentKey]: updatedParentObject
          }
        };

        dispatch(setFormWiseData(updatedData));
      }
    }
  };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Operations assessed for risks related to corruption"} index={0}>
          <div className="mt-1">
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Total number</th>
                  <th scope="col">Percentage</th>
                </tr>
              </thead>
              <tbody>
                {govtData?.antiCorruption?.operations?.operationsAssessed?.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.heading}</td>
                    <td>
                      <input
                        type="text"
                        name="totalNumber"
                        value={item.totalNumber}
                        onChange={(e) => handleOperationsAssessedChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="percentage"
                        value={item.percentage}
                        onChange={(e) => handleOperationsAssessedChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="foreword-container mt-4">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">Significant risk related to corruption identified</p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={govtData?.antiCorruption?.operations?.significantRiskForeword}
                  name="communicationAndTrainingForeword"
                  onChange={(e) => handleCommunicationAndTrainingForewordChange(e)}
                  className="no-style-input w-100"
                  disabled={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      {/* a==================================part ================================================== */}
      <div className="form-bullet-points-div mt-3">
        <Accordion title={"Communication and training about anti-corruption policies and procedures"} index={1}>
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">
              a. Governance body members that the anti-corruption policies and procedures have been communicated
            </p>
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col">Region</th>
                  <th scope="col">Total number</th>
                  <th scope="col">Percentage</th>
                  <th className="col-md-1" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {govtData?.antiCorruption?.communicationAndTraining?.governanceBodyMembers?.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        value={item.region ? item.region : "Region"}
                        onChange={(value) =>
                          handleGovernanceBodyMembersChange({ target: { value, name: "region" } }, index)
                        }
                      >
                        {user?.facilities?.map((item, ind) => (
                          <Select.Option key={ind} value={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="totalNumber"
                        value={item.totalNumber}
                        onChange={(e) => handleGovernanceBodyMembersChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="percentage"
                        value={item.percentage}
                        onChange={(e) => handleGovernanceBodyMembersChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("communicationAndTraining", "governanceBodyMembers" , index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-outline-success add-new-row mb-4" onClick={() => addNewRowGovernanceBodyMembers()}>
            <p>+Add New Region</p>
          </button>

          {/* b================================================part================================================= */}
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">
              b. Total number and percentage of employees that the organization’s anti-corruption policies and
              procedures have been communicated to, broken down by employee category and region
            </p>
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col">Region</th>
                  <th scope="col">Employee Category</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Total Number</th>
                  <th scope="col">Percentage</th>
                  <th className="col-md-1" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {govtData?.antiCorruption?.communicationAndTraining?.employeeCategoryAndRegion?.map((inputItem, index) => (
                  <tr key={inputItem + index}>
                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Region"
                        showSearch
                        value={inputItem.region ? inputItem.region : "Region"}
                        onChange={(value) =>
                          handleEmployeeCategoryAndRegionChange({ target: { value, name: "region" } }, index)
                        }
                      >
                        {user?.facilities?.map((item, ind) => (
                          <Select.Option key={ind} value={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Permanent Employee"
                        showSearch
                        value={inputItem.employeeCategory ? inputItem.employeeCategory : "Permanent Employee"}
                        onChange={(value) =>
                          handleEmployeeCategoryAndRegionChange({ target: { value, name: "employeeCategory" } }, index)
                        }
                      >
                        {employeeCategory.map((item, ind) => (
                          <Select.Option key={ind} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Male"
                        showSearch
                        value={inputItem.gender ? inputItem.gender : "Male"}
                        onChange={(value) =>
                          handleEmployeeCategoryAndRegionChange({ target: { value, name: "gender" } }, index)
                        }
                      >
                        {gender.map((item, ind) => (
                          <Select.Option key={ind} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <input
                        type="text"
                        name="totalNumber"
                        value={inputItem.totalNumber}
                        onChange={(e) => handleEmployeeCategoryAndRegionChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="percentage"
                        value={inputItem.percentage}
                        onChange={(e) => handleEmployeeCategoryAndRegionChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>

                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("communicationAndTraining", "employeeCategoryAndRegion" , index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className="btn btn-outline-success add-new-row mb-4"
            onClick={() => addNewRowEmployeeCategoryAndRegion()}
          >
            <p>+Add New Region</p>
          </button>

          {/* c============================part============================================================ */}
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">
              c. Total number and percentage of business partners that the organization’s anticorruption policies and
              procedures have been communicated to, broken down by type of business partner and region.
            </p>
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col">Region</th>
                  <th scope="col">Business Partner Category</th>
                  <th scope="col">Total Number</th>
                  <th scope="col">Percentage</th>
                  <th className="col-md-1" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {govtData?.antiCorruption?.communicationAndTraining?.businessPartnerCategory?.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Region"
                        showSearch
                        value={item.region ? item.region : "Region"}
                        onChange={(value) =>
                          handleBusinessPartnerCategoryChange({ target: { value, name: "region" } }, index)
                        }
                      >
                        {user?.facilities?.map((item, ind) => (
                          <Select.Option key={ind} value={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Suppliers"
                        showSearch
                        value={item.businessPartnerCategory ? item.businessPartnerCategory : "Suppliers"}
                        onChange={(value) =>
                          handleBusinessPartnerCategoryChange(
                            { target: { value, name: "businessPartnerCategory" } },
                            index
                          )
                        }
                      >
                        {businessPartnerCategory.map((item, ind) => (
                          <Select.Option key={ind} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <input
                        type="text"
                        name="totalNumber"
                        value={item.totalNumber}
                        onChange={(e) => handleBusinessPartnerCategoryChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="percentage"
                        value={item.percentage}
                        onChange={(e) => handleBusinessPartnerCategoryChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>

                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("communicationAndTraining", "businessPartnerCategory" , index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className="btn btn-outline-success add-new-row mb-4"
            onClick={() => addNewRowBusinessPartnerCategory()}
          >
            <p>+Add New Region</p>
          </button>

          {/* d===============================part=================================================== */}
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">
              d. Total number and percentage of governance body members that have received training on anti-corruption,
              broken down by region
            </p>
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col">Region</th>
                  <th scope="col">Total Number</th>
                  <th scope="col">Percentage</th>
                  <th className="col-md-1" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {govtData?.antiCorruption?.communicationAndTraining?.totalRecievedTraningBodyMembers?.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Region"
                        showSearch
                        value={item.region ? item.region : "Region"}
                        onChange={(value) =>
                          handleTrainingBodyMembersChange({ target: { value, name: "region" } }, index)
                        }
                      >
                        {user?.facilities?.map((item, ind) => (
                          <Select.Option key={ind} value={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <input
                        type="text"
                        name="totalNumber"
                        value={item.totalNumber}
                        onChange={(e) => handleTrainingBodyMembersChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>

                    <td>
                      <input
                        type="text"
                        name="percentage"
                        value={item.percentage}
                        onChange={(e) => handleTrainingBodyMembersChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>

                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("communicationAndTraining", "totalRecievedTraningBodyMembers" , index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-outline-success add-new-row mb-4" onClick={() => addNewRowTrainingBodyMembers()}>
            <p>+Add New Region</p>
          </button>

          {/* e ============================================================================part */}
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">
              e. Total number and percentage of employees that have received training on antiCorruption, broken down by
              employee category and region
            </p>
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col">Region</th>
                  <th scope="col">Employee Category</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Total Number</th>
                  <th scope="col">Percentage</th>
                  <th className="col-md-1" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {govtData?.antiCorruption?.communicationAndTraining?.totalRecievedTraningEmployees?.map((inputItem, index) => (
                  <tr key={inputItem + index}>
                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Region"
                        showSearch
                        value={inputItem.region ? inputItem.region : "Region"}
                        onChange={(value) =>
                          handleTotalRecievedTraningEmployeesChange({ target: { value, name: "region" } }, index)
                        }
                      >
                        {user?.facilities?.map((item, ind) => (
                          <Select.Option key={ind} value={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Permanent Employee"
                        showSearch
                        value={inputItem.employeeCategory ? inputItem.employeeCategory : "Permanent Employee"}
                        onChange={(value) =>
                          handleTotalRecievedTraningEmployeesChange(
                            { target: { value, name: "employeeCategory" } },
                            index
                          )
                        }
                      >
                        {employeeCategory.map((item, ind) => (
                          <Select.Option key={ind} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Male"
                        showSearch
                        value={inputItem.gender ? inputItem.gender : "Male"}
                        onChange={(value) =>
                          handleTotalRecievedTraningEmployeesChange({ target: { value, name: "gender" } }, index)
                        }
                      >
                        {gender.map((item, ind) => (
                          <Select.Option key={ind} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <input
                        type="text"
                        name="totalNumber"
                        value={inputItem.totalNumber}
                        onChange={(e) => handleTotalRecievedTraningEmployeesChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="percentage"
                        value={inputItem.percentage}
                        onChange={(e) => handleTotalRecievedTraningEmployeesChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>

                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("communicationAndTraining", "totalRecievedTraningEmployees",index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className="btn btn-outline-success add-new-row mb-4"
            onClick={() => addNewRowTotalRecievedTraningEmployees()}
          >
            <p>+Add New Region</p>
          </button>
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion title={"Confirmed incidents of corruption and actions taken"} index={2}>
          <div className="mt-1 table-scrollable">
            <table className="form-table w-auto mb-2">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Total Number of CIC</th>
                  <th scope="col">Nature of CIC</th>
                  <th scope="col">Total number of CIC in which employees were dismissed</th>
                  <th scope="col">Total number of CIC in which employees were disciplined</th>
                  <th scope="col">Total number of CIC in which business partners were terminated or not renewed</th>
                </tr>
              </thead>
              <tbody>
                {govtData?.antiCorruption?.actionsTaken?.confirmedIncidents?.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.heading}</td>
                    <td>
                      <input
                        type="number"
                        name="totalnumberOfCIC"
                        value={item.totalnumberOfCIC}
                        onChange={(e) => handleConfirmedIncidentsChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="natureOfCIC"
                        value={item.natureOfCIC}
                        onChange={(e) => handleConfirmedIncidentsChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="number"
                        name="totalNumberEmployeesDismissed"
                        value={item.totalNumberEmployeesDismissed}
                        onChange={(e) => handleConfirmedIncidentsChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="number"
                        name="totalNumberEmployeesDisciplined"
                        value={item.totalNumberEmployeesDisciplined}
                        onChange={(e) => handleConfirmedIncidentsChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="number"
                        name="totalNumberPartnersTerminated"
                        value={item.totalNumberPartnersTerminated}
                        onChange={(e) => handleConfirmedIncidentsChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="foreword-container mt-4">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                Public legal cases regarding corruption brought against the organization or its employees during the
                reporting period and the outcomes of such cases
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={govtData.antiCorruption.actionsTaken.publiclegal}
                  name="communicationAndTrainingForeword"
                  onChange={(e) => handlePubliclegalChange(e)}
                  className="no-style-input w-100"
                  disabled={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default Curroption;
