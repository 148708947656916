import React from 'react'
import { Tooltip } from 'react-tooltip'
import './CustomTooltip.scss'

const CustomTooltip = ({content, showIcon, childComponent, width, id ,position}) => {
    return (
        <div>
            <div className={`${showIcon ? "showDisplay" : "noDisplay"}`} data-tooltip-id={id} data-tooltip-html={content}>
                {childComponent}
            </div>
            <Tooltip id={id} style={{width: width, zIndex: 1} } place={position} />
        </div>
    )
}

export default CustomTooltip