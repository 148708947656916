import { createSlice } from "@reduxjs/toolkit";

const initialBrsrReportState = {
  pageWiseValue: {
    coverPage: {
      selectedCardIndex: 0,
      foreword: ""
    },
    SectionA: {
      corporateNumber: "",
      corpoarteNumberEvidence: "",
      listedEntityName: "",
      listedEntityNameEvidence: "",
      yearOfInc: "",
      officeAddress: "",
      corporateAddress: "",
      email: "",
      telephone: {
        areaCode: "",
        countryCode: "+91",
        telephoneNo: "",
        mobileNo: ""
      },
      website: "",
      financialYear: "",
      stockExchange: "",
      paidUpCapital: "",

      contactDetail: {
        name: "",
        email: "",
        designation: "",
        telephoneNo: "",
        mobileNo: "",
        faxNo: "",
        areaCode: "",
        countryCode: "+91"
      },

      reportingBoundary: "",
      nameOfAssurance: "",
      typeOfAssurance: "",

      businessActivityDetails: [
        {
          mainActivity: "",
          businessActivity: "",
          turnoverSpecific: "",
          totalTurnover: "",
          percentTurnover: "0"
        }
      ],

      productServiceSold: [
        {
          productService: "",
          nicCode: "",
          specificProduct: "",
          totalTurnover: "",
          percentTurnover: "0"
        }
      ],

      locationOfPlants: {
        national: {
          noOfPlants: "",
          noOfOffices: "",
          total: "0"
        },
        international: {
          noOfPlants: "",
          noOfOffices: "",
          total: "0"
        }
      },
      marketServed: {
        national: {
          number: ""
        },
        international: {
          number: ""
        }
      },
      exportContribution: "",
      typesOfCustomer: "",
      employee: {
        permanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        notPermanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        totalEmployee: {
          total: "0",
          male: "0",
          malePercent: 0,
          female: "0",
          femalePercent: 0
        }
      },
      worker: {
        permanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        notPermanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        totalEmployee: {
          total: "0",
          male: "0",
          malePercent: 0,
          female: "0",
          femalePercent: 0
        }
      },
      diffEmployee: {
        permanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        notPermanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        totalEmployee: {
          total: "0",
          male: "0",
          malePercent: 0,
          female: "0",
          femalePercent: 0
        }
      },
      diffWorker: {
        permanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        notPermanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        totalEmployee: {
          total: "0",
          male: "0",
          malePercent: 0,
          female: "0",
          femalePercent: 0
        }
      },
      participation: {
        boardOfDirectors: {
          total: "0",
          women: ""
        },
        keyManagement: {
          total: "0",
          women: ""
        }
      },
      turnOverCurrent: {
        permanentEmployee: {
          maleCountApril: "",
          maleCountMarch: "",
          maleCountleft: "",
          male: "0",
          femaleCountApril: "",
          femaleCountMarch: "",
          femaleCountleft: "",
          female: "0",
          total: "0"
        },
        permanentWorker: {
          maleCountApril: "",
          maleCountMarch: "",
          maleCountleft: "",
          male: "0",
          femaleCountApril: "",
          femaleCountMarch: "",
          femaleCountleft: "",
          female: "0",
          total: "0"
        }
      },
      turnOverPrevious: {
        permanentEmployee: {
          male: "",
          female: ""
        },
        permanentWorker: {
          male: "",
          female: ""
        }
      },
      turnOverPrePrevious: {
        permanentEmployee: {
          male: "",
          female: ""
        },
        permanentWorker: {
          male: "",
          female: ""
        }
      },
      holdings: [
        {
          companyDetail: "",
          indicator: "",
          sharePercent: "",
          participation: ""
        }
      ],
      csrApplicable: "",
      netWorth: "",
      turnOver: "",

      complaints: {
        communities: {
          grievanceRedressal: "",
          webLink: "",
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },

        investors: {
          grievanceRedressal: "",
          webLink: "",
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },
        shareholders: {
          grievanceRedressal: "",
          webLink: "",
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },

        employees: {
          grievanceRedressal: "",
          webLink: "",
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },
        customers: {
          grievanceRedressal: "",
          webLink: "",
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },
        valueChain: {
          grievanceRedressal: "",
          webLink: "",
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },
        other: {
          grievanceRedressal: "",
          webLink: "",
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        }
      },

      riskBusinessIssue: [
        {
          materialIssue: "",
          riskOrOpportunity: "",
          reasonOfRiskOpportunity: "",
          mitigation: "",
          financialImplication: ""
        }
      ]
    },
    SectionB: {
      discolosureQuestions: {
        whetherEntityPolicyCoversPrinciple: {
          questions:
            "1. a. Whether your entity’s policy/policies cover each principle and its core elements of the NGRBCs. (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        policyApprovedByBoard: {
          questions: "b. Has the policy been approved by the Board? (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        policyWeblink: {
          questions: "c. Web Link of the Policies, if available",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        policyIntoProcedure: {
          questions: "2. Whether the entity has translated the policy into procedures. (Yes / No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        policyExtendsToValueChain: {
          questions: "3. Do the enlisted policies extend to your value chain partners? (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        nameOfNationalInternationalCodes: {
          questions:
            "4. Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and mapped to each principle.",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        specificCommitmentsGoals: {
          questions: "5. Specific commitments, goals and targets set by the entity with defined timelines, if any.",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        performanceOfEntityAgainstSpecificCommitment: {
          questions:
            "6. Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met.",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        }
      },

      statementByDirector: "",
      highestAuthorityDetails: "",
      hasSpecifiedCommitteeYesNo: "",
      hasSpecifiedCommittee: "",

      ngrbcDirectorReview: {
        indication: {
          performanceAgainstPolicies: {
            subject: "Performance against above policies and follow up action",
            p1: "",
            p2: "",
            p3: "",
            p4: "",
            p5: "",
            p6: "",
            p7: "",
            p8: "",
            p9: ""
          },
          complianceWithRequirements: {
            subject:
              "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliances",
            p1: "",
            p2: "",
            p3: "",
            p4: "",
            p5: "",
            p6: "",
            p7: "",
            p8: "",
            p9: ""
          }
        },
        frequency: {
          performanceAgainstPolicies: {
            subject: "Performance against above policies and follow up action",
            p1: "",
            p2: "",
            p3: "",
            p4: "",
            p5: "",
            p6: "",
            p7: "",
            p8: "",
            p9: ""
          },
          complianceWithRequirements: {
            subject:
              "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliances",
            p1: "",
            p2: "",
            p3: "",
            p4: "",
            p5: "",
            p6: "",
            p7: "",
            p8: "",
            p9: ""
          }
        }
      },
      independentAssessmentAgency: {
        p1: "",
        p2: "",
        p3: "",
        p4: "",
        p5: "",
        p6: "",
        p7: "",
        p8: "",
        p9: ""
      },

      reasonToBeStated: [
        {
          question: "The entity does not consider the Principles material to its business (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          question:
            "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          question:
            "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          question: "It is planned to be done in the next financial year (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          question: "Any other reason (please specify)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        }
      ],

      ngrbcFrequencyReview: [
        {
          subject: "Performance against above policies and follow up action",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          subject:
            "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliances",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        }
      ]
    },
    SectionC: {
      principleOne: {
        coverageByTraining: {
          boardOfDirectors: {
            segment:"Board of Directors",
            totalPrograms: "",
            principlesCovered: "",
            totalPerson: "",
            totalPersonEachCategory: "",
            agePercetage: "0"
          },
          keyManagerial: {
            segment:"Key Managerial Personne",
            totalPrograms: "",
            principlesCovered: "",
            totalPerson: "",
            totalPersonEachCategory: "",
            agePercetage: "0"
          },
          employeesOther: {
            segment:"Employees other than BoD and KMPs",
            totalPrograms: "",
            principlesCovered: "",
            totalPerson: "",
            totalPersonEachCategory: "",
            agePercetage: "0"
          },
          workers: {
            segment:"Workers",
            totalPrograms: "",
            principlesCovered: "",
            totalPerson: "",
            totalPersonEachCategory: "",
            agePercetage: "0"
          }
        },

        settlementPenaltyFine: [
          {
            segment: "Penalty/Fine",
            ngrbcPrinciple: "",
            regulatoryName: "",
            amount: "",
            caseBrief: "",
            appealPreffered: ""
          },
          {
            segment: "Settlement",
            ngrbcPrinciple: "",
            regulatoryName: "",
            amount: "",
            caseBrief: "",
            appealPreffered: ""
          },
          {
            segment: "Compounding fee",
            ngrbcPrinciple: "",
            regulatoryName: "",
            amount: "",
            caseBrief: "",
            appealPreffered: ""
          }
        ],
        // settlementPenaltySettlement: [
        //   {
        //     segment: "Settlement",
        //     ngrbcPrinciple: "",
        //     regulatoryName: "",
        //     amount: "",
        //     caseBrief: "",
        //     appealPreffered: ""
        //   }
        // ],
        // settlementPenaltyCompoundingFee: [
        //   {
        //     segment: "Compounding fee",
        //     ngrbcPrinciple: "",
        //     regulatoryName: "",
        //     amount: "",
        //     caseBrief: "",
        //     appealPreffered: ""
        //   }
        // ],

        settlementPenaltyNonMonetary: [
          {
            segment: "Imprisonment",
            ngrbcPrinciple: "",
            regulatoryName: "",
            amount: "",
            caseBrief: "",
            appealPreffered: ""
          },
          {
            segment: "Punishment",
            ngrbcPrinciple: "",
            regulatoryName: "",
            amount: "",
            caseBrief: "",
            appealPreffered: ""
          }
        ],

        appealPreferred: [
          {
            caseDetail: "",
            regulatoryName: ""
          }
        ],
        antiCorruptionDetails: "",
        weblink: "",

        disciplinaryAction: {
          directors: {
            groupType: "Directors",
            currentYear: "",
            previousYear: ""
          },
          kmps: {
            groupType: "KMPs",
            currentYear: "",
            previousYear: ""
          },
          employees: {
            groupType: "Employees",
            currentYear: "",
            previousYear: ""
          },
          workers: {
            groupType: "Workers",
            currentYear: "",
            previousYear: ""
          }
        },

        conflictOfInterest: {
          directors: {
            complaintType:
              "Number of complaints received in relation to issues of Conflict of Interest of the Directors",
            currentYear: {
              number: "",
              remarks: ""
            },
            previousYear: {
              number: "",
              remarks: ""
            }
          },
          kmps: {
            complaintType: "Number of complaints received in relation to issues of Conflict of Interest of the KMPs",
            currentYear: {
              number: "",
              remarks: ""
            },
            previousYear: {
              number: "",
              remarks: ""
            }
          }
        },

        collectiveAction: "",
        daysOfAccountsPayable: [
          {
            payable: "Number of days of accounts payables",
            accountsPayable: "",
            costOfGoods: "",
            currentYear: "",
            previousYear: ""
          }
        ],

        openNessOfBusiness: {
          purchase: {
            purchaseTrading: {
              metrics: "a. Purchases from trading houses as % of total purchases",
              totalPurchase: "",
              purchaseFromTradingHouses: "",
              purchasesFromTop10: "",
              currentFinancialYear: "0",
              previousFinancialYear: ""
            },
            numberOfTrading: {
              metrics: "b. Number of trading houses where purchases are made from",
              totalPurchase: "",
              purchaseFromTradingHouses: "",
              purchasesFromTop10: "",
              currentFinancialYear: "",
              previousFinancialYear: ""
            },
            purchaseTop10: {
              metrics: "c. Purchases from top 10 trading houses as % of total purchases from trading houses",
              totalPurchase: "",
              purchaseFromTradingHouses: "",
              purchasesFromTop10: "",
              currentFinancialYear: "",
              previousFinancialYear: ""
            }
          },

          sales: {
            salesToDealer: {
              metrics: "a. Sales to dealers / distributors as % of total sales",
              totalSales: "",
              totalDealer: "",
              saletoTop10: "",
              currentYear: "0",
              previousYear: ""
            },
            numberOfDealer: {
              metrics: "b. Number of dealers / distributors to whom sales are made",
              totalSales: "",
              totalDealer: "",
              saletoTop10: "",
              currentYear: "",
              previousYear: ""
            },
            salesTop10: {
              metrics: "c. Sales to top 10 dealers / distributors as % of total sales to dealers / distributors",
              totalSales: "",
              totalDealer: "",
              saletoTop10: "",
              currentYear: "0",
              previousYear: ""
            }
          },

          rptShare: {
            purchaseSale: {
              purchase: {
                metrics: "a. Purchases (Purchases with related parties / Total Purchases)",
                realtedPlants: "",
                totalPurchase: "",
                currentYear: "0",
                previousYear: ""
              },
              sales: {
                metrics: "b. Sales (Sales to related parties / Total Sales)",
                realtedPlants: "",
                totalPurchase: "",
                currentYear: "0",
                previousYear: ""
              }
            }
          },

          loadAdvance: {
            metrics: "c. Loans & advances (Loans & advances given to related parties / Total loans & advances)",
            totalLoan: "",
            advanceLoan: "",
            currentYear: "0",
            previousYear: ""
          },
          investment: {
            metrics: "d. Investments ( Investments in related parties / Total Investments made)",
            totalInvestment: "",
            investedPlants: "",
            currentYear: "0",
            previousYear: ""
          }
        },

      
        awarenessPrograms: {
          totalAwarenessPrograms: "",
          topicsCovered: "",
          totalValueChain: "",
          totalValueBusiness: "",
          valueChainPartnersCoverd: "0"
        },
        entityHasProcess: "",
        entityHasProcessDetail: ""
      },

      principleTwo: {
        capitalExpenditure: {
          reseach: {
            investments: "R&D",
            totalExpenditure: "",
            expenditureEnvironment: "",
            currentYear: "0",
            previousYear: "",
            envImpact: ""
          },
          capex: {
            investments: "Capex",
            totalExpenditure: "",
            expenditureEnvironment: "",
            currentYear: "0",
            previousYear: "",
            envImpact: ""
          }
        },
        entityHasProcedures: "",
        sustainablePercent: "",
        sustainablePercentDescription: "",
        plasticRecycle: "",
        eWasteRecycle: "",
        hazardousWasteRecycle: "",
        otherWaste: "",
        ermApplicable: "",
        planInLineWithErm: "",
        lifeCycleAssessment: [
          {
            nicCode: "",
            productName: "",
            turnoverProduct: "",
            totalTurnover: "",
            percentageTurnover: "0",
            assessmentBoundary: "",
            externalAgency: "",
            publicResults: ""
          }
        ],
        envConcerns: [
          {
            productName: "",
            riskDescription: "",
            actionTaken: ""
          }
        ],
        percentageRecycle: [
          {
            inputMaterial: "",
            totalValues: "",
            totalInput: "",
            currentYear: "0",
            previousYear: ""
          }
        ],
        recycleReuse: [
          {
            product: "Plastics (including packaging)",
            currentYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            },
            previousYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            }
          },
          {
            product: "E-waste",
            currentYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            },
            previousYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            }
          },
          {
            product: "Hazardous waste",
            currentYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            },
            previousYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            }
          },
          {
            product: "Other waste",
            currentYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            },
            previousYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            }
          }
        ],

        reclaimedProducts: [
          {
            category: "",
            valueProduct: "",
            totalProduct: "",
            productPercent: "0"
          }
        ]
      },
      principleThree: {
        employeeWellBeing: {
          permanentEmployee: {
            male: {
              category: "Male",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "0",
              accidentInsurance: "",
              accidentInsurancePercent: "0",
              maternityBenefits: "",
              maternityBenefitsPercent: "0",
              paternityBenefits: "",
              paternityBenefitsPercent: "0",
              dayCareFacility: "",
              dayCareFacilityPercent: "0"
            },
            female: {
              category: "Female",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "0",
              accidentInsurance: "",
              accidentInsurancePercent: "0",
              maternityBenefits: "",
              maternityBenefitsPercent: "0",
              paternityBenefits: "",
              paternityBenefitsPercent: "0",
              dayCareFacility: "",
              dayCareFacilityPercent: "0"
            },
            total: {
              category: "Total",
              total: "0",
              healthInsurance: "0",
              healthInsurancePercent: "0",
              accidentInsurance: "0",
              accidentInsurancePercent: "0",
              maternityBenefits: "0",
              maternityBenefitsPercent: "0",
              paternityBenefits: "0",
              paternityBenefitsPercent: "0",
              dayCareFacility: "0",
              dayCareFacilityPercent: "0"
            }
          },
          nonPermanentEmployee: {
            male: {
              category: "Male",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "0",
              accidentInsurance: "",
              accidentInsurancePercent: "0",
              maternityBenefits: "",
              maternityBenefitsPercent: "0",
              paternityBenefits: "",
              paternityBenefitsPercent: "0",
              dayCareFacility: "",
              dayCareFacilityPercent: "0"
            },
            female: {
              category: "Female",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "0",
              accidentInsurance: "",
              accidentInsurancePercent: "0",
              maternityBenefits: "",
              maternityBenefitsPercent: "0",
              paternityBenefits: "",
              paternityBenefitsPercent: "0",
              dayCareFacility: "",
              dayCareFacilityPercent: "0"
            },
            total: {
              category: "Total",
              total: "0",
              healthInsurance: "0",
              healthInsurancePercent: "0",
              accidentInsurance: "0",
              accidentInsurancePercent: "0",
              maternityBenefits: "0",
              maternityBenefitsPercent: "0",
              paternityBenefits: "0",
              paternityBenefitsPercent: "0",
              dayCareFacility: "0",
              dayCareFacilityPercent: "0"
            }
          },

          permanentWorker: {
            male: {
              category: "Male",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "0",
              accidentInsurance: "",
              accidentInsurancePercent: "0",
              maternityBenefits: "",
              maternityBenefitsPercent: "0",
              paternityBenefits: "",
              paternityBenefitsPercent: "0",
              dayCareFacility: "",
              dayCareFacilityPercent: "0"
            },
            female: {
              category: "Female",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "0",
              accidentInsurance: "",
              accidentInsurancePercent: "0",
              maternityBenefits: "",
              maternityBenefitsPercent: "0",
              paternityBenefits: "",
              paternityBenefitsPercent: "0",
              dayCareFacility: "",
              dayCareFacilityPercent: "0"
            },
            total: {
              category: "Total",
              total: "0",
              healthInsurance: "0",
              healthInsurancePercent: "0",
              accidentInsurance: "0",
              accidentInsurancePercent: "0",
              maternityBenefits: "0",
              maternityBenefitsPercent: "0",
              paternityBenefits: "0",
              paternityBenefitsPercent: "0",
              dayCareFacility: "0",
              dayCareFacilityPercent: "0"
            }
          },
          nonPermanentWorker: {
            male: {
              category: "Male",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "0",
              accidentInsurance: "",
              accidentInsurancePercent: "0",
              maternityBenefits: "",
              maternityBenefitsPercent: "0",
              paternityBenefits: "",
              paternityBenefitsPercent: "0",
              dayCareFacility: "",
              dayCareFacilityPercent: "0"
            },
            female: {
              category: "Female",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "0",
              accidentInsurance: "",
              accidentInsurancePercent: "0",
              maternityBenefits: "",
              maternityBenefitsPercent: "0",
              paternityBenefits: "",
              paternityBenefitsPercent: "0",
              dayCareFacility: "",
              dayCareFacilityPercent: "0"
            },
            total: {
              category: "Total",
              total: "0",
              healthInsurance: "0",
              healthInsurancePercent: "0",
              accidentInsurance: "0",
              accidentInsurancePercent: "0",
              maternityBenefits: "0",
              maternityBenefitsPercent: "0",
              paternityBenefits: "0",
              paternityBenefitsPercent: "0",
              dayCareFacility: "0",
              dayCareFacilityPercent: "0"
            }
          }
        },

        spendingOnWellBeing: [
          {
            costIncurred: "Cost incurred on well-being measures as a % of total revenue of the company",
            healthInsurance: "",
            accidentInsurance: "",
            maternityBenefits: "",
            paternityBenefits: "",
            dayCareFacilities: "",
            otherWellbeingMeasures: "",
            totalRevenue: "",
            currentYear: "0",
            previousYear: ""
          }
        ],

        retirementBenefits: {
          pf: {
            benefits: "PF",
            currentYear: {
              totalEmployees: "",
              employeesCovered: "",
              totalWorkers: "",
              workersCovered: "",
              employeesCoveredPercentage: "0",
              workersCoveredPercentage: "0",
              deductedDeposited: ""
            },
            previousYear: {
              totalEmployees: "",
              employeesCovered: "",
              totalWorkers: "",
              workersCovered: "",
              employeesCoveredPercentage: "",
              workersCoveredPercentage: "",
              deductedDeposited: ""
            }
          },
          gratuity: {
            benefits: "Gratuity",
            currentYear: {
              totalEmployees: "",
              employeesCovered: "",
              totalWorkers: "",
              workersCovered: "",
              employeesCoveredPercentage: "0",
              workersCoveredPercentage: "0",
              deductedDeposited: ""
            },
            previousYear: {
              totalEmployees: "",
              employeesCovered: "",
              totalWorkers: "",
              workersCovered: "",
              employeesCoveredPercentage: "",
              workersCoveredPercentage: "",
              deductedDeposited: ""
            }
          },
          esi: {
            benefits: "ESI",
            currentYear: {
              totalEmployees: "",
              employeesCovered: "",
              totalWorkers: "",
              workersCovered: "",
              employeesCoveredPercentage: "0",
              workersCoveredPercentage: "0",
              deductedDeposited: ""
            },
            previousYear: {
              totalEmployees: "",
              employeesCovered: "",
              totalWorkers: "",
              workersCovered: "",
              employeesCoveredPercentage: "",
              workersCoveredPercentage: "",
              deductedDeposited: ""
            }
          },
          others: {
            benefits: "Others-Specify",
            currentYear: {
              totalEmployees: "",
              employeesCovered: "",
              totalWorkers: "",
              workersCovered: "",
              employeesCoveredPercentage: "0",
              workersCoveredPercentage: "0",
              deductedDeposited: ""
            },
            previousYear: {
              totalEmployees: "",
              employeesCovered: "",
              totalWorkers: "",
              workersCovered: "",
              employeesCoveredPercentage: "",
              workersCoveredPercentage: "",
              deductedDeposited: ""
            }
          }
        },

        accessibleToEmployees: "",
        equalOpportunityPolicy: "",
        equalOpportunityPolicyUrlLink: "",
        retentionRate: {
          male: {
            gender: "Male",
            permanentEmployee: {
              returnToWork: "",
              didReturn: "",
              retainedAfter12Months: "",
              returnFromPrior: "",
              returnToWorkRate: "",
              retentionRate: ""
            },
            nonPermanentEmployee: {
              returnToWork: "",
              didReturn: "",
              retainedAfter12Months: "",
              returnFromPrior: "",
              returnToWorkRate: "",
              retentionRate: ""
            }
          },
          female: {
            gender: "Female",
            permanentEmployee: {
              returnToWork: "",
              didReturn: "",
              retainedAfter12Months: "",
              returnFromPrior: "",
              returnToWorkRate: "",
              retentionRate: ""
            },
            nonPermanentEmployee: {
              returnToWork: "",
              didReturn: "",
              retainedAfter12Months: "",
              returnFromPrior: "",
              returnToWorkRate: "",
              retentionRate: ""
            }
          },
          total: {
            gender: "Total",
            permanentEmployee: {
              returnToWork: "",
              didReturn: "",
              retainedAfter12Months: "",
              returnFromPrior: "",
              returnToWorkRate: "",
              retentionRate: ""
            },
            nonPermanentEmployee: {
              returnToWork: "",
              didReturn: "",
              retainedAfter12Months: "",
              returnFromPrior: "",
              returnToWorkRate: "",
              retentionRate: ""
            }
          }
        },

        grievanceMechanism: {
          permWorker: {
            typeOfEmployee: "Permanent Workers",
            grievanceMechanismExists: "",
            grievanceMechanismDetail: ""
          },
          otherPermWorker: {
            typeOfEmployee: "Other than Permanent Workers",
            grievanceMechanismExists: "",
            grievanceMechanismDetail: ""
          },
          permEmployee: {
            typeOfEmployee: "Permanent Employees",
            grievanceMechanismExists: "",
            grievanceMechanismDetail: ""
          },
          otherPermEmployee: {
            typeOfEmployee: "Other than Permanent Employees",
            grievanceMechanismExists: "",
            grievanceMechanismDetail: ""
          }
        },

        membershipOfEmployees: {
          totalPermEmployee: {
            category: "Total Permanent Employees",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              bAPercentage: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              dCPercentage: ""
            }
          },
          maleEmployee: {
            category: "Male",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              bAPercentage: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              dCPercentage: ""
            }
          },
          femaleEmployee: {
            category: "Female",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              bAPercentage: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              dCPercentage: ""
            }
          },
          totalPermWorker: {
            category: "Total Permanent Workers",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              bAPercentage: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              dCPercentage: ""
            }
          },
          maleWorker: {
            category: "Male",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              bAPercentage: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              dCPercentage: ""
            }
          },
          femaleWorker: {
            category: "Female",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              bAPercentage: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: "",
              dCPercentage: ""
            }
          }
        },

        trainingGivenToEmployee: [
          {
            category: "Male",
            currentYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            }
          },
          {
            category: "Total",
            currentYear: {
              total: "0",
              healthAndSafety: "0",
              skillUpgradation: "0",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            }
          }
        ],
        trainingGivenToWorker: [
          {
            category: "Male",
            currentYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            }
          },
          {
            category: "Total",
            currentYear: {
              total: "0",
              healthAndSafety: "0",
              skillUpgradation: "0",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "0",
              skillUpgradationPercent: "0"
            }
          }
        ],

        performanceReviewEmployee: {
          male: {
            category: "Male",
            currentYear: {
              total: "",
              number: "",
              numberPercent: "0"
            },
            previousYear: {
              total: "",
              number: "",
              numberPercent: "0"
            }
          },
          female: {
            category: "Female",
            currentYear: {
              total: "",
              number: "",
              numberPercent: "0"
            },
            previousYear: {
              total: "",
              number: "",
              numberPercent: "0"
            }
          },
          total: {
            category: "Total",
            currentYear: {
              total: "0",
              number: "0",
              numberPercent: "0"
            },
            previousYear: {
              total: "0",
              number: "0",
              numberPercent: "0"
            }
          }
        },

        performanceReviewWorker: [
          {
            category: "Male",
            currentYear: {
              total: "",
              number: "",
              numberPercent: "0"
            },
            previousYear: {
              total: "",
              number: "",
              numberPercent: "0"
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              number: "",
              numberPercent: "0"
            },
            previousYear: {
              total: "",
              number: "",
              numberPercent: "0"
            }
          },
          {
            category: "Total",
            currentYear: {
              total: "0",
              number: "0",
              numberPercent: "0"
            },
            previousYear: {
              total: "0",
              number: "0",
              numberPercent: "0"
            }
          }
        ],

        healthAndSafetyImplementedYesNo: "",
        healthAndSafetyImplemented: "",
        healthAndSafetyIdentifyProcess: "",
        hazardIdentifyProcess: "",
        reportHazardYesNo: "",
        reportHazard: "",
        accessToHealthcareYesNo: "",
        accessToHealthcare: "",

        safetyRelatedIncidents: {
          injuryFrequencyEmployee: {
            safetyIncidents: "Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)",
            ngrbcPrinciple: "Employees",
            regulatoryName: "",
            briefOfCase: "",
            lostTimeInjuriesFY: "", // New key
            totalHoursWorked: "" // New key
          },
          injuryFrequencyWorker: {
            parameters: "Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)",
            ngrbcPrinciple: "Workers",
            regulatoryName: "",
            briefOfCase: "",
            lostTimeInjuriesFY: "", // New key
            totalHoursWorked: "" // New key
          },
          workRelatedInjuryEmployee: {
            safetyIncidents: "Total recordable work-related injuries",
            ngrbcPrinciple: "Employees",
            regulatoryName: "",
            briefOfCase: "",
            lostTimeInjuriesFY: "", 
            totalHoursWorked: "" 
          },
          workRelatedInjuryWorker: {
            parameters: "Total recordable work-related injuries",
            ngrbcPrinciple: "Workers",
            regulatoryName: "",
            briefOfCase: "",
            lostTimeInjuriesFY: "",
            totalHoursWorked: ""
          },
          employeeFatalities: {
            safetyIncidents: "No. of fatalities",
            ngrbcPrinciple: "Employees",
            regulatoryName: "",
            briefOfCase: "",
            lostTimeInjuriesFY: "",
            totalHoursWorked: "" 
          },
          workerFatalities: {
            parameters: "No. of fatalities",
            ngrbcPrinciple: "Workers",
            regulatoryName: "",
            briefOfCase: "",
            lostTimeInjuriesFY: "", 
            totalHoursWorked: ""
          },
          highConsequenceEmployee: {
            safetyIncidents: "High consequence work-related injury or ill-health (excluding fatalities)",
            ngrbcPrinciple: "Employees",
            regulatoryName: "",
            briefOfCase: "",
            lostTimeInjuriesFY: "", 
            totalHoursWorked: ""
          },
          highConsequenceWorker: {
            parameters: "High consequence work-related injury or ill-health (excluding fatalities)",
            ngrbcPrinciple: "Workers",
            regulatoryName: "",
            briefOfCase: "",
            lostTimeInjuriesFY: "", 
            totalHoursWorked: "" 
          }
        },

        measureToEnsureSafety: "",

        numberOfComplaints: {
          workingConditions: {
            complaintType: "Working Conditions",
            currentYear: {
              filedDuringYear: "",
              resolutionPending: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              resolutionPending: "",
              remarks: ""
            }
          },
          healthSafety: {
            complaintType: "Health & Safety",
            currentYear: {
              filedDuringYear: "",
              resolutionPending: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              resolutionPending: "",
              remarks: ""
            }
          }
        },

        assessmentsForYear: [
          {
            assessmentType: "Working Conditions",
            plantsOffice: "",
            totalNumberPlants: "",
            assessedPlants: "0"
          },
          {
            assessmentType: "Health and safety practices",
            plantsOffice: "",
            totalNumberPlants: "",
            assessedPlants: "0"
          }
        ],

        correctiveActionsTaken: "",
        lifeEnsuranceEmployeeYesNo: "",
        lifeEnsuranceEmployee: "",
        lifeEnsuranceWorkerYesNo: "",
        lifeEnsuranceWorker: "",
        measureTakenForStatutoryDues: "",

        workRelatedInjuryEmployee: {
          employee: {
            category: "Employees",
            affectedEmployees: {
              currentYear: "",
              previousYear: ""
            },
            rehabilitatedEmployee: {
              currentYear: "",
              previousYear: ""
            }
          }
        },
        workRelatedInjuryWorker: {
          workers: {
            category: "Workers",
            affectedWorkers: {
              currentYear: "",
              previousYear: ""
            },
            rehabilitatedWorkers: {
              currentYear: "",
              previousYear: ""
            }
          }
        },
        transAssitanceProgramYesNo: "",
        transAssitanceProgram: "",

        assessedValueChainPartners: [
          {
            assessmentType: "Health and safety practices",
            assessedPartners: "",
            totalValueByPartners: "",
            totalValueAssessed: "",
            totalValueAssessedPercent: ""
          },
          {
            assessmentType: "Working Conditions",
            assessedPartners: "",
            totalValueByPartners: "",
            totalValueAssessed: "",
            totalValueAssessedPercent: ""
          }
        ],

        correctiveActionsForEmployees: ""
      },
      principleFour: {
        processOfIdentification: "",

        capitalExpenditure: [
          {
            stakeHolderGroup: "",
            vulnerable: "",
            channelsOfCommunication: "",
            frequencyOfEngangement: "",
            purposeOfEngangement: ""
          }
        ],
        processOfConsultation: "",
        consultationForSupport: "",
        instancesOfEngagement: ""
      },

      principleFive: {
        trainingOnHumanRightsEmployees: {
          permanent: {
            category: "Permanent",
            currentYear: {
              total: "",
              noOfEmployees: "",
              employeePercent: "0"
            },
            previousYear: {
              total: "",
              noOfEmployees: "",
              employeePercent: "0"
            }
          },
          other: {
            category: "Other than permanent",
            currentYear: {
              total: "",
              noOfEmployees: "",
              employeePercent: "0"
            },
            previousYear: {
              total: "",
              noOfEmployees: "",
              employeePercent: "0"
            }
          },
          total: {
            category: "Total Employees",
            currentYear: {
              total: "0",
              noOfEmployees: "0",
              employeePercent: "0"
            },
            previousYear: {
              total: "0",
              noOfEmployees: "0",
              employeePercent: "0"
            }
          }
        },
        trainingOnHumanRightsWorkers: {
          permanent: {
            category: "Permanent",
            currentYear: {
              total: "",
              noOfEmployees: "",
              workerPercent: "0"
            },
            previousYear: {
              total: "",
              noOfEmployees: "",
              workerPercent: "0"
            }
          },
          other: {
            category: "Other than permanent",
            currentYear: {
              total: "",
              noOfEmployees: "",
              workerPercent: "0"
            },
            previousYear: {
              total: "",
              noOfEmployees: "",
              workerPercent: "0"
            }
          },
          total: {
            category: "Total Workers",
            currentYear: {
              total: "0",
              noOfEmployees: "0",
              workerPercent: "0"
            },
            previousYear: {
              total: "0",
              noOfEmployees: "0",
              workerPercent: "0"
            }
          }
        },

        minimumWageEmployees: {
          permanent: {
            category: "Permanent",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          male: {
            category: "Male",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          female: {
            category: "Female",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          other: {
            category: "Other than Permanent",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          }
        },
        minimumWageWorkers: {
          permanent: {
            category: "Permanent",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          male: {
            category: "Male",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          female: {
            category: "Female",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          other: {
            category: "Other than Permanent",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          }
          // {
          //   category: "Male",
          //   currentYear: {
          //     total: "",
          //     equalToMinimum: "",
          //     moreThanMinimum: ""
          //   },
          //   previousYear: {
          //     total: "",
          //     equalToMinimum: "",
          //     moreThanMinimum: ""
          //   }
          // },
          // {
          //   category: "Female",
          //   currentYear: {
          //     total: "",
          //     equalToMinimum: "",
          //     moreThanMinimum: ""
          //   },
          //   previousYear: {
          //     total: "",
          //     equalToMinimum: "",
          //     moreThanMinimum: ""
          //   }
          // }
        },

        medianRemuneration: {
          board: {
            category: "Board of Directors (BoD)",
            male: {
              number: "",
              salary: ""
            },
            female: {
              number: "",
              salary: ""
            }
          },
          key: {
            category: "Key Managerial Personnel",
            male: {
              number: "",
              salary: ""
            },
            female: {
              number: "",
              salary: ""
            }
          },
          employee: {
            category: "Employees other than BoD and KMP",
            male: {
              number: "",
              salary: ""
            },
            female: {
              number: "",
              salary: ""
            }
          },
          worker: {
            category: "Workers",
            male: {
              number: "",
              salary: ""
            },
            female: {
              number: "",
              salary: ""
            }
          }
        },

        grossFemaleWages: [
          {
            title: "Gross wages paid to females as % of total wages",
            totalwages: "",
            totalwagesPaid: "",
            currentYear: "0",
            previousYear: ""
          }
        ],
        internalMechanismToRefressGrievance: "",
        processOfConsultationYesNo: "",
        processOfConsultation: "",
        noOfComplaintsByEmployee: [
          {
            category: "Sexual Harassment",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Discrimination at workplace",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Child Labour",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Forced Labour/Involuntary Labour",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Wages",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Other human rights related issues",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          }
        ],

        complaintsFiledUnderHarassment: [
          {
            category:
              "Total Complaints reported under Sexual Harassment on of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 (POSH)",
            currentYear: "",
            previousYear: ""
          },
          {
            category: "Complaints on POSH as a % of female employees / workers",
            currentYear: "",
            previousYear: ""
          },
          {
            category: "Complaints on POSH upheld",
            currentYear: "",
            previousYear: ""
          }
        ],

        preventAdverseConsequencesToComplaints: "",
        humanRightsPartOfContractYesNo: "",
        humanRightsPartOfContract: "",
        plantsAndOfficessAssessed: {
          child: {
            category: "Child labour",
            percentageAssessed: ""
          },
          forced: {
            category: "Forced/involuntary labour",
            percentageAssessed: ""
          },
          sexual: {
            category: "Sexual harassment",
            percentageAssessed: ""
          },
          discrimination: {
            category: "Discrimination at workplace",
            percentageAssessed: ""
          },
          wages: {
            category: "Wages",
            percentageAssessed: ""
          },
          others: {
            category: "Others – please specify",
            percentageAssessed: ""
          }
        },
        correctiveActionSignificantRiskForTen: "",
        detailsOfBusinessProcessModification: "",
        detailsOfScopeByHumanRights: "",
        accessibleToDifferntlyAbled: "",

        valueChainPartnerAssessed: {
          child: {
            category: "Child labour",
            percentageAssessed: "",
            totalValueBusiness: "", // New field
            totalValueAssessed: "" // New field
          },
          forced: {
            category: "Forced/involuntary labour",
            percentageAssessed: "",
            totalValueBusiness: "", // New field
            totalValueAssessed: "" // New field
          },
          sexual: {
            category: "Sexual harassment",
            percentageAssessed: "",
            totalValueBusiness: "", // New field
            totalValueAssessed: "" // New field
          },
          discrimination: {
            category: "Discrimination at workplace",
            percentageAssessed: "",
            totalValueBusiness: "", // New field
            totalValueAssessed: "" // New field
          },
          wages: {
            category: "Wages",
            percentageAssessed: "",
            totalValueBusiness: "", // New field
            totalValueAssessed: "" // New field
          },
          others: {
            category: "Others – please specify",
            percentageAssessed: "",
            totalValueBusiness: "", // New field
            totalValueAssessed: "" // New field
          }
        },

        correctiveActionSignificantRiskForFour: ""
      },
      principleSix: {
        totalEnergyConsumptionRenewable: {
          electricConsumption: {
            parameters: "Total electricity consumption (A)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          fuelConsumption: {
            parameters: "Total fuel consumption (B)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          energyConsumption: {
            parameters: "Energy consumption through other sources (C)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          totalEnergyConsumed: {
            parameters: "Total energy consumed from renewable sources (A+B+C)",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        },

        totalEnergyConsumptionRenewableNoteYesNo: "",
        totalEnergyConsumptionRenewableNote: "",

        totalEnergyConsumptionNonRenewable: {
          electricConsumption: {
            parameters: "Total electricity consumption (D)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          fuelConsumption: {
            parameters: "Total fuel consumption (E)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          energyConsumption: {
            parameters: "Energy consumption through other sources (F)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          totalEnergyConsumed: {
            parameters: "Total energy consumed from non renewable sources (D+E+F) ",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          totalEnergy: {
            parameters: "Total energy consumed (A+B+C+D+E+F)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          energyIntensity: {
            parameters: "Energy intensity per rupee of turnover (Total energy consumed / Revenue from operations)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          energyIntensityAdjusted: {
            parameters:
              "Energy intensity per rupee of turnover adjusted for Purchasing Power Parity (PPP) (Total energy consumed / Revenue from operations adjusted for PPP)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          energyIntensityPhysical: {
            parameters: "Energy intensity in terms of physical output",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          energyIntensityOptional: {
            parameters: "Energy intensity (optional) – the relevant metric may be selected by the entity",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        },

        totalEnergyConsumptionNonRenewableNote: "",
        designatedConsumerSitesYesNo: "",
        designatedConsumerSites: "",

        disclosureWater: {
          surfaceWater: {
            parameters: "(i) Surface water",
            currentYear: "",
            previousYear: ""
          },
          groundWater: {
            parameters: "(ii) Groundwater",
            currentYear: "",
            previousYear: ""
          },
          thirdParty: {
            parameters: "(iii) Third party water",
            currentYear: "",
            previousYear: ""
          },
          seaWater: {
            parameters: "(iv) Seawater / desalinated water",
            currentYear: "",
            previousYear: ""
          },
          others: {
            parameters: "(v) Others",
            currentYear: "",
            previousYear: ""
          },
          volumeOfWithdrawal: {
            parameters: "Total volume of water withdrawal (in kilo litres) (i + ii + iii + iv + v)",
            currentYear: "",
            previousYear: ""
          },
          valueOfConsumption: {
            parameters: "Total volume of water consumption (in kilo litres)",
            currentYear: "",
            previousYear: ""
          },
          waterIntensity: {
            parameters: "Water intensity per rupee of turnover (Total water consumption / Revenue from operations)",
            currentYear: "",
            previousYear: ""
          },
          waterIntensityAdjusted: {
            parameters:
              "Water intensity per rupee of turnover adjusted for Purchasing Power Parity (PPP) (Total water consumption / Revenue from operations adjusted for PPP)",
            currentYear: "",
            previousYear: ""
          },
          waterIntensityPhysical: {
            parameters: "Water intensity in terms of physical output",
            currentYear: "",
            previousYear: ""
          },
          optional: {
            parameters: "Water intensity (optional) – the relevant metric may be selected by the entity",
            currentYear: "",
            previousYear: ""
          }
        },
        disclosureWaterNoteYesNo: "",
        disclosureWaterNote: "",

        waterDischarge: {
          surfaceWater: {
            parameters: "(i) To Surface water",
            currentYear: "",
            previousYear: ""
          },
          surfaceNoTreatment: {
            parameters: "-No treatment",
            currentYear: "",
            previousYear: ""
          },
          surfaceTreatment: {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          groundWater: {
            parameters: "(ii) To Groundwater",
            currentYear: "",
            previousYear: ""
          },
          groundNoTreatment: {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          groundTreatment: {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          seaWater: {
            parameters: "(iii) To Seawater",
            currentYear: "",
            previousYear: ""
          },
          seaNoTreatment: {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          seaTreatment: {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          thirdParty: {
            parameters: "(iv) Sent to third-parties",
            currentYear: "",
            previousYear: ""
          },
          thirdPartyNoTreatment: {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          thirdPartyTreatment: {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          others: {
            parameters: "(v) Others",
            currentYear: "",
            previousYear: ""
          },
          othersNotreatment: {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          othersTreatment: {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          totalTreatment: {
            parameters: "Total water discharged (in kilolitres)",
            currentYear: "",
            previousYear: ""
          }
        },

        waterDischargeNoteYesNo: "",
        waterDischargeNote: "",
        zeroLiquidDischargeYesNo: "",
        zeroLiquidDischarge: "",

        airEmissions: {
          nox: {
            parameters: "NOx",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          sox: {
            parameters: "SOx",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          pm: {
            parameters: "Particulate matter (PM)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          pop: {
            parameters: "Persistent organic pollutants (POP)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          voc: {
            parameters: "Volatile organic compounds (VOC)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          hap: {
            parameters: "Hazardous air pollutants (HAP)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          others: {
            parameters: "Others – please specify",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        },

        airEmissionsNoteYesNo: "",
        airEmissionsNote: "",

        ghgEmissions: {
          totalS1: {
            parameters:
              "Total Scope 1 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          totalS2: {
            parameters:
              "Total Scope 2 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          turnover: {
            parameters:
              "Total Scope 1 and Scope 2 emission intensity per rupee of turnover (Total Scope 1 and Scope 2 GHG emissions / Revenue from operations)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          adjustedTurnover: {
            parameters:
              "Total Scope 1 and Scope 2 emission intensity per rupee of turnover adjusted for Purchasing Power Parity (PPP) (Total Scope 1 and Scope 2 GHG emissions / Revenue from operations adjusted for PPP)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          intensity: {
            parameters: "Total Scope 1 and Scope 2 emission intensity in terms of physical output",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          optional: {
            parameters:
              "Total Scope 1 and Scope 2 emission intensity (optional) – the relevant metric may be selected by the entity",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        },

        externalAgencyEvaluationYesNo: "",
        externalAgencyEvaluation: "",
        greenHouseReductionProjectsYesNo: "",
        greenHouseReductionProjects: "",
        externalAgencyEvaluation1YesNo: "",
        externalAgencyEvaluation1: "",

        wasteMangementByEntity: {
          plastic: {
            parameters: "Plastic waste (A)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          ewaste: {
            parameters: "E-waste (B)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          biomedical: {
            parameters: "Bio-medical waste (C)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          construction: {
            parameters: "Construction and demolition waste (D)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          battery: {
            parameters: "Battery waste (E)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          radioactive: {
            parameters: "Radioactive waste (F)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          otherHazardous: {
            parameters: "Other Hazardous waste. Please specify, if any. (G)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          otherNonHarzardous: {
            parameters:
              "Other Non-hazardous waste generated (H). Please specify, if any. (Break-up by composition i.e. by materials relevant to the sector)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          total: {
            parameters: "Total (A+B + C + D + E + F + G + H)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          wasteTurnover: {
            parameters: "Waste intensity per rupee of turnover (Total waste generated / Revenue from operations)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          wasteAdjusted: {
            parameters:
              "Waste intensity per rupee of turnover adjusted for Purchasing Power Parity (PPP) (Total waste generated / Revenue from operations adjusted for PPP)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          wasteIntensity: {
            parameters: "Waste intensity in terms of physical output",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          optional: {
            parameters: "Waste intensity (optional) – the relevant metric may be selected by the entity",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        },

        wasteMangementByEntityNote: "",

        wasteRecovered: [
          {
            parameters: "(i) Recycled",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(ii) Re-used",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iii) Other recovery operations",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        ],

        wasteRecoveredNote: "",
        totalWasteDisposed: [
          {
            parameters: "(i) Incineration",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(ii) Landfilling",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iii) Other disposal operations",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        ],

        totalWasteDisposedNote: "",
        wasteManagementPractice: "",
        operationInSensitiveAreas: [
          {
            location: "",
            typeOfOperations: "",
            clearanceComplied: ""
          }
        ],

        environmentalImpact: [
          {
            name: "",
            eiaNo: "",
            date: "",
            externalAgency: "",
            publicCommunication: "",
            webLink: ""
          }
        ],
        environMentalLawCompliance: [
          {
            specifyLaw: "",
            detailsOfNonCompliance: "",
            conditionsComplied: "",
            correctiveActions: ""
          }
        ],

        waterWithdrawel: [
          {
            nameOfArea: "",
            detailsOfNonCompliance: "",
            natureOfOperation: ""
          }
        ],
        waterWithdrawelNameOfArea: "",
        waterWithdrawelNatureOfOperation: "",

        waterWithdrawelInFormat: [
          {
            parameters: "(i) Surface water",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(ii) Groundwater",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iii) Third party water",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iv) Seawater / desalinated water",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(v) Others",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total volume of water withdrawal (in kilolitres) (i + ii + iii + iv + v)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total volume of water consumption (in kilolitres)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Water intensity per rupee of turnover (Total water consumption / Revenue from operations)",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
          // {
          //   parameters: "Water intensity (optional) – the relevant metric may be selected by the entity",
          //   unit:"",
          //   currentYear: "",
          //   previousYear: ""
          // }
        ],

        waterDischargeByDestination: [
          {
            parameters: "(i) Surface water",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(ii) To Groundwater",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iii) To Seawater",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iv) Sent to third-parties",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(v) Others",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total water discharged (in kilo litres)",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        ],
        waterDischargeByDestinationYesNo: "",
        waterDischargeByDestinationNote: "",

        scope3EmissionDetails: [
          {
            parameters:
              "Total Scope 3 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total Scope 3 emissions per rupee of turnover",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters:
              "Total Scope 3 emission intensity (optional) – the relevant metric may be selected by the entity",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        ],
        scope3EmissionDetailsYesNo: "",
        scope3EmissionDetailsNote: "",
        impactOnBiodiversity: "",
        innovativeTechnologyUsed: [
          {
            initiativeUndertaken: "",
            initiativeDetails: "",
            intiativeOutcome: ""
          }
        ],
        disasterManagementPlan: "",
        impactOnEnviromentByValuChain: "",
        percentageOfValuChainPartners: ""
      },
      principleSeven: {
        affiliatedWithTrade: "",
        topTenTradeChambers: {
          association1: {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          association2: {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          association3: {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          association4: {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          association5: {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          association6: {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          association7: {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          association8: {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          association9: {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          association10: {
            nameOfTrade: "",
            reachOfTrade: ""
          }
        },
        correctiveActionsTakenBrief: [
          {
            nameOfAuthority: "",
            briefOfCase: "",
            correctiveActions: ""
          }
        ],
        publicPolicyPositionDetails: [
          {
            publicPolicyAdvocate: "",
            infoAvailableToPublic: "",
            frequencyOfReview: "",
            webLink: ""
          }
        ]
      },
      principleEight: {
        socialImpactAssessmentDetails: [
          {
            projectName: "",
            siaNotifNo: "",
            dateOfNotif: "",
            conductedByExternalAgency: "",
            resultInPublicDomain: "",
            webLink: ""
          }
        ],
        rehabProjectInfo: [
          {
            projectName: "",
            state: "",
            district: "",
            projectAffectedFamily: "",
            totalPaf: "",
            pafCovered: "0%",
            pafAmountPaid: ""
          }
        ],
        grievanceMechanism: "",
        percentInputMaterial: {
          msme: {
            title: "Directly sourced from MSMEs/ small producers",
            values: "",
            currentYear: "0%",
            previousYear: ""
          },
          withinDistrict: {
            title: "Sourced directly from within the district and neighbouring districts",
            values: "",
            currentYear: "0%",
            previousYear: ""
          },
          direct: {
            title: "Directly from within India",
            values: "",
            currentYear: "0%",
            previousYear: ""
          },
          total: {
            title: "Total Input Sourced",
            values: "",
            currentYear: "0%",
            previousYear: ""
          }
        },
        jobCreationSmallTown: {
          rural: {
            location: "Rural",
            values: "",
            currentYear: "",
            previousYear: ""
          },
          semiUrban: {
            location: "Semi-urban",
            values: "",
            currentYear: "",
            previousYear: ""
          },
          urban: {
            location: "Urban",
            values: "",
            currentYear: "",
            previousYear: ""
          },
          metro: {
            location: "Metropolitan",
            values: "",
            currentYear: "",
            previousYear: ""
          },
          total: {
            location: "Total wages",
            values: "",
            currentYear: "",
            previousYear: ""
          }
        },
        negativeSocialImpactMitigation: [
          {
            negativeSocialImpact: "",
            correctiveActions: ""
          }
        ],
        csrProject: [
          {
            state: "",
            aspDistrict: "",
            amountSpent: ""
          }
        ],
        preferentialProcurementPolicy: "",
        preferentialProcurementPolicyYesNo: "",
        procurementGroup: "",
        percentOfTotalProcurement: "",
        benefitsDerivedFromProperty: [
          {
            intellectualProperty: "",
            owned: "",
            benifitsShared: "",
            basisOfCalculation: ""
          }
        ],
        correctiveActionUnderwayOrTaken: [
          {
            authorityName: "",
            briefOfCase: "",
            correctiveActions: ""
          }
        ],
        csrProjectTwo: [
          {
            csrProjectName: "",
            peopleBenifited: "",
            vulnerablePeople: ""
          }
        ]
      },
      principleNine: {
        consumerComplaintResponseMechanism: "",
        productTurnover: {
          environment: {
            title: "Environmental and social parameters relevant to the product",
            carryInfo: "",
            percentOfTotal: "0%"
          },
          saferesponsible: {
            title: "Safe and responsible usage",
            carryInfo: "",
            percentOfTotal: "0%"
          },
          recycle: {
            title: "Recycling and/or safe disposal",
            carryInfo: "",
            percentOfTotal: "0%"
          },
          total: {
            title: "Total turnover",
            carryInfo: "",
            percentOfTotal: "-"
          }
        },
        totalConsumerComplaints: {
          dataPrivacy: {
            category: "Data privacy",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          advertising: {
            category: "Advertising",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          cyberSecurity: {
            category: "Cyber-security",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          delivery: {
            category: "Delivery of essential services",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          restrictive: {
            category: "Restrictive Trade Practices",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          unfairPractice: {
            category: "Unfair Trade Practices",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          other: {
            category: "Other",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          }
        },
        productRecallInstanceDetail: [
          {
            title: "Voluntary recalls",
            number: "",
            reason: ""
          },
          {
            title: "Forced recalls",
            number: "",
            reason: ""
          }
        ],
        policyOnCyberSecurity: "",
        policyOnCyberSecurityYesNo: "",
        correctiveActionAdvertising: "",
        dataBreachInstances: "",
        personalInformationDataBreach: "",
        dataBreachImpact: "",
        productAccessPlatforms: [
          {
            product: "",
            channel: ""
          }
        ],
        safeUsageInformationProvided: "",
        mechanismToInformConsumerOfRisk: "",
        entityDisplaysProductInfo: "",
        entityDisplaysProductInfoYesNo: ""
      }
    }
  },
  pageWiseErrors: {
    SectionA: {
      corporateNumberError: false
    }
  }
};

export const brsrReportSlice = createSlice({
  name: "brsrReport",
  initialState: initialBrsrReportState,
  reducers: {
    setPageWiseValue: (state, action) => {
      return {
        ...state,
        pageWiseValue: action.payload
      };
    },

    setPageWiseErrors: (state, action) => {
      return {
        ...state,
        pageWiseErrors: action.payload
      };
    }
  }
});

export const { setPageWiseValue, setPageWiseErrors } = brsrReportSlice.actions;

export default brsrReportSlice.reducer;
