import React from "react";
import Accordion from "../../../../../components/Accordion/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { setPageWiseData } from "../../../../../state/SocialSlice";
import { numberRegex } from "../../../../../helper/Rejex";
const TrainingEducation = ({ formEditable, editMode }) => {
  const { pageWiseData } = useSelector((state) => state.social);

  const dispatch = useDispatch();

  let socialAllData = JSON.parse(JSON.stringify(pageWiseData));

  const handleByGender = (e, index) => {
    if (numberRegex.test(e.target.value) || e.target.value === "") {
      socialAllData.trainingAndEducation.reportingPeriod.byGender[index][e.target.name] = e.target.value;
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleByEmployeeCategoryChange = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      socialAllData.trainingAndEducation.reportingPeriod.byEmployeeCategory[index][name] = value;
    }

    dispatch(setPageWiseData(socialAllData));
  };

  const handleUpgradingEmployeeskill = (e) => {
    if (numberRegex.test(e.target.value) || e.target.value === "") {
      socialAllData.trainingAndEducation.assistanceProgram.upgradingEmployeeskill.foreword = e.target.value;
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleCareerEndingsResulting = (e) => {
    if (numberRegex.test(e.target.value) || e.target.value === "") {
      socialAllData.trainingAndEducation.careerEndingsResulting.foreword = e.target.value;
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleByGenderChange = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "percentage") {
        if (value <= 100) {
          socialAllData.trainingAndEducation.employeePercentageOfCareerDevelopment.byGender[index][name] = value;
        }
      } else {
        socialAllData.trainingAndEducation.employeePercentageOfCareerDevelopment.byGender[index][name] = value;
      }
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleByEmployeeCategoryPercentageChange = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "percentage") {
        if (value <= 100) {
          socialAllData.trainingAndEducation.employeePercentageOfCareerDevelopment.byEmployeeCategory[index][name] =
            value;
        }
      } else {
        socialAllData.trainingAndEducation.employeePercentageOfCareerDevelopment.byEmployeeCategory[index][name] =
          value;
      }
    }
    dispatch(setPageWiseData(socialAllData));
  };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion
          title={
            "Average hours of training that the organization’s employees have undertaken during the reporting period"
          }
          index={0}
        >
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">By Gender</p>
            <table className="form-table mb-4">
              <thead>
                <tr>
                  <th scope="col">Gender</th>
                  <th scope="col">Average training hours </th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.trainingAndEducation?.reportingPeriod?.byGender.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.title}</td>
                    <td>
                      <input
                        type="text"
                        name="averageTrainingHours"
                        value={item.averageTrainingHours}
                        onChange={(e) => handleByGender(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <table className="form-table mb-4">
            <thead>
              <tr>
                <th scope="col">By Employee Category</th>
                <th scope="col">Average training hours  </th>
              </tr>
            </thead>
            <tbody>
              {socialAllData?.trainingAndEducation?.reportingPeriod?.byEmployeeCategory.map((item, index) => (
                <tr key={index + item}>
                  {index === 0 || index === 3 || index === 6 || index === 9 || index === 12 ? (
                    <>
                      <td colSpan={2}>{item.category}</td>
                    </>
                  ) : (
                    <>
                      <td>{item.category}</td>
                      <td>
                        <input
                          type="text"
                          name="averageTrainingHours"
                          value={item.averageTrainingHours}
                          onChange={(e) => handleByEmployeeCategoryChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion title={"Programs for upgrading employee skills and transition assistance program"} index={1}>
          <div className="foreword-container mt-1 mb-4">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                Type and scope of programs implemented and assistance provided to upgrade employee skills
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData?.trainingAndEducation?.assistanceProgram?.upgradingEmployeeskill.foreword}
                  name="totalNumberOfIncidents"
                  onChange={(e) => handleUpgradingEmployeeskill(e)}
                  className="no-style-input w-100"
                  disabled={editMode && !formEditable}
                />
              </div>
            </div>
          </div>

          <div className="foreword-container mb-4">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                Transition assistance programs provided to facilitate continued employability and the management of
                career endings resulting from retirement or termination of employment
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData?.trainingAndEducation?.assistanceProgram?.careerEndingsResulting.foreword}
                  name="totalNumberOfIncidents"
                  onChange={(e) => handleCareerEndingsResulting(e)}
                  className="no-style-input w-100"
                  disabled={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion
          title={"Percentage of employees who received a regular performance and career development"}
          index={2}
        >
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">By Gender</p>
            <table className="form-table mb-4">
              <thead>
                <tr>
                  <th scope="col">Gender</th>
                  <th scope="col">Number </th>
                  <th scope="col">Percentage</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData.trainingAndEducation.employeePercentageOfCareerDevelopment.byGender.map(
                  (item, index) => (
                    <tr key={item + index}>
                      <td>{item.title}</td>
                      <td>
                        <input
                          type="text"
                          name="number"
                          value={item.number}
                          onChange={(e) => handleByGenderChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="percentage"
                          value={item.percentage}
                          onChange={(e) => handleByGenderChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <table className="form-table mb-4">
            <thead>
              <tr>
                <th scope="col">By Employee Category</th>
                <th scope="col">Number</th>
                <th scope="col">Percentage</th>
              </tr>
            </thead>
            <tbody>
              {socialAllData.trainingAndEducation.employeePercentageOfCareerDevelopment.byEmployeeCategory.map(
                (item, index) => (
                  <tr key={index + item}>
                    {index === 0 || index === 3 || index === 6 || index === 9 || index === 12 ? (
                      <>
                        <td colSpan={3}>{item.category}</td>
                      </>
                    ) : (
                      <>
                        <td>{item.category}</td>
                        <td>
                          <input
                            type="text"
                            name="number"
                            value={item.number}
                            onChange={(e) => handleByEmployeeCategoryPercentageChange(e, index)}
                            className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                            readOnly={editMode && !formEditable}
                          ></input>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="percentage"
                            value={item.percentage}
                            onChange={(e) => handleByEmployeeCategoryPercentageChange(e, index)}
                            className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                            readOnly={editMode && !formEditable}
                          ></input>
                        </td>
                      </>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </Accordion>
      </div>
    </div>
  );
};

export default TrainingEducation;
