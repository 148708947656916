export const InvoiceEndPoint = (build) => ({
  getInvoiceList: build.query({
    query: (arg) => ({
      url: `cbam/invoice`,
      method: "GET",
      params: { ...arg }
    }),
    providesTags: ["Invoice"]
  }),
  DeleteInvoice: build.mutation({
    query: (id) => ({
      url: `cbam/invoice/delete/${id}`,
      method: "DELETE"
    }),
    invalidatesTags: ["Invoice"]
  }),
  CalculateEmission: build.mutation({
    query: (body) => ({
      url: `cbam/invoice/calculateEmission`,
      method: "POST",
      body: body
    }),
    invalidatesTags: ["Invoice"]
  }),

  CreateInvoice: build.mutation({
    query: (body) => ({
      url: `cbam/invoice`,
      method: "POST",
      body: body
    }),
    invalidatesTags: ["Invoice"]
  }),
  GetInvoice: build.query({
    query: (id) => ({
      url: `cbam/invoice/details/${id}`,
      method: "GET"
    }),
    invalidatesTags: ["Invoice"]
  }),
  GetBreakdownList: build.query({
    query: (arg) => ({
      url: `cbam/breakdown`,
      method: "GET",
      params: { ...arg }
    }),
    providesTags: ["Invoice"]
  }),
  PatchInvoice: build.mutation({
    query: ({ invoiceId, invoicePayload }) => ({
      url: `cbam/invoice/update/${invoiceId}`,
      method: "PATCH",
      body: invoicePayload
    }),
    invalidatesTags: ["Invoice"]
  }),
  MakePdfInvoice: build.mutation({
    query: ({ invoiceId, pdfPayload }) => ({
      url: `cbam/invoice/generate/${invoiceId}`,
      method: "POST",
      body: pdfPayload
    }),
    invalidatesTags: ["Invoice"]
  })
});
