import { Checkbox, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomModal from "../../../../components/Modal/Modal";
import ButtonLoader from "../../../../components/Loaders/ButtonLoader";
import { useSnackbar } from "../../../../components/snackbar/SnackbarContext";
import {
  useDeleteUserGroupMutation,
  useLazyGetUserDetailByIdQuery,
  useLazyGetUserGroupDetailQuery,
  usePatchUserGroupMutation,
  usePostUserGroupMutation,
  usePostAuditLogDataMutation,
  useGetOrganizationDataQuery
} from "../../../../state/api";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import { useSelector } from "react-redux";
import findChangedFields from "../../../../helper/Utils/utilitiyFunctions";

const Checklist = () => {
  const [deleteUserGroup] = useDeleteUserGroupMutation();
  const [updateUserGroup] = usePatchUserGroupMutation();
  const [createUserGroup] = usePostUserGroupMutation();
  const [getUserGroupDetail, resultUserGroupDetail] = useLazyGetUserGroupDetailQuery();
  const orgData = useGetOrganizationDataQuery();

  const [postAuditLogData] = usePostAuditLogDataMutation({});

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  let group = searchParams.get("group") ? searchParams.get("group") : "";
  const location = useLocation();

  let groupId = location.state?.groupId;
  let userCount = location.state?.userCount;

  const [groupName, setGroupName] = useState(group);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { openSnackbar } = useSnackbar();

  const dashboard = [{ label: "View Dashboard", value: "viewData" }];
  const analysis = [{ label: "View Analysis", value: "viewData" }];

  const measure = [
    { label: 'View "Emission Data"', value: "viewData" },
    { label: 'Edit "Emission Data"', value: "editData" },
    { label: "Can Add Batch Data", value: "bulkUpload" },
    { label: "View Consumption Data", value: "viewConsumptionData" },
    { label: "Edit Consumption Data", value: "editConsumptionData" },
    { label: "Edit Air Emission Data", value: "editAirData" },
    { label: "View Air Emission Data", value: "viewAirData" },
    { label: "Edit Water Data", value: "editWaterData" },
    { label: "View Water Data", value: "viewWaterData" },
    { label: "Edit Energy Data", value: "editEnergyData" },
    { label: "View Energy Data", value: "viewEnergyData" },
    { label: "Edit Waste Data", value: "editWasteData" },
    { label: "View Waste Data", value: "viewWasteData" },
    { label: "Edit Social Data", value: "editSocialData" },
    { label: "View Social Data", value: "viewSocialData" },
    { label: "Edit Governance Data", value: "editGovtData" },
    { label: "View Governance Data", value: "viewGovtData" },
    { label: "Edit Scope 1 Data", value: "editscope1" },
    { label: "View Scope 1 Data", value: "viewscope1" },
    { label: "Edit Scope 2 Data", value: "editscope2" },
    { label: "View Scope 2 Data", value: "viewscope2" },
    { label: "Edit Scope 3 Data", value: "editscope3" },
    { label: "View Scope 3 Data", value: "viewscope3" },
    { label: "View Data Quality", value: "viewdataquality" },
    { label: "Edit Data Quality", value: "editdataquality" }
  ];

  const act = [
    { label: "View Targets", value: "viewTarget" },
    { label: "Edit Targets", value: "editTarget" },
    { label: "View Simulation", value: "viewSimulation" },
    { label: "Edit Simulation", value: "editSimulation" },
    { label: "View Reduction Measure (Simulation)", value: "viewReductionMeasure" },
    { label: "Edit Reduction Measure (Simulation)", value: "editReductionMeasure" },
    { label: "View Action Plan", value: "viewActionPlan" },
    { label: "Edit Action Plan", value: "editActionPlan" },
    { label: "View Reduction Action", value: "viewReductionAction" },
    { label: "Edit Reduction Action", value: "editReductionAction" }
  ];

  const reduce = [
    { label: "Can see Klean", value: "viewKlean" },
    { label: "Can purchase Klean", value: "editKlean" },
    { label: "Can see Offset", value: "viewOffset" },
    { label: "Can purchase Offset", value: "editOffset" }
  ];

  const reports = [
    { label: "Can Access BRSR", value: "brsrReport" },
    { label: "Can Access GRI", value: "griReport" },
    { label: "Can Access CDP", value: "cdpReport" },
    { label: "Can Access TCFD", value: "tcfdReport" }
  ];

  const accessReports = [
    { label: "View Reports", value: "viewReport" },
    { label: "Edit Reports", value: "editReport" }
  ];

  const adminPermissions = [
    { label: "View Organization", value: "viewOrganization" },
    { label: "Edit Organization", value: "editOrganization" },
    { label: "View Facility", value: "viewFacility" },
    { label: "Edit Facility", value: "editFacility" },
    { label: "View User", value: "viewUser" },
    { label: "Edit User", value: "editUser" },
    { label: "View Permissions", value: "viewPermissions" },
    { label: "Edit Permissions", value: "editPermissions" }
  ];

  const cbam = [
    { label: "View Products", value: "viewProducts" },
    { label: "Edit Products", value: "editProducts" },
    { label: "View Importers", value: "viewImporters" },
    { label: "Edit Importers", value: "editImporters" },
    { label: "View Invoice", value: "viewInvoice" },
    { label: "Edit Invoice", value: "editInvoice" },
    { label: "View Breakdown Details", value: "viewBreakdown" }
  ];

  const brsrReport = [{ label: "Approve BRSR", value: "approveBRSR" }];

  const sections = [
    { label: "Cover Page", value: "coverPage" },
    { label: "Section A", value: "sectionA" },
    { label: "Section B", value: "sectionB" }
  ];

  const sectionC = [
    { label: "Principle 1", value: "p1" },
    { label: "Principle 2", value: "p2" },
    { label: "Principle 3", value: "p3" },
    { label: "Principle 4", value: "p4" },
    { label: "Principle 5", value: "p5" },
    { label: "Principle 6", value: "p6" },
    { label: "Principle 7", value: "p7" },
    { label: "Principle 8", value: "p8" },
    { label: "Principle 9", value: "p9" }
  ];

  const [checkedList, setCheckedList] = useState({
    dashboard: [],
    analysis: [],
    measure: [],
    act: [],
    reduce: [],
    reports: [],
    accessReports: [],
    cbam: [],
    adminPermissions: [],
    brsrReport: [],
    sections: [],
    sectionC: []
  });

  const [prevCheckedList, setPrevCheckedList] = useState({
    dashboard: [],
    analysis: [],
    measure: [],
    act: [],
    reduce: [],
    reports: [],
    accessReports: [],
    cbam: [],
    adminPermissions: [],
    brsrReport: [],
    sections: [],
    sectionC: []
  });

  const checkAllMeasure = measure.length === checkedList.measure.length;
  const indeterminateMeasure = checkedList.measure.length > 0 && checkedList.measure.length < measure.length;

  const checkAllAct = act.length === checkedList.act.length;
  const indeterminateAct = checkedList.act.length > 0 && checkedList.act.length < act.length;

  const checkAllDashboard = dashboard.length === checkedList.dashboard.length;
  const indeterminateDashboard = checkedList.dashboard.length > 0 && checkedList.dashboard.length < dashboard.length;

  const checkAllAnalysis = analysis.length === checkedList.analysis.length;
  const indeterminateAnalysis = checkedList.analysis.length > 0 && checkedList.analysis.length < analysis.length;

  const checkAllReduce = reduce.length === checkedList.reduce.length;
  const indeterminateReduce = checkedList.reduce.length > 0 && checkedList.reduce.length < reduce.length;

  const checkAllReport = reports.length === checkedList.reports.length;
  const indeterminateReport = checkedList.reports.length > 0 && checkedList.reports.length < reports.length;

  const checkAllAccessReport = accessReports.length === checkedList.accessReports.length;
  const indeterminateAccessReport =
    checkedList.accessReports.length > 0 && checkedList.accessReports.length < accessReports.length;

  const checkAllCbam = cbam.length === checkedList.cbam.length;
  const indeterminateCbam = checkedList.cbam.length > 0 && checkedList.cbam.length < cbam.length;

  const checkAllAdminPermissions = adminPermissions.length === checkedList.adminPermissions.length;
  const indeterminateAdminPermissions =
    checkedList.adminPermissions.length > 0 && checkedList.adminPermissions.length < adminPermissions.length;

  const checkAllBrsrReport = brsrReport.length === checkedList.brsrReport.length;
  const indeterminateBrsrReport =
    checkedList.brsrReport.length > 0 && checkedList.brsrReport.length < brsrReport.length;

  const checkAllSections = sections.length === checkedList.sections.length;
  const indeterminateSections = checkedList.sections.length > 0 && checkedList.sections.length < sections.length;

  const checkAllSectionC = sectionC.length === checkedList.sectionC.length;
  const indeterminateSectionC = checkedList.sectionC.length > 0 && checkedList.sectionC.length < sectionC.length;

  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    if (groupId) {
      getUserGroupDetail({ id: groupId });
    }
  }, []);

  useEffect(() => {
    let convertedObject = {};
    if (resultUserGroupDetail.data?.data) {
      for (const [key, value] of Object.entries(resultUserGroupDetail.data?.data.permissions)) {
        if (value && Object.keys(value).length > 0) {
          convertedObject[key] = Object.keys(value).filter((subKey) => subKey !== "fullAccess" && value[subKey]);
        } else {
          convertedObject[key] = [];
        }
      }

      let Obj = {
        dashboard: convertedObject.dashboard,
        analysis: convertedObject.analysis,
        measure: convertedObject.measure,
        act: convertedObject.act,
        reduce: convertedObject.reduce,
        reports: convertedObject.reports,
        accessReports: convertedObject.accessReports ? convertedObject.accessReports : [],
        cbam: convertedObject.cbam ? convertedObject.cbam : [],
        adminPermissions: convertedObject.adminPermissions ? convertedObject.adminPermissions : [],
        brsrReport: convertedObject.brsrReport ? convertedObject.brsrReport : [],
        sections: convertedObject.sections ? convertedObject.sections : [],
        sectionC: convertedObject.sectionC ? convertedObject.sectionC : []
      };

      setCheckedList(Obj);
      setPrevCheckedList(Obj);
    }
  }, [resultUserGroupDetail.data?.data]);

  const onChange = (name, list) => {
    switch (name) {
      case "act":
        if (list.includes("editSimulation") && !checkedList.act.includes("editSimulation")) {
          list = [...list, "viewReductionMeasure", "editReductionMeasure", "viewSimulation"];
        } else if (!list.includes("editSimulation") && checkedList.act.includes("editSimulation")) {
          let valuesToRemove = ["viewReductionMeasure", "editReductionMeasure"];
          list = list.filter((item) => !valuesToRemove.includes(item));
        } else if (list.includes("viewReductionMeasure") && list.includes("editReductionMeasure")) {
          list = [...list, "editSimulation"];
        } else if (
          (list.includes("viewReductionMeasure") && !list.includes("editReductionMeasure")) ||
          (!list.includes("viewReductionMeasure") && list.includes("editReductionMeasure"))
        ) {
          list = list.filter((item) => !["editSimulation"].includes(item));
        }

        if (list.includes("editActionPlan") && !checkedList.act.includes("editActionPlan")) {
          list = [...list, "viewActionPlan"];
        }
        if (list.includes("editTarget") && !checkedList.act.includes("editTarget")) {
          list = [...list, "viewTarget"];
        }
        if (list.includes("editReductionAction") && !checkedList.act.includes("editReductionAction")) {
          list = [...list, "viewReductionAction"];
        }
        break;

      case "measure":
        if (list.includes("editData") && !checkedList.measure.includes("editData")) {
          list = [...list, "viewData"];
        }
        if (list.includes("editConsumptionData") && !checkedList.measure.includes("editConsumptionData")) {
          list = [...list, "viewConsumptionData"];
        }
        if (list.includes("editAirData") && !checkedList.measure.includes("editAirData")) {
          list = [...list, "viewAirData"];
        }
        if (list.includes("editWaterData") && !checkedList.measure.includes("editWaterData")) {
          list = [...list, "viewWaterData"];
        }
        if (list.includes("editEnergyData") && !checkedList.measure.includes("editEnergyData")) {
          list = [...list, "viewEnergyData"];
        }
        if (list.includes("editWasteData") && !checkedList.measure.includes("editWasteData")) {
          list = [...list, "viewWasteData"];
        }
        if (list.includes("editSocialData") && !checkedList.measure.includes("editSocialData")) {
          list = [...list, "viewSocialData"];
        }
        if (list.includes("editGovtData") && !checkedList.measure.includes("editGovtData")) {
          list = [...list, "viewGovtData"];
        }
        if (list.includes("editscope1") && !checkedList.measure.includes("editscope1")) {
          list = [...list, "viewscope1"];
        }
        if (list.includes("editscope2") && !checkedList.measure.includes("editscope2")) {
          list = [...list, "viewscope2"];
        }
        if (list.includes("editscope3") && !checkedList.measure.includes("editscope3")) {
          list = [...list, "viewscope3"];
        }
        if (list.includes("editdataquality") && !checkedList.measure.includes("editdataquality")) {
          list = [...list, "viewdataquality"];
        }
        break;

      case "reduce":
        if (list.includes("editKlean") && !checkedList.reduce.includes("editKlean")) {
          list = [...list, "viewKlean"];
        }
        if (list.includes("editOffset") && !checkedList.reduce.includes("editOffset")) {
          list = [...list, "viewOffset"];
        }
        break;

      case "accessReports":
        if (list.includes("editReport") && !checkedList.accessReports.includes("editReport")) {
          list = [...list, "viewReport"];
        }
        break;

      case "cbam":
        if (list.includes("editProducts") && !checkedList.cbam.includes("editProducts")) {
          list = [...list, "viewProducts"];
        }
        if (list.includes("editImporters") && !checkedList.cbam.includes("editImporters")) {
          list = [...list, "viewImporters"];
        }
        if (list.includes("editInvoice") && !checkedList.cbam.includes("editInvoice")) {
          list = [...list, "viewInvoice"];
        }
        break;

      case "adminPermissions":
        if (list.includes("editOrganization") && !checkedList.adminPermissions.includes("editOrganization")) {
          list = [...list, "viewOrganization"];
        }
        if (list.includes("editFacility") && !checkedList.adminPermissions.includes("editFacility")) {
          list = [...list, "viewFacility"];
        }
        if (list.includes("editUser") && !checkedList.adminPermissions.includes("editUser")) {
          list = [...list, "viewUser"];
        }
        if (list.includes("editPermissions") && !checkedList.adminPermissions.includes("editPermissions")) {
          list = [...list, "viewPermissions"];
        }
        break;

      default:
        break;
    }
    list = [...new Set(list)];
    let obj = { ...checkedList, [name]: list };
    setCheckedList(obj);
  };

  const onCheckAllChange = (e, name) => {
    let modifiedArray;
    switch (name) {
      case "measure":
        modifiedArray = measure.map((item) => item.value);
        break;
      case "act":
        modifiedArray = act.map((item) => item.value);
        break;
      case "dashboard":
        modifiedArray = dashboard.map((item) => item.value);
        break;
      case "analysis":
        modifiedArray = analysis.map((item) => item.value);
        break;
      case "reduce":
        modifiedArray = reduce.map((item) => item.value);
        break;
      case "reports":
        modifiedArray = reports.map((item) => item.value);
        break;
      case "accessReports":
        modifiedArray = accessReports.map((item) => item.value);
        break;
      case "cbam":
        modifiedArray = cbam.map((item) => item.value);
        break;
      case "adminPermissions":
        modifiedArray = adminPermissions.map((item) => item.value);
        break;
      case "brsrReport":
        modifiedArray = brsrReport.map((item) => item.value);
        break;
      case "sections":
        modifiedArray = sections.map((item) => item.value);
        break;
      case "sectionC":
        modifiedArray = sectionC.map((item) => item.value);
        break;
      default:
        break;
    }

    setCheckedList(e.target.checked ? { ...checkedList, [name]: modifiedArray } : { ...checkedList, [name]: [] });
  };

  const isEmptyObject = (obj) => {
    for (const key in obj) {
      if (obj[key].length > 0) {
        return false;
      }
    }
    return true;
  };

  function formatChangeMessage(changedFields, type) {
    let message = ``;

    for (const [field, { prevValue, currentValue }] of Object.entries(changedFields)) {
      message += `'${field}' of  '${prevValue}' to '${currentValue}'.\n `;
    }

    return message;
  }

  const saveGroup = async () => {
    const transformedObject = {};

    setLoading(true);
    if (groupName !== "") {
      if (isEmptyObject(checkedList)) {
        setError("At least One User Permission Must Be Defined");
        setLoading(false);
        return;
      }
    } else {
      setError("Group Name must be entered");
      setLoading(false);
      return;
    }

    for (const [category, permissions] of Object.entries(checkedList)) {
      transformedObject[category] = {};
      switch (category) {
        case "dashboard":
          if (permissions.length !== 0) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "analysis":
          if (permissions.length !== 0) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "measure":
          if (permissions.length === measure.length) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "act":
          if (permissions.length === act.length) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "reduce":
          if (permissions.length === reduce.length) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "reports":
          if (permissions.length === reports.length) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "accessReports":
          if (permissions.length === accessReports.length) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "cbam":
          if (permissions.length === cbam.length) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "adminPermissions":
          if (permissions.length === adminPermissions.length) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "brsrReport":
          if (permissions.length === brsrReport.length) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "sections":
          if (permissions.length === sections.length) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        case "sectionC":
          if (permissions.length === sectionC.length) {
            transformedObject[category]["fullAccess"] = true;
          } else {
            transformedObject[category]["fullAccess"] = false;
          }
          break;
        default:
          break;
      }
      permissions.forEach((permission) => {
        transformedObject[category][permission] = true;
      });
    }

    try {
      let response;
      if (groupId) {
        response = await updateUserGroup({
          id: groupId,
          postBody: { name: groupName, permissions: transformedObject }
        });
      } else {
        response = await createUserGroup({ postBody: { name: groupName, permissions: transformedObject } });
      }
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        if (groupId) {
          let changes = findChangedFields(prevCheckedList, checkedList);

          console.log("changes",changes , prevCheckedList);
          
          if (Object.keys(changes).length > 0) {
            postAuditLogData({
              action: `UPDATE`,
              message: `${formatChangeMessage(changes)}  update by ${user.name} `,
              tab: "Permissions",
              facility: "",
              organization: orgData.data?.data._id,
              user: userId._id,
              ipAddress: "10.10.0.1"
            });
          }else{
            // postAuditLogData({
            //   action: `UPDATE`,
            //   message: `${groupName}  update by ${user.name} `,
            //   tab: "Permissions",
            //   facility: "",
            //   organization: orgData.data?.data._id,
            //   user: userId._id,
            //   ipAddress: "10.10.0.1"
            // });
          }
         
        } else {
          postAuditLogData({
            action: `CREATE`,
            message: `User Group '${groupName}' has been created by ${user.name} `,
            tab: "Permissions",
            facility: "",
            organization: orgData.data?.data._id,
            user: userId._id,
            ipAddress: "10.10.0.1"
          });
        }

        openSnackbar(response.data?.message, "success");
        navigate("/user/permissions");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "error");
    }

    setLoading(false);
  };

  const confirmDelete = async () => {
    try {
      const response = await deleteUserGroup(groupId);
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        postAuditLogData({
          action: "DELETE",
          message: `User Group '${groupName}' has been deleted by ${user.name} `,
          tab: "Permissions",
          facility: "",
          organization: orgData.data?.data._id,
          user: userId._id,
          ipAddress: "10.10.0.1"
        });
        openSnackbar(response.data?.message, "success");
        navigate("/user/permissions");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "success");
    }
  };

  const setGpName = (e) => {
    if (group === "Admin") {
      return;
    }
    setGroupName(e.target.value);
  };

  return (
    <>
      <CustomModal modalOpen={deleteModalOpen} setModalOpen={setDeleteModalOpen} outSideClose={false}>
        <Box sx={{ width: 500 }}>
          {userCount !== 0 ? (
            <p className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>
              This user group cannot be deleted as it is has users associated with it
            </p>
          ) : (
            <p className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>
              Are you sure you want to delete this user group permanently?{" "}
            </p>
          )}

          <div className={`d-flex gap-3`} style={{ marginTop: "29px" }}>
            <div
              style={
                userCount !== 0 ? { flex: "1 1 auto", display: "flex", justifyContent: "center" } : { width: "50%" }
              }
            >
              <button
                type="button"
                className="btn btn-outline-success"
                style={userCount !== 0 ? { width: "160px" } : {}}
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
            </div>
            {userCount === 0 && (
              <button
                type="button"
                className="btn btn-danger"
                style={{ width: "50%" }}
                onClick={confirmDelete}
                disabled={isLoading}
              >
                {isLoading ? <ButtonLoader /> : "Delete"}
              </button>
            )}
          </div>
        </Box>
      </CustomModal>
      <div className="scopeDesc">
        <p className="page-header-text">User Group and Permissions</p>
        <div className="d-flex justify-content-between align-items-center">
          <p style={{ fontSize: "16px" }}>Create user groups, assign specific permissions as per your choice</p>
          {groupName !== "Admin" && (
            <div className="d-flex gap-2">
              <button type="button" className="btn btn-success" onClick={saveGroup}>
                Save
              </button>
              {group && (
                <button type="button" className="btn btn-outline-danger" onClick={() => setDeleteModalOpen(true)}>
                  Delete
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="data-container-box">
        <div className="d-flex justify-content-between">
          <div>
            <p className="labelText">
              Group Name <sup className="color-red">*</sup>
            </p>
            <input
              id="group"
              type="text"
              className={`form-control custom-input-2 ${group === "Admin" && "disable"}`}
              placeholder="Group Name"
              name="groupName"
              value={groupName}
              autoComplete="off"
              onChange={setGpName}
              disabled={group === "Admin"}
              style={{ width: "250px" }}
            />
          </div>
          {groupName === "Admin" && (
            <div>
              <p className="fw-bold">(The name and permissions of this group cannot be changed.)</p>
            </div>
          )}
        </div>
        {error && (
          <div className="error-red-band" style={{ width: "50%", marginTop: "15px" }}>
            <div>
              <ReportGmailerrorredOutlinedIcon />
              &nbsp;{error}
            </div>
          </div>
        )}
        <div className="mt-5">
          <p className="fw-bold">Permissions</p>
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateDashboard}
              onChange={(e) => onCheckAllChange(e, "dashboard")}
              checked={checkAllDashboard}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <p className="fw-bold">DASHBOARD</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.dashboard}
              onChange={(val) => onChange("dashboard", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {dashboard.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox value={val.value}>{val.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateMeasure}
              onChange={(e) => onCheckAllChange(e, "measure")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllMeasure}
            >
              <p className="fw-bold">MEASURE</p>
            </Checkbox>
            {/* <Checkbox.Group options={measure} value={checkedList.measure} /> */}
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.measure}
              onChange={(val) => onChange("measure", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {measure.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox
                      disabled={
                        (val.value === "viewConsumptionData" && checkedList.measure.includes("editConsumptionData")) ||
                        (val.value === "viewData" && checkedList.measure.includes("editData")) ||
                        (val.value === "viewAirData" && checkedList.measure.includes("editAirData")) ||
                        (val.value === "viewWaterData" && checkedList.measure.includes("editWaterData")) ||
                        (val.value === "viewWasteData" && checkedList.measure.includes("editWasteData")) ||
                        (val.value === "viewSocialData" && checkedList.measure.includes("editSocialData")) ||
                        (val.value === "viewEnergyData" && checkedList.measure.includes("editEnergyData")) ||
                        (val.value === "viewGovtData" && checkedList.measure.includes("editGovtData")) ||
                        (val.value === "viewscope1" && checkedList.measure.includes("editscope1")) ||
                        (val.value === "viewscope2" && checkedList.measure.includes("editscope2")) ||
                        (val.value === "viewscope3" && checkedList.measure.includes("editscope3")) ||
                        (val.value === "viewdataquality" && checkedList.measure.includes("editdataquality"))
                      }
                      value={val.value}
                    >
                      {val.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateAnalysis}
              onChange={(e) => onCheckAllChange(e, "analysis")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllAnalysis}
            >
              <p className="fw-bold">ANALYSIS</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.analysis}
              onChange={(val) => onChange("analysis", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {analysis.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox value={val.value}>{val.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateAct}
              onChange={(e) => onCheckAllChange(e, "act")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllAct}
            >
              <p className="fw-bold">ACT</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.act}
              onChange={(val) => onChange("act", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {act.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox
                      disabled={
                        (val.value === "viewSimulation" && checkedList.act.includes("editSimulation")) ||
                        (val.value === "viewTarget" && checkedList.act.includes("editTarget")) ||
                        (val.value === "viewActionPlan" && checkedList.act.includes("editActionPlan")) ||
                        (val.value === "viewReductionAction" && checkedList.act.includes("editReductionAction"))
                      }
                      value={val.value}
                    >
                      {val.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateReduce}
              onChange={(e) => onCheckAllChange(e, "reduce")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllReduce}
            >
              <p className="fw-bold">REDUCE</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.reduce}
              onChange={(val) => onChange("reduce", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {reduce.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox
                      disabled={
                        (val.value === "viewKlean" && checkedList.reduce.includes("editKlean")) ||
                        (val.value === "viewOffset" && checkedList.reduce.includes("editOffset"))
                      }
                      value={val.value}
                    >
                      {val.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateReport}
              onChange={(e) => onCheckAllChange(e, "reports")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllReport}
            >
              <p className="fw-bold">SHOW REPORTS</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.reports}
              onChange={(val) => onChange("reports", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {reports.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox value={val.value}>{val.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateAccessReport}
              onChange={(e) => onCheckAllChange(e, "accessReports")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllAccessReport}
            >
              <p className="fw-bold">REPORTS</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.accessReports}
              onChange={(val) => onChange("accessReports", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {accessReports.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox
                      disabled={val.value === "viewReport" && checkedList.accessReports.includes("editReport")}
                      value={val.value}
                    >
                      {val.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateCbam}
              onChange={(e) => onCheckAllChange(e, "cbam")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllCbam}
            >
              <p className="fw-bold">CBAM</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.cbam}
              onChange={(val) => onChange("cbam", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {cbam.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox
                      disabled={
                        (val.value === "viewProducts" && checkedList.cbam.includes("editProducts")) ||
                        (val.value === "viewInvoice" && checkedList.cbam.includes("editInvoice")) ||
                        (val.value === "viewImporters" && checkedList.cbam.includes("editImporters"))
                      }
                      value={val.value}
                    >
                      {val.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateAdminPermissions}
              onChange={(e) => onCheckAllChange(e, "adminPermissions")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllAdminPermissions}
            >
              <p className="fw-bold">ADMIN PERMISSIONS</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.adminPermissions}
              onChange={(val) => onChange("adminPermissions", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {adminPermissions.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox
                      disabled={
                        (val.value === "viewOrganization" &&
                          checkedList.adminPermissions.includes("editOrganization")) ||
                        (val.value === "viewFacility" && checkedList.adminPermissions.includes("editFacility")) ||
                        (val.value === "viewUser" && checkedList.adminPermissions.includes("editUser")) ||
                        (val.value === "viewPermissions" && checkedList.adminPermissions.includes("editPermissions"))
                      }
                      value={val.value}
                    >
                      {val.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateBrsrReport}
              onChange={(e) => onCheckAllChange(e, "brsrReport")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllBrsrReport}
            >
              <p className="fw-bold">BRSR REPORT</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.brsrReport}
              onChange={(val) => onChange("brsrReport", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {brsrReport.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox value={val.value}>{val.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateSections}
              onChange={(e) => onCheckAllChange(e, "sections")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllSections}
            >
              <p className="fw-bold">BRSR SECTIONS</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.sections}
              onChange={(val) => onChange("sections", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {sections.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox value={val.value}>{val.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Divider />
          <div className="mt-4">
            <Checkbox
              style={{ marginBottom: "8px" }}
              indeterminate={indeterminateSectionC}
              onChange={(e) => onCheckAllChange(e, "sectionC")}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
              checked={checkAllSectionC}
            >
              <p className="fw-bold">BRSR SECTION C</p>
            </Checkbox>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedList.sectionC}
              onChange={(val) => onChange("sectionC", val)}
              disabled={!user?.userGroup?.permissions?.adminPermissions?.editPermissions || group === "Admin"}
            >
              <Row gutter={[8, 8]}>
                {sectionC.map((val, ind) => (
                  <Col span={8} key={ind}>
                    <Checkbox value={val.value}>{val.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checklist;
