import React from "react";
import Accordion from "../../../../../components/Accordion/Accordion";
import { useDispatch, useSelector } from "react-redux";
import "../../Social.scss";
import { setPageWiseData } from "../../../../../state/SocialSlice";

const NonDiscrimination = ({formEditable, editMode}) => {
  const { pageWiseData } = useSelector((state) => state.social);

  const dispatch = useDispatch();

  let socialAllData = JSON.parse(JSON.stringify(pageWiseData));

  const handleTotalNumberOfIncidentsChange=(e)=>{
    socialAllData.discrimination.nonDiscrimination.totalNumberOfIncidents= e.target.value;
    dispatch(setPageWiseData(socialAllData))
  }

  const handleStatusOfTheIncidentsChange =(e, index)=>{
    socialAllData.discrimination.nonDiscrimination.statusOfTheIncidents[index][e.target.name]= e.target.value;
    dispatch(setPageWiseData(socialAllData))
  }
const addNewRstatusOfTheIncidents =()=>{
   let newROw= {
    sNo: "",
    discriminatoryIncident: "",
    remediationPlans: "",
    managementProcesses: "",
    incidentsNoLonger: ""
  }
  socialAllData.discrimination.nonDiscrimination.statusOfTheIncidents.push(newROw)
    dispatch(setPageWiseData(socialAllData))
}


// const deleteRow = (rowIndex, name) => {
//   if (rowIndex > 0) {
//     let tempArray = [...socialAllData.nonDiscrimination[name]];
//     tempArray.splice(rowIndex, 1);
//     socialAllData.nonDiscrimination[name] = tempArray;
//     dispatch(setPageWiseData(socialAllData));
//   }
// };
const deleteRow = (parentKey, arrayKey, rowIndex) => {
  const parentObject = socialAllData.discrimination?.[parentKey];
  const dataArray = parentObject?.[arrayKey];
  if (Array.isArray(dataArray)) {
    if (rowIndex >= 0 && rowIndex < dataArray.length) {
      const updatedArray = dataArray.filter((_, index) => index !== rowIndex);

      const updatedParentObject = {
        ...parentObject,
        [arrayKey]: updatedArray
      };

      const updatedData = {
        ...socialAllData,
        discrimination: {
          ...socialAllData.discrimination,
          [parentKey]: updatedParentObject
        }
      };

      dispatch(setPageWiseData(updatedData));
    }
  }
};



  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Incidents of discrimination and corrective actions taken"} index={0}>
            
          <div className="foreword-container mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                Total number of incidents of discrimination during the reporting period
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData?.discrimination?.nonDiscrimination?.totalNumberOfIncidents}
                  name="totalNumberOfIncidents"
                  onChange={(e)=>handleTotalNumberOfIncidentsChange(e)}
                  className="no-style-input w-100"
                  disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>

          <div>
            <p className="form-bullet-points">Status of the incidents and actions taken</p>
            <table className="form-table mb-4">
              <thead>
                <tr>
                  <th scope="col">SI.No.</th>
                  <th scope="col">Nature of discriminatory incident reviewed by Organization</th>
                  <th scope="col">Remediation plans being implemented</th>
                  <th scope="col">
                    Remediation plans that have been implemented, with results reviewed through routine internal
                    management review processes
                  </th>
                  <th scope="col">Incidents no longer subject to action</th>
                  <th className="col-md-1">Action</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.discrimination?.nonDiscrimination?.statusOfTheIncidents?.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <input
                        type="text"
                        name="sNo"
                        value={item.sNo}
                        onChange={(e) => handleStatusOfTheIncidentsChange(e, index)}
                        className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1 w-auto":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="discriminatoryIncident"
                        value={item.discriminatoryIncident}
                        onChange={(e) => handleStatusOfTheIncidentsChange(e, index)}
                      className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="remediationPlans"
                        value={item.remediationPlans}
                        onChange={(e) => handleStatusOfTheIncidentsChange(e, index)}
                      className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="managementProcesses"
                        value={item.managementProcesses}
                        onChange={(e) => handleStatusOfTheIncidentsChange(e, index)}
                      className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="incidentsNoLonger"
                        value={item.incidentsNoLonger}
                        onChange={(e) => handleStatusOfTheIncidentsChange(e, index)}
                      className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td className="small-width-columns">
                        {index !==0 && (
                          <img
                            src="/images/icons/delete-icon.svg"
                            alt=""
                            onClick={() => deleteRow("nonDiscrimination", "statusOfTheIncidents", index)}
                            className="cursor-pointer"
                          />
                        )}
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-outline-success add-new-row mb-4" onClick={() => addNewRstatusOfTheIncidents()}>
            <p>+ Add New Indicator</p>
          </button>
        </Accordion>
      </div>
    </div>
  );
};

export default NonDiscrimination;
