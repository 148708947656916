import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../../../../components/Accordion/Accordion";
import "../../../../Reports/BRSR/Brsr.scss";
import "../../../Social/Social.scss";
import { setFormWiseData } from "../../../../../state/govtSlice";
import "../../Governance.scss";

const ConflictInterest = ({formEditable, editMode}) => {
  const dispatch = useDispatch();
  const { formPageWiseData } = useSelector((state) => state.governance);

  let govtAllData = JSON.parse(JSON.stringify(formPageWiseData));

  const handleDetailsAboutConflictChange = (e, index) => {
    govtAllData.conflictInterest.conflictOfInterest.detailsAboutConflict[index][e.target.name] = e.target.value;
    dispatch(setFormWiseData(govtAllData));
  };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Details about conflict of interest"} index={0}>
          {govtAllData?.conflictInterest?.conflictOfInterest?.detailsAboutConflict?.map((item, index) => (
            <div className="foreword-container mb-4 mt-1" key={item+index}>
              <div className="form-textfield-container px-4 pb-4">
                <p className="form-bullet-points">{item.heading}</p>
                {item.firstSubHeading && item.secondSubHeading && item.thirdSubHeading && item.fourthSubHeading? (
                  <ol className="d-flex flex-column gap-3">
                    <li>{item.firstSubHeading}</li>
                    <li>{item.secondSubHeading}</li>
                    <li>{item.thirdSubHeading}</li>
                    <li>{item.fourthSubHeading}</li>
                  </ol>
                ) : (
                  ""
                )}

                <div className="custom-textfield ">
                  <textarea
                    rows="1"
                    placeholder="Write your foreword here."
                    value={item.forewordValue}
                    name="forewordValue"
                    onChange={(e) => handleDetailsAboutConflictChange(e, index)}
                    className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                  />
                </div>
              </div>
            </div>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default ConflictInterest;
