import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../../../../components/Accordion/Accordion";
import "../../../../Reports/BRSR/Brsr.scss";
import "../../../Social/Social.scss";
import { setFormWiseData } from "../../../../../state/govtSlice";
import "../../Governance.scss";
import { Select } from "antd";
import { numberRegex } from "../../../../../helper/Rejex";

const AntiCompetitive = ({ formEditable, editMode }) => {
  const dispatch = useDispatch();
  const { formPageWiseData } = useSelector((state) => state.governance);

  let govtAllData = JSON.parse(JSON.stringify(formPageWiseData));

  const handleDeatilsAboutAntiCompetitiveChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "pendingCompleted") {
      govtAllData.organisation.antiCompetitive.deatilsAboutAntiCompetitive[index][name] = value;
    } else if (name === "outcome") {
      govtAllData.organisation.antiCompetitive.deatilsAboutAntiCompetitive[index][name] = value;
    } else if (numberRegex.test(e.target.value) || e.target.value === "") {
      govtAllData.organisation.antiCompetitive.deatilsAboutAntiCompetitive[index][name] = value;
    }

    dispatch(setFormWiseData(govtAllData));
  };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Details about the Anti-Competitive cases in the Organisation"} index={0}>
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">
              Number of legal actions pending or completed during the reporting period regarding anti-competitive
              behavior and violations of anti-trust and monopoly legislation in which the organization has been
              identified as a participant{" "}
            </p>
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Number of cases</th>
                  <th scope="col">Pending/Completed</th>
                  <th scope="col">Outcome of completed legal Action</th>
                </tr>
              </thead>
              <tbody>
                {govtAllData?.organisation?.antiCompetitive?.deatilsAboutAntiCompetitive?.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.heading}</td>
                    <td>
                      <input
                        type="text"
                        name="numberOfCases"
                        value={item.numberOfCases}
                        onChange={(e) => handleDeatilsAboutAntiCompetitiveChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <Select
                        className="w-100"
                        value={item.pendingCompleted ? item.pendingCompleted : "Pending"}
                        onChange={(value) =>
                          handleDeatilsAboutAntiCompetitiveChange(
                            { target: { value, name: "pendingCompleted" } },
                            index
                          )
                        }
                        disabled={editMode && !formEditable}
                      >
                        {["Pending", "Completed"].map((item, ind) => (
                          <Select.Option key={ind} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="outcome"
                        value={item.outcome}
                        onChange={(e) => handleDeatilsAboutAntiCompetitiveChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default AntiCompetitive;
