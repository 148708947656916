import React from "react";

import { TextareaAutosize as BaseTextareaAutosize, TextField, Typography } from "@mui/material";
import { Box } from "@mui/material";

import CustomModal from "../Modal/Modal";



function DeleteModal({ name, openModal, setModalOpen, handleFileDelete, currentItem, heading, itemName }) {
  return (
    <CustomModal modalOpen={openModal} setModalOpen={setModalOpen} outSideClose={true}>
      <Box sx={{ width: 500 }}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ marginTop: ".5rem" }}>
            {heading}
          </Typography>
          {
            name && name === "facility" ?
              <div style={{ fontSize: 18, fontWeight:"500" ,  marginTop: ".5rem" }}>
               <p >If you delete this facility, all data will be lost. Are you sure you want to delete {itemName}</p>
              </div> :
              <div style={{ fontSize: 14, color: "#9C9C9C", marginTop: ".5rem" }}>
                {itemName} will be deleted
              </div>
          }
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem", marginTop: "1rem" }}>
          <button className="btn btn-outline-success"  onClick={() => setModalOpen(false)}>
            Cancel
          </button>
          <button className="btn btn-danger"  onClick={handleFileDelete}>
            Delete
          </button>
        </div>
      </Box>
    </CustomModal>
  );
}

export default DeleteModal;
