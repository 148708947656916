export const userEndpoints = (build) => ({
  postSendEmail: build.mutation({
    query: (postBody) => ({
      url: `/users/invite`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: []
  }),
  getUsersAll: build.query({
    query: (arg) => ({
      url: `/users`,
      params: { ...arg }
    }),
    providesTags: ["Userlist"]
  }),
  getUserDetailById: build.query({
    query: ({id}) => ({
      url: `/users/details/${id}`,
    }),
    providesTags: ["Userdetail"]
  }),
  getUserGroup: build.query({
    query: () => ({
      url: `/user-group`,
    }),
    providesTags: ["Usergroup"]
  }),
  patchUserList: build.mutation({
    query: ({id, postBody}) => ({
      url: `/users/${id}`,
      method: "PATCH",
      body: postBody
    }),
    invalidatesTags: ["Userlist", "Userdetail", "Usergroup"]
  }),

  usePostCreateUser: build.mutation({
    query: ({id, postBody}) => ({
      url: `/users/create-profile/${id}`,
      method: "POST",
      body: postBody
    }),
    invalidatesTags: ["Userlist"]
  }),
  
  deleteUserList: build.mutation({
    query: (id) => ({
      url: `/users/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: ["Userlist"]
  }),
  deleteUserGroup: build.mutation({
    query: (id) => ({
      url: `/user-group/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: ["Usergroup"]
  }),
  patchUserGroup: build.mutation({
    query: ({id, postBody}) => ({
      url: `/user-group/${id}`,
      method: "PATCH",
      body: postBody
    }),
    invalidatesTags: ["Usergroupdetail", "Usergroup", "Userdetail", "Userlist"]
  }),
  postUserGroup: build.mutation({
    query: ({postBody}) => ({
      url: `/user-group`,
      method: "POST",
      body: postBody
    }),
    invalidatesTags: ["Usergroupdetail", "Usergroup"]
  }),
  getUserGroupDetail: build.query({
    query: ({id}) => ({
      url: `/user-group/details/${id}`,
    }),
    providesTags: ["Usergroupdetail"]
  }),
  getCountryCode: build.query({
    query: () => ({
      url: `users/country-codes`,
    }),
    providesTags: ["Countrycode"]
  }),

  getUserDepartment: build.query({
    query: () => ({
      url: `users/getDepartment`,
    }),
    providesTags: ["Department"]
  }),

  
});
