export const auditLogEndpoints = (build) => ({
    
    getAuditLogData:build.query({
        query:(arg)=>({
         url: `audit`,
         params: { ...arg }
        }),
        providesTags: ["Cards"]
    }),


    
    postAuditLogData: build.mutation({
        query: (postBody) => ({
          url: `audit`,
          method: "POST",
          body: postBody
        }),
    
        transformResponse: (response) => {
          return response;
        },
    
        invalidatesTags: ["Carddata"]
      }),

      getAuditLogFilterData:build.query({
        query:(arg)=>({
         url: `audit/filter`,
         params: { ...arg }
        }),
        providesTags: ["Cards"]
    }),
})