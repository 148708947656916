export const apiUrl = process.env.REACT_APP_API_URL;

export const requestOptionsGet = {
  method: "GET", // Replace with your desired HTTP method (GET, POST, etc.)
  credentials: "include",
  headers: {
    "Content-Type": "application/json"
  }
};

export const requestOptionsPost = {
  method: "POST", // Replace with your desired HTTP method (GET, POST, etc.)
  credentials: "include",
  headers: {
    "Content-Type": "application/json"
  }
};

export const requestOptionsPut = {
  method: "PUT", // Replace with your desired HTTP method (GET, POST, etc.)
  credentials: "include",
  headers: {
    "Content-Type": "application/json"
  }
};

export const requestOptionsDelete = {
  method: "DELETE",
  credentials: "include",
  headers: {
    "Content-Type": "application/json"
  }
};
