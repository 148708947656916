import React from "react";
import "./Breakdown.scss";
import { EnvBreakDown } from "./EnvBreakDown/EnvBreakDown";
import { Breadcrumbs, Link } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation } from "react-router-dom";
import { EsgBreakDown } from "./EsgBreakDown/EsgBreakDown";

const BreakDown = () => {
  const location = useLocation();

  const data  = location.state?.data;
  const tab  = location.state?.tab;

  // console.log("data comming", data , tab);

  const breadcrumbs = [
    <div className="d-flex gap-2 align-items-center">
      <div style={{ width: "15px" }} className="d-flex">
        <ArrowBackIosIcon fontSize="small" className="w-100" />
      </div>
      <Link className="fs-6" underline="hover" key="1" color="inherit" href="/dataquality">
        {tab === "1" ? data.subCategory.name :data.kpi}
      </Link>
    </div>
  ];

  return (
    <>
      <div key={"break"}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" key={"bread"}>
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <div className="breakdown-wrapper" key={"down"}>
       {tab === "1" ?(<EnvBreakDown data={data}/>):(<EsgBreakDown data={data} tabValue={tab}/>)} 
      </div>
    </>
  );
};

export default BreakDown;
