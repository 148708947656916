import React, { useEffect, useRef, useState } from "react";
import "./EsgBreakDown.scss";
// import "./EnvBreakDown.scss";
import { Breadcrumbs, Link, Pagination, Stack, Typography } from "@mui/material";
import { Button, Progress } from "antd";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { capitalizeFirstLetter } from "../../../../../helper/Utils/utilitiyFunctions";
import CustomModal from "../../../../../components/Modal/Modal";
import Lottie from "react-lottie";
import * as animationData from "../../../../../helper/tick-animation.json";
import { useLazyGetEsgBreakdownQuery } from "../../../../../state/api";
import WidgetLoader from "../../../../../components/Loaders/WidgetLoader";

const allYears = ["2020", "2022", "2023", "2024", "2025"];

export const EsgBreakDown = ({ data, tabValue }) => {
  const [openModal, setopenModal] = useState(false);

  const postPerPage = 10;
  const [currentPage, SetCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [openCongratsModal, setopenCongratsModal] = useState(false);

  const [getEsgBreakdown, resultEsgBreakdown] = useLazyGetEsgBreakdownQuery();

  const timerRef = useRef(null);

  useEffect(() => {
    if (data.facility._id) {
      getEsgBreakdown({ id: data?.id });
    }
    // console.log("data", data.id);
  }, [data]);

  // console.log("esgbreak down data", resultEsgBreakdown);

  const openModalHandle = () => {
    setopenModal(true);
  };

  const breadcrumbs = [
    <Link className="fs-6" underline="none" key="1" color="#2E2E3A" sx={{ textDecoration: "none" }}>
      {data.facility.name}
    </Link>,

    <Typography className="fs-6" key="3" color="#2E2E3A">
      {data.kpi}
    </Typography>
  ];

  const handleSave = () => {
    setopenModal(false);
    closeModalWithTimeout();
  };

  const closeModalWithTimeout = () => {
    setopenCongratsModal(true);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    } else {
      timerRef.current = setTimeout(() => {
        setopenCongratsModal(false);
      }, 5000);
    }
  };

  const handleCongratsModal = () => {
    setopenCongratsModal(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
      setopenCongratsModal(false);
    }
  };

  const getPayLoad = (pageNo) => {
    let body = {
      from: `${parseInt(pageNo) - 1 < 0 ? 0 : parseInt(pageNo) - 1}`,
      size: postPerPage
    };

    return body;
  };

  const handlePageChange = async (e, value) => {
    if (value - 1 < 0) {
      SetCurrentPage(0);
    } else {
      SetCurrentPage((value - 1) * postPerPage);
    }
    try {
      if (value - 1 < 0) {
        // await getDataQuality(getPayLoad(0), true);
      } else {
        // await getDataQuality(getPayLoad((value - 1) * postPerPage), true);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const getStrokeColor = (percentage) => {
    if (percentage === 100) {
      return "#4CAF50";
    } else if (percentage > 0 && percentage < 100) {
      return "#FBC02D";
    } else {
      return "#F44336";
    }
  };

  return (
    <>
      {/* <CustomModal modalOpen={openModal} setModalOpen={setopenModal} outSideClose={false}>
        <div className="d-flex gap-4 flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <p className="text-dark fs-6 fw-medium lh-1 d-inline">Edit Remarks</p>
            <img
              src="/images/icons/modal-close-icon.svg"
              alt="icon"
              className="cursor-pointer"
              sizes="40px"
              onClick={() => setopenModal(false)}
            />
          </div>
          <textarea name="remark" placeholder="Placeholder" rows={8} className="remark-textArea"></textarea>
          <div className="d-flex justify-content-between  gap-4">
            <button type="button" className="btn btn-outline-success ms-1" onClick={() => setopenModal(false)}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={handleSave}>
              {"Save"}
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal modalOpen={openCongratsModal} setModalOpen={setopenCongratsModal}>
        <div
          style={{
            width: "100%"
          }}
        >
          <div className="d-flex justify-content-end">
            <img
              src="/images/icons/cross-icon.svg"
              alt=""
              className="cursor-pointer"
              onClick={handleCongratsModal}
              style={{ width: "48px", height: "48px" }}
            />
          </div>
          <div className="px-5 py-3">
            <Lottie options={{ loop: false, autoplay: true, animationData }} height={222} width={265} />
            <div className="d-flex flex-column gap-1 justify-content-center align-items-center mt-4">
              <p className="text-dark fs-4 fw-medium">Your remark is successfully saved</p>
            </div>
          </div>
        </div>
      </CustomModal> */}

      <div className="heading-cont">
        <p> {tabValue === "2" ? "Social" : "Governance"} Breakdown</p>
      </div>

      {resultEsgBreakdown.isLoading ? (
        <div className="empty-wrapper">
          <WidgetLoader />
        </div>
      ) : (
        resultEsgBreakdown?.data && (
          <div className="esg-breakdown-wrapper">
            <div className="bread-crumbs-wrapper">
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </div>
            <div className="esg-beakdown-table">
              <table>
                <thead>
                  <tr>
                    <th>Activity 1</th>
                    <th className="text-nowrap">Data Completeness</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(resultEsgBreakdown?.data?.data).map((key, inde) => (
                    <tr key={key + inde}>
                      {resultEsgBreakdown?.data?.data[key].accordionTitle ? (
                        <td>{resultEsgBreakdown?.data?.data[key].accordionTitle}</td>
                      ) : (
                        ""
                      )}
                      {resultEsgBreakdown?.data?.data[key].accordionTitle ? (
                        <td>
                          <Progress
                            type="circle"
                            percent={
                              resultEsgBreakdown?.data?.data[key].completion === 0
                                ? "100"
                                : resultEsgBreakdown?.data?.data[key].completion
                            }
                            size={50}
                            strokeColor={getStrokeColor(resultEsgBreakdown?.data?.data[key].completion)}
                            format={() =>
                              resultEsgBreakdown?.data?.data[key].completion === 100
                                ? `${resultEsgBreakdown?.data?.data[key].completion}%`
                                : `${resultEsgBreakdown?.data?.data[key].completion}%`
                            }
                            status={resultEsgBreakdown?.data?.data[key].completion === 0 ? "exception" : ""}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}

      {/* <div className="mt-auto py-4">
        <Stack spacing={2}>
          <Pagination count={Math.ceil(totalCount / postPerPage)} shape="rounded" onChange={handlePageChange} />
        </Stack>
      </div> */}
    </>
  );
};
