import React from 'react'
import './Loader.scss'

const WidgetLoader = () => {
    return (
        <div>
            <div className="loader-15"></div>
        </div>
    )
}

export default WidgetLoader