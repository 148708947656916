export const actEndpoints = (build) => ({
  createReductionTarget: build.mutation({
    query: (postBody) => ({
      url: "act/target",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: [],
    // invalidatesTags: ["Reductiontarget", "Targetgraph"],

    transformResponse: (response) => {
      return response;
    }
  }),

  updateReductionTarget: build.mutation({
    query: (postBody) => ({
      url: "act/target",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: [],
    // invalidatesTags: ["Reductiontarget", "Targetgraph"],

    transformResponse: (response) => {
      return response;
    }
  }),

  deleteReductionTarget: build.mutation({
    query: (id) => ({
      url: `act/target/${id}`,
      method: "DELETE"
    }),

    invalidatesTags: [],
    // invalidatesTags: ["Reductiontarget", "Targetgraph"],

    transformResponse: (response) => {
      return response;
    }
  }),

  getReductionTarget: build.query({
    query: (params) => ({
      url: `act/target`,
      params
    }),
    providesTags: []
    // providesTags: ["Reductiontarget"]
  }),

  postTargetByScope: build.mutation({
    query: (postBody) => ({
      url: "act/target/scope",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: [],

    transformResponse: (response) => {
      return response;
    }
  }),

  getReductionTargetGraph: build.query({
    query: (params) => ({
      url: `act/target/graph`,
      params
    }),
    providesTags: []
    // providesTags: ["Targetgraph"]
  }),

  getReductionTargetZeroState: build.query({
    query: () => ({
      url: `act/target/zero-state`
    }),
    providesTags: [""]
  }),

  getReductionReportingYear: build.query({
    query: (params) => ({
      url: `act/target/filter/reporting-year`,
      params
    }),
    providesTags: [""]
  }),

  checkReductionTargetExists: build.mutation({
    query: (postBody) => ({
      url: "act/target/check-exists",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: [],

    transformResponse: (response) => {
      return response;
    }
  }),
  getSimulations: build.query({
    query: (params) => ({
      url: `act/simulation`,
      params
    }),
    providesTags: ["Simulations"]
  }),
  getSimulationsYearFilter: build.query({
    query: (params) => ({
      url: `act/simulation/filter/reporting-year`,
      params
    }),
    providesTags: ["SimulationYear"]
  }),
  createSimulations: build.mutation({
    query: (postBody) => ({
      url: "act/simulation",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: ["Simulations"],

    transformResponse: (response) => {
      return response;
    }
  }),
  updateSimulations: build.mutation({
    query: (postBody) => ({
      url: "act/simulation",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: ["Simulations", "Simulation"],

    transformResponse: (response) => {
      return response;
    }
  }),
  deleteSimulations: build.mutation({
    query: (id) => ({
      url: `act/simulation/${id}`,
      method: "DELETE"
    }),

    invalidatesTags: ["Simulations", "scopetarget"],

    transformResponse: (response) => {
      return response;
    }
  }),
  getSimulationSingle: build.query({
    query: (params) => ({
      url: `act/simulation/single`,
      params
    }),

    providesTags: ["Simulation"],

    transformResponse: (response) => {
      return response;
    }
  }),
  getMeasures: build.query({
    query: (params) => ({
      url: `act/measure`,
      params
    }),
    providesTags: ["Measures"]
  }),
  deleteMeasures: build.mutation({
    query: (id) => ({
      url: `act/measure/${id}`,
      method: "DELETE"
    }),

    invalidatesTags: ["Simulations", "Measures", "Simulation", "scopetarget"],

    transformResponse: (response) => {
      return response;
    }
  }),
  getMeasureScopeData: build.query({
    query: (params) => ({
      url: `act/measure/scope`,
      params
    }),
    providesTags: ["MeasureScopes"]
  }),
  createMeasure: build.mutation({
    query: (postBody) => ({
      url: "act/measure",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: ["Measures"],

    transformResponse: (response) => {
      return response;
    }
  }),
  updateMeasure: build.mutation({
    query: (postBody) => ({
      url: "act/measure",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: ["Measures"],

    transformResponse: (response) => {
      return response;
    }
  }),
  getMeasureDetail: build.query({
    query: (params) => ({
      url: `act/measuredetail`,
      params
    }),
    providesTags: ["MeasureDetails"]
  }),
  createMeasureDetail: build.mutation({
    query: (postBody) => ({
      url: "act/measuredetail",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: ["MeasureDetails"],

    transformResponse: (response) => {
      return response;
    }
  }),
  deleteMeasureDetail: build.mutation({
    query: (id) => ({
      url: `act/measuredetail/${id}`,
      method: "DELETE"
    }),

    invalidatesTags: ["MeasureDetails", "Measures", "Simulations", "MeasureScopes", "Simulation"],

    transformResponse: (response) => {
      return response;
    }
  }),
  createProjection: build.mutation({
    query: (postBody) => ({
      url: "act/projection",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: [
      "Projections",
      "MeasureDetails",
      "Measures",
      "Simulations",
      "MeasureScopes",
      "Simulation",
      "scopetarget"
    ],

    transformResponse: (response) => {
      return response;
    }
  }),
  updateProjection: build.mutation({
    query: (postBody) => ({
      url: "act/projection",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: [
      "Projections",
      "MeasureDetails",
      "Measures",
      "Simulations",
      "MeasureScopes",
      "Simulation",
      "scopetarget"
    ],

    transformResponse: (response) => {
      return response;
    }
  }),
  getProjection: build.query({
    query: (params) => ({
      url: `act/projection`,
      params
    }),
    providesTags: ["Projections"]
  }),
  deleteProjection: build.mutation({
    query: (id) => ({
      url: `act/projection/${id}`,
      method: "DELETE"
    }),

    invalidatesTags: [
      "Projections",
      "MeasureDetails",
      "Measures",
      "Simulations",
      "MeasureScopes",
      "Simulation",
      "scopetarget"
    ],

    transformResponse: (response) => {
      return response;
    }
  }),
  createActionPlan: build.mutation({
    query: (postBody) => ({
      url: "act/actionplan",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: ["Actionplan"],

    transformResponse: (response) => {
      return response;
    }
  }),
  getActionPlan: build.query({
    query: (params) => ({
      url: `act/actionplan`,
      params
    }),
    providesTags: ["Actionplan"]
  }),
  updateActionPlan: build.mutation({
    query: (postBody) => ({
      url: "act/actionplan",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: ["Actionplan"],

    transformResponse: (response) => {
      return response;
    }
  }),
  deleteActionPlan: build.mutation({
    query: (id) => ({
      url: `act/actionplan/${id}`,
      method: "DELETE"
    }),

    invalidatesTags: ["Actionplan"],

    transformResponse: (response) => {
      return response;
    }
  }),
  createActionMeasure: build.mutation({
    query: (postBody) => ({
      url: "act/actionmeasure",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: ["Actionmeasure", "Actionmeasuredetail"],

    transformResponse: (response) => {
      return response;
    }
  }),
  updateActionMeasure: build.mutation({
    query: (postBody) => ({
      url: "act/actionmeasure",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: ["Actionmeasure", "Actionmeasuredetail"],

    transformResponse: (response) => {
      return response;
    }
  }),
  deleteActionMeasure: build.mutation({
    query: (id) => ({
      url: `act/actionmeasure/${id}`,
      method: "DELETE"
    }),

    invalidatesTags: ["Actionmeasure", "Actionmeasuredetail"],

    transformResponse: (response) => {
      return response;
    }
  }),
  getActionMeasure: build.query({
    query: (params) => ({
      url: `act/actionmeasure`,
      params
    }),
    providesTags: ["Actionmeasure"]
  }),
  getActionMeasureDetail: build.query({
    query: (id) => ({
      url: `act/actionmeasure/${id}`
    }),

    providesTags: ["Actionmeasuredetail"]
  }),
  createActionTemplate: build.mutation({
    query: (postBody) => ({
      url: "act/template",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: ["Actiontemplate", "Actiontemplatefilter"],

    transformResponse: (response) => {
      return response;
    }
  }),
  updateActionTemplate: build.mutation({
    query: (postBody) => ({
      url: "act/template",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: ["Actiontemplate", "Actiontemplatefilter", "Actiontemplatesingle"],

    transformResponse: (response) => {
      return response;
    }
  }),
  likeActionTemplate: build.mutation({
    query: (postBody) => ({
      url: "act/template/like",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: ["Actiontemplate", "Actiontemplatesingle"],

    transformResponse: (response) => {
      return response;
    }
  }),
  getActionTemplate: build.query({
    query: (params) => ({
      url: `act/template`,
      params
    }),
    providesTags: ["Actiontemplate"]
  }),
  deleteActionTemplate: build.mutation({
    query: (id) => ({
      url: `act/template/${id}`,
      method: "DELETE"
    }),

    invalidatesTags: ["Actiontemplate", "Actiontemplatesingle"],

    transformResponse: (response) => {
      return response;
    }
  }),
  getActionTemplateFilter: build.query({
    query: (params) => ({
      url: `act/template/filter`,
      params
    }),
    providesTags: ["Actiontemplatefilter"]
  }),
  getSingleTemplate: build.mutation({
    query: (id) => ({
      url: `act/template/${id}`,
      method: "GET"
    }),

    invalidatesTags: ["Actiontemplatesingle"],

    transformResponse: (response) => {
      return response;
    }
  }),
  getActionAction: build.query({
    query: (params) => ({
      url: `act/actionaction`,
      params
    }),
    providesTags: ["Actionaction"]
  }),
  getAllActionAction: build.query({
    query: (params) => ({
      url: `act/actionaction/all`,
      params
    }),
    providesTags: ["Allactionaction"]
  }),
  createActionAction: build.mutation({
    query: (postBody) => ({
      url: "act/actionaction",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: ["Actionplan", "Actionmeasure", "Actionaction", "Allactionaction"],

    transformResponse: (response) => {
      return response;
    }
  }),
  updateActionAction: build.mutation({
    query: (postBody) => ({
      url: "act/actionaction",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: ["Actionplan", "Actionmeasure", "Actionaction"],

    transformResponse: (response) => {
      return response;
    }
  }),
  deleteActionAction: build.mutation({
    query: (id) => ({
      url: `act/actionaction/${id}`,
      method: "DELETE"
    }),

    invalidatesTags: ["Actionplan", "Actionmeasure", "Actionaction", "Allactionaction"],

    transformResponse: (response) => {
      return response;
    }
  }),
  createComment: build.mutation({
    query: (postBody) => ({
      url: "act/comment",
      method: "POST",
      body: postBody
    }),

    invalidatesTags: ["Comments"],

    transformResponse: (response) => {
      return response;
    }
  }),
  getComment: build.query({
    query: (params) => ({
      url: `act/comment`,
      params
    }),
    providesTags: ["Comments"]
  }),
  updateComment: build.mutation({
    query: (postBody) => ({
      url: "act/comment",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: ["Comments"],

    transformResponse: (response) => {
      return response;
    }
  }),
  deleteComment: build.mutation({
    query: (id) => ({
      url: `act/comment/${id}`,
      method: "DELETE"
    }),

    invalidatesTags: ["Comments"],

    transformResponse: (response) => {
      return response;
    }
  })
});
