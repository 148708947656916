import { Button, Divider, Input, Progress, Select } from "antd";
import React, { useState, useEffect } from "react";
import "./EnvDataQuality.scss";
import { Pagination } from "@mui/material";
import { Stack } from "@mui/system";
import CustomDrawer from "../../../../components/CustomeDrawer/CustomDrawer";
import {
  useLazyGetUserDetailByIdQuery,
  useGetOrganizationDataQuery,
  useLazyGetTemplateListQuery,
  useLazyGetEnvDataQualityQuery,
  useLazyGetDataQualityFilterDataQuery
} from "../../../../state/api";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import WidgetLoader from "../../../../components/Loaders/WidgetLoader";
import { capitalizeFirstLetter } from "../../../../helper/Utils/utilitiyFunctions";

const filterValueInScope = ["Scope 1", "Scope 2", "Scope 3", "Air Emission"];

const allMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const EnvDataQuality = ({
  drawerOpen,
  toggleDrawer,
  onSearchValue,
  triggerSearch,
  onSearchCompleted,
  tabValue
}) => {
  const navigate = useNavigate();
  const orgData = useGetOrganizationDataQuery();
  const [getTemplate, resultTemplate] = useLazyGetTemplateListQuery();
  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  const [yearData, setYearData] = useState();
  const [yearSelected, setYearSelected] = useState({ financialYear: true, calenderYear: false });
  const [activeFilters, setActiveFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setcategoryList] = useState();
  const [prevcategoryList, setprevcategoryList] = useState();
  const [subcategoryList, setSubcategoryList] = useState();
  const [initialYear, setInitialYear] = useState();
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [getDataQuality, resultDataQuality] = useLazyGetEnvDataQualityQuery();
  const [getDataQualityFilters, resultDataQualityFilters] = useLazyGetDataQualityFilterDataQuery();

  const postPerPage = 10;
  const [currentPage, SetCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const country = orgData.data?.data.country.name;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const previousYears = [];

  for (let i = 0; i < 10; i++) {
    previousYears.push(currentYear - i);
  }
  const baseYear = orgData.data?.data.baseYear;

  let financialYear = [];
  let calendarYear = [];

  let startYear = dayjs().month() < 3 ? currentYear - 1 : currentYear;

  for (let year = baseYear; year <= currentYear; year++) {
    calendarYear.push(year);
  }

  for (let year = startYear; year >= baseYear; year--) {
    let nextYear = year + 1;
    financialYear.push(`${year}-${nextYear}`);
  }
  calendarYear.reverse();

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    if (country) {
      if (country === "India") {
        setYearData("2024-2025");
        setInitialYear("2024-2025");
      } else {
        setYearData(dayjs().year());
        setInitialYear(dayjs().year());
      }
    }
  }, [country]);

  useEffect(() => {
    if (triggerSearch) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const body = {
            year: yearData,
            from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
            size: postPerPage,
            ...(onSearchValue && { search: onSearchValue })
          };
          await getDataQuality(body, true);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
        onSearchCompleted();
      };

      fetchData();
    }
  }, [onSearchValue, triggerSearch, onSearchCompleted]);

  useEffect(() => {
    if (initialYear) {
      getDataQuality(
        {
          year: yearData,
          from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
          size: postPerPage
        },
        true
      );
    }
  }, [initialYear]);

  useEffect(() => {
    if (resultDataQuality?.data?.data) {
      setTotalCount(resultDataQuality?.data?.data?.totalData);
    }
  }, [currentPage, resultDataQuality?.data?.data]);

  const handleChange = async (e, newValue) => {
    const { name, value } = e.target;
    let updatedFilters = { ...activeFilters };

    switch (e.target.name) {
      case "fyYear":
        updatedFilters[name] = value;
        setYearData(value);
        break;

      case "facility":
        updatedFilters[name] = value;
        break;

      case "category":
        updatedFilters[name] = value;

        let result = await getDataQualityFilters({ category: value }, true);

        setSubcategoryList(result?.data?.data);
        break;
      case "subcategory":
        updatedFilters[name] = value;
        break;
      case "scope":
        updatedFilters[name] = value;
        if (!value) {
          updatedFilters["category"] = "";
        }

        let categoryresult = await getDataQualityFilters({ scope: value }, true);
        setcategoryList(categoryresult?.data?.data);
        break;
      case "year":
        updatedFilters[name] = value;
        setYearData(value);

        break;

      default:
        break;
    }
    setActiveFilters(updatedFilters);
  };

  const handleReset = async () => {
    toggleDrawer();
    setIsLoading(true);
    try {
      await getDataQuality(
        {
          year: yearData,
          from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
          size: postPerPage
        },
        true
      );

      setActiveFilters({
        fyYear: "",
        facility: "",
        category: "",
        subcategory: "",
        year: "",
        scope: ""
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleApplyFilter = async () => {
    toggleDrawer();
    setIsLoading(true);
    let body = {
      facility: activeFilters.facility,
      category: activeFilters.category,
      subCategory: activeFilters.subcategory,
      year: yearData,
      scope: activeFilters.scope,
      from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
      size: postPerPage
    };

    setFiltersApplied(true);

    try {
      await getDataQuality(body);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const removeFilter = async (filterName) => {
    const defaultFilters = {
      fyYear: "",
      facility: "",
      category: "",
      subcategory: "",
      year:"",
      scope: ""
    };

    const updatedFilters = { ...activeFilters };

    if (defaultFilters.hasOwnProperty(filterName)) {
      updatedFilters[filterName] = defaultFilters[filterName];
    } else {
      delete updatedFilters[filterName];
    }

    setActiveFilters(updatedFilters);

    const body = {
      facility: updatedFilters.facility,
      category: updatedFilters.category,
      subCategory: updatedFilters.subcategory,
      year: yearData,
      scope: updatedFilters.scope,
      from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
      size: postPerPage
    };
    const allFiltersEmpty = Object.values(updatedFilters).every((value) => value === "" || value === undefined);
    if (allFiltersEmpty) {
      await getDataQuality(
        {
          year: initialYear,
          from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
          size: postPerPage
        },
        true
      );

      setFiltersApplied(false);
    } else if (filtersApplied) {
      setIsLoading(true);
      try {
        await getDataQuality(body);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  const getPayLoad = (pageNo) => {
    let body = {
      year: currentYear,
      from: `${parseInt(pageNo) - 1 < 0 ? 0 : parseInt(pageNo) - 1}`,
      size: postPerPage
    };

    return body;
  };

  const handlePageChange = async (e, value) => {
    setIsLoading(true);
    if (value - 1 < 0) {
      SetCurrentPage(0);
    } else {
      SetCurrentPage((value - 1) * postPerPage);
    }
    try {
      if (value - 1 < 0) {
        await getDataQuality(getPayLoad(0), true);
      } else {
        await getDataQuality(getPayLoad((value - 1) * postPerPage), true);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getSLiceWithElips = (value, to, size) => {
    if (value && value !== "") {
      if (value.length > size) {
        return value.slice(0, to) + "...";
      } else {
        return value;
      }
    }
  };
  const handleback = () => {
    navigate("/measure");
  };

  const calculatePercentage = (data) => {
    const totalMonths = 12;
    const trueCount = Object.values(data).filter((value) => value === true).length;
    const percentage = (trueCount / totalMonths) * 100;
    return Math.round(percentage);
  };

  const getStrokeColor = (percentage) => {
    if (percentage === 100) {
      return "#4CAF50";
    } else if (percentage > 0 && percentage < 100) {
      return "#FBC02D";
    } else {
      return "#F44336";
    }
  };

  const gotoBreakDown = (item, index) => {
    navigate("breakdown", { state: { data: item, tab: tabValue } });
  };

  const categoryName = (id) => {
    const categoryName = categoryList?.find((t) => t._id === id);
    return categoryName ? categoryName.name : id;
  };
  const subCategoryName = (id) => {
    const subCategoryName = subcategoryList?.find((t) => t._id === id);
    return subCategoryName ? subCategoryName.name : id;
  };

  const getFacilityName = (id) => {
    const facility = user?.facilities?.find((f) => f._id === id);
    return facility ? facility.name : id;
  };



  return (
    <>
      <div style={{ position: "relative" }} className="env-filter-wrapper">
        <CustomDrawer open={drawerOpen} onClose={toggleDrawer}>
          <p className="filter-text">Filters</p>
          <div className="filter-container">
            <div className="allfilter-container">
              {country === "India" ? (
                <Select
                  value={` ${yearSelected.calenderYear ? "" : "FY"} ${yearData}`}
                  placeholder="Select Year"
                  size="large"
                  className="custom-select"
                  name="fyYear"
                  onChange={(value) => handleChange({ target: { value, name: "fyYear" } })}
                  style={{ width: "340px" }}
                  dropdownRender={(menu) => (
                    <div className="dropdownFyContinar">
                      <div className="year-select-container d-flex">
                        <div
                          className={`year-select ${yearSelected.financialYear ? "active-year" : ""}`}
                          onClick={() => setYearSelected({ financialYear: true, calenderYear: false })}
                        >
                          <p>Financial Year</p>
                        </div>

                        <div
                          className={`year-select ${yearSelected.calenderYear ? "active-year" : ""}`}
                          onClick={() => setYearSelected({ financialYear: false, calenderYear: true })}
                        >
                          <p>Calender Year</p>
                        </div>
                      </div>
                      {menu}
                    </div>
                  )}
                >
                  {yearSelected.financialYear
                    ? financialYear.map((item, key) => (
                        <Select.Option key={key} value={item} label={item.name}>
                          {item}
                        </Select.Option>
                      ))
                    : calendarYear.map((item, key) => (
                        <Select.Option key={key} value={item} label={item.name}>
                          {item}
                        </Select.Option>
                      ))}
                </Select>
              ) : (
                <div>
                  <p className="dropdown-lebel">Year</p>
                  <select
                    value={activeFilters["fyYear"]}
                    onChange={handleChange}
                    name="year"
                    className={`form-select modalInput`}
                    aria-label=".form-select-lg example"
                    style={{ width: "340px" }}
                  >
                    {previousYears.map((item, key) => (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <Divider />
              <div>
                <p className="dropdown-lebel">Facility</p>
                {/* <select
                  value={activeFilters["facility"]}
                  onChange={handleChange}
                  name="facility"
                  className={`form-select modalInput`}
                  aria-label=".form-select-lg example"
                  style={{ width: "340px" }}
                >
                  <option value="">All Facilities</option>
                  {user?.facilities?.map((item, key) => (
                    <option key={key} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select> */}
                <Select
                  showSearch
                  value={activeFilters["facility"]}
                  placeholder="Select a facility"
                  name="facility"
                  optionFilterProp="facility"
                  size="large"
                  onChange={(value) => handleChange({ target: { value, name: "facility" } })}
                  style={{ width: "340px" }}
                  defaultValue=""
                >
                  <Select.Option value="" select>
                    All facility
                  </Select.Option>
                  {user?.facilities?.map((item, ind) => (
                    <Select.Option key={ind} value={item._id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <Divider />

              <div>
                <p className="dropdown-lebel">Scope</p>
                {/* <select
                  value={activeFilters["scope"]}
                  onChange={handleChange}
                  name="scope"
                  className={`form-select modalInput`}
                  aria-label=".form-select-lg example"
                  style={{ width: "340px" }}
                >
                  <option value="">All Scope</option>
                  {filterValueInScope?.map((item, ind) => (
                    <option key={ind} value={item.toLowerCase()}>
                      {item}
                    </option>
                  ))}
                </select> */}

                <Select
                  showSearch
                  value={activeFilters["scope"]}
                  placeholder="Select a scope"
                  name="scope"
                  optionFilterProp="scope"
                  size="large"
                  onChange={(value) => handleChange({ target: { value, name: "scope" } })}
                  style={{ width: "340px" }}
                  defaultValue=""
                >
                  <Select.Option value="" select>
                    All Scope
                  </Select.Option>
                  {filterValueInScope?.map((item, ind) => (
                    <Select.Option key={ind} value={item.toLowerCase()}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <Divider />
              <div>
                <p className="dropdown-lebel">Category</p>
                {/* <select
                  value={activeFilters["category"]}
                  onChange={handleChange}
                  name="category"
                  className={`form-select modalInput`}
                  aria-label=".form-select-lg example"
                  style={{ width: "340px" }}
                  disabled={!activeFilters.scope}
                >
                  <option value="">All Category</option>
                  {categoryList &&
                    categoryList.length > 0 &&
                    categoryList.map((item, key) => (
                      <option key={key} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </select> */}

                <Select
                  showSearch
                  value={activeFilters["category"]}
                  placeholder="Select a Category"
                  name="category"
                  optionFilterProp="category"
                  size="large"
                  onChange={(value) => handleChange({ target: { value, name: "category" } })}
                  style={{ width: "340px" }}
                  defaultValue=""
                  disabled={!activeFilters.scope}
                  // className={`form-select modalInput`}
                >
                  <Select.Option value="">All Category</Select.Option>
                  {categoryList &&
                    categoryList.length > 0 &&
                    categoryList?.map((item, ind) => (
                      <Select.Option key={ind} value={item._id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </div>
              <Divider />
              <div>
                <p className="dropdown-lebel">Subcategory</p>
                {/* <select
                  value={activeFilters["subcategory"]}
                  onChange={handleChange}
                  name="subcategory"
                  className={`form-select modalInput`}
                  aria-label=".form-select-lg example"
                  style={{ width: "340px" }}
                  disabled={!activeFilters.category}
                >
                  <option value="">All Subcategory</option>
                  {subcategoryList &&
                    subcategoryList.length > 0 &&
                    subcategoryList?.map((item, key) => (
                      <option key={key} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </select> */}
                <Select
                  showSearch
                  value={activeFilters["subcategory"]}
                  placeholder="Select a subcategory"
                  name="subcategory"
                  optionFilterProp="subcategory"
                  size="large"
                  onChange={(value) => handleChange({ target: { value, name: "subcategory" } })}
                  style={{ width: "340px" }}
                  defaultValue=""
                  disabled={!activeFilters.category}
                >
                  <Select.Option value="" select>
                    All Subcategory
                  </Select.Option>
                  {subcategoryList &&
                    subcategoryList.length > 0 &&
                    subcategoryList?.map((item, ind) => (
                      <Select.Option key={ind} value={item._id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </div>
              {/* <Divider />
              <div>
                <p className="dropdown-lebel">Uploaded by</p>
                <select
                  value={activeFilters["updatedBy"]}
                  onChange={handleChange}
                  name="updatedBy"
                  className={`form-select modalInput`}
                  aria-label=".form-select-lg example"
                  style={{ width: "340px" }}
                >
                  <option value="">All </option>
                  <option value="uploaded">Robin Sharma, robin@planetsustech.com</option>
                </select>
              </div> */}
              {/* <Divider />
              <div>
                <p className="dropdown-lebel">Evidences</p>
                <select
                  value={activeFilters["bill"]}
                  onChange={handleChange}
                  name="bill"
                  className={`form-select modalInput`}
                  aria-label=".form-select-lg example"
                  style={{ width: "340px" }}
                >
                  <option value="">All Evidences</option>
                  <option value="uploaded">Uploaded</option>
                  <option value="not uploaded">Not Uploaded</option>
                </select>
              </div> */}
              {/* <Divider />
              <div>
                <p className="dropdown-lebel">Evidence Type</p>
                <select
                  value={activeFilters["billType"]}
                  onChange={handleChange}
                  name="billType"
                  className={`form-select modalInput`}
                  aria-label=".form-select-lg example"
                  style={{ width: "340px" }}
                >
                  <option value="">All Evidence Type</option>
                  <option value="uploaded">Pdf</option>
                  <option value="not uploaded">Csv</option>
                </select>
              </div> */}
            </div>

            <div className="button-container">
              <Button type="primary" ghost className="text-black" onClick={handleReset}>
                Reset
              </Button>

              <Button type="primary" size="default" style={{ width: "120px" }} onClick={handleApplyFilter}>
                Apply
              </Button>
            </div>
          </div>
        </CustomDrawer>
      </div>

      <div className="selected-filter">
        {Object.keys(activeFilters).map((filterName) => {
          const filterValue = activeFilters[filterName];
          let displayValue;

          switch (filterName) {
            case "category":
              displayValue = categoryName(filterValue);
              break;
            case "subcategory":
              displayValue = subCategoryName(filterValue);
              break;
            case "facility":
              displayValue = getFacilityName(filterValue);
              break;
            default:
              displayValue = filterValue;
              break;
          }

          return (
            <>
              {displayValue ? (
                <div key={filterName} className="filter-chip">
                  <span style={{ fontSize: "12px", fontWeight: "normal" }}>{ capitalizeFirstLetter(displayValue)}</span>

                  <CloseOutlined style={{ height: "12px" }} onClick={() => removeFilter(filterName)} />
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
      </div>

      {resultDataQuality.isLoading || isLoading ? (
        <div className="empty-wrapper">
          <WidgetLoader />
        </div>
      ) : resultDataQuality?.data?.data?.data.length === 0 ? (
        <div className="empty-wrapper">
          <div className="empty-container">
            <img src="/empty-box.svg" alt="EmptyImage" style={{ width: "236px" }} />
            <p>No existing data entries to show. Please add data.</p>
            <button type="button" style={{ width: "176px" }} onClick={handleback} className="btn btn-success">
              + Add new data
            </button>
          </div>
        </div>
      ) : (
        resultDataQuality?.data?.data?.data.length > 0 && (
          <div className="mainEnv-wrapper">
            <div className="envContainer">
              <div style={{ width: "70%" }} className="first-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Facility</th>
                      <th>Scope</th>
                      <th>Category</th>
                      <th>Subcategory</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultDataQuality?.data?.data?.data.map((item, index) => (
                      <tr key={item + "quality"}>
                        <td>
                          <Tooltip title={item?.facility?.name} arrow placement="top">
                            {item?.facility?.name ? getSLiceWithElips(item?.facility?.name, "12", "10") : "NA"}
                          </Tooltip>
                        </td>

                        <td>
                          <Tooltip title={item?.scope? capitalizeFirstLetter(item?.scope):""} arrow placement="top">
                            {capitalizeFirstLetter(getSLiceWithElips(item?.scope, "10", "10"))}
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={item?.category?.name} arrow placement="top">
                            {getSLiceWithElips(item?.category?.name, "15", "15")}
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={item?.subCategory?.name} arrow placement="top">
                            {getSLiceWithElips(item?.subCategory?.name, "15", "15")}
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div style={{ width: "25%" }} className="scrollable">
                <table className="table">
                  <thead>
                    <tr>
                      {allMonth.map((month, ind) => (
                        <th key={month + ind}>{month}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {resultDataQuality?.data?.data?.data.map((item, index) => (
                      <tr key={item + "month"}>
                        {allMonth.map((month, ind) => (
                          <td key={month + ind}>
                            {item.months[month] === true ? (
                              <img src={"/images/icons/green-tick-circle.svg"} alt="icon" />
                            ) : (
                              <img src={"/images/icons/red-cross-box.svg"} alt="icon" />
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="thirdtable">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Data Completeness</th>
                      {/* <th className="text-nowrap">View Details</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {resultDataQuality?.data?.data?.data.map((item, index) => (
                      <tr key={item + index}>
                        <td>
                          <Progress
                            type="circle"
                            percent={calculatePercentage(item.months) === 0 ? "100" : calculatePercentage(item.months)}
                            size={50}
                            strokeColor={getStrokeColor(calculatePercentage(item.months))}
                            format={() =>
                              calculatePercentage(item) === 100
                                ? `${calculatePercentage(item.months)}%`
                                : `${calculatePercentage(item.months)}%`
                            }
                            status={calculatePercentage(item.months) === 0 ? "exception" : ""}
                          />
                        </td>
                        {/* <td>
                          <div className="mt-3">
                            <img
                              src={"/images/icons/right-arrow.svg"}
                              className="cursor-pointer"
                              alt="icon"
                              onClick={() => gotoBreakDown(item, index)}
                            />
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      )}

      <div className="mt-auto py-4">
        <Stack spacing={2}>
          <Pagination count={Math.ceil(totalCount / postPerPage)} shape="rounded" onChange={handlePageChange} />
        </Stack>
      </div>
    </>
  );
};
