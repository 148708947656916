import React, { useCallback } from 'react'
import './SearchBar.scss'
import { debounce } from 'lodash';

const SearchBar = ({ placeHolder, onSearch = () => {} } ) => {

  const debouncedSearch = useCallback(
    debounce(async (value) => {
      await onSearch(value);
    }, 500),
    []
  );

  const handleChange = (event) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  return (
    <div className="invite-container">
      <input
        type="text"
        className="email-input"
        placeholder={placeHolder}
        onChange={handleChange}
      />
      <img src="images/icons/search-icon.svg" alt="icon" />
    </div>
  )
}

export default SearchBar