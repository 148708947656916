import React from "react";
import Accordion from "../../../../../components/Accordion/Accordion";
import { useDispatch, useSelector } from "react-redux";
import "../../Social.scss";
import { setPageWiseData } from "../../../../../state/SocialSlice";

function FreedomAssociation({formEditable, editMode}) {
  const { pageWiseData } = useSelector((state) => state.social);

  const dispatch = useDispatch();

  let socialAllData = JSON.parse(JSON.stringify(pageWiseData));

  const handleOperationsAndSuppliersChange=(e, index)=>{
    socialAllData.freedom.freedomOfAssociation.operationsAndSuppliers[index][e.target.name] = e.target.value
    dispatch(setPageWiseData(socialAllData))
  }
const addRowOperationsAndSuppliers=()=>{
   let productRow={ collectiveBargaining: "", typeOfOperation: "", typeofSupplier: "", country: "", region: "" }
   socialAllData.freedom.freedomOfAssociation.operationsAndSuppliers.push(productRow)
   dispatch(setPageWiseData(socialAllData))
}

const handleMeasuresTakenByTheOrganization =(e)=>{
  socialAllData.freedom.freedomOfAssociation.measuresTakenByTheOrganization= e.target.value
  dispatch(setPageWiseData(socialAllData))
}

// const deleteRow = (rowIndex, name) => {
//   if (rowIndex > 0) {
//     let tempArray = [...socialAllData.freedomOfAssociation[name]];
//     tempArray.splice(rowIndex, 1);
//     socialAllData.freedomOfAssociation[name] = tempArray;
//     dispatch(setPageWiseData(socialAllData));
//   }
// };


const deleteRow = (parentKey, arrayKey, rowIndex) => {
  const parentObject = socialAllData.freedom?.[parentKey];
  const dataArray = parentObject?.[arrayKey];
  if (Array.isArray(dataArray)) {
    if (rowIndex >= 0 && rowIndex < dataArray.length) {
      const updatedArray = dataArray.filter((_, index) => index !== rowIndex);

      const updatedParentObject = {
        ...parentObject,
        [arrayKey]: updatedArray
      };

      const updatedData = {
        ...socialAllData,
        freedom: {
          ...socialAllData.freedom,
          [parentKey]: updatedParentObject
        }
      };

      dispatch(setPageWiseData(updatedData));
    }
  }
};


  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Freedom of Association and Collective Bargaining"} index={0}>
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">
              Operations and suppliers in which the right to freedom of association and collective bargaining may be at
              risk in terms of:
            </p>
            <table className="form-table">
              <thead>
                <tr>
              
                  <th scope="col"> Collective Bargaining Risk</th>
                  <th scope="col"> Type of Operation</th>
                  <th scope="col">Type of Supplier</th>
                  <th scope="col">Country</th>
                  <th scope="col">Region</th>
                  <th className="Col-md-1" scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.freedom?.freedomOfAssociation?.operationsAndSuppliers?.map((item, index) => (
                  <tr key={item + index}>
                
                    <td>
                      <input
                        type="text"
                        name="collectiveBargaining"
                        value={item.collectiveBargaining}
                        onChange={(e) => handleOperationsAndSuppliersChange(e, index)}
                       className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="typeOfOperation"
                        value={item.typeOfOperation}
                        onChange={(e) => handleOperationsAndSuppliersChange(e, index)}
                       className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="typeofSupplier"
                        value={item.typeofSupplier}
                        onChange={(e) => handleOperationsAndSuppliersChange(e, index)}
                       className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="country"
                        value={item.country}
                        onChange={(e) => handleOperationsAndSuppliersChange(e, index)}
                       className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="region"
                        value={item.region}
                        onChange={(e) => handleOperationsAndSuppliersChange(e, index)}
                       className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("freedomOfAssociation", "operationsAndSuppliers" , index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-outline-success add-new-row mb-4" onClick={()=>addRowOperationsAndSuppliers()}>
            <p>+ Add variable</p>
          </button>

          <div className="foreword-container">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                Measures taken by the organization in the reporting period intended to support rights to exercise
                freedom of association and collective bargaining
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData?.freedom?.freedomOfAssociation?.measuresTakenByTheOrganization}
                  name="measuresTakenByTheOrganization"
                  onChange={(e)=>handleMeasuresTakenByTheOrganization(e)}
                  className="no-style-input w-100"
                  disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default FreedomAssociation;
