import React, { useState } from "react";
import PropTypes from "prop-types";
import "./TextAreaWithCount.scss";


const TextAreaWithWordCount = ({
  maxCharacters = 200,
  placeholder = "Start typing here...",
  customClass = "",
  value = "",
  name = "",
  disabled = false,
  className,
  onChange = () => { },
  row
}) => {
  const [text, setText] = useState("");

  const countWords = (text) => {
    return value.trim().split(/\s+/).filter(Boolean).length;
  };

  const handleChange = (e) => {
    const newText = e.target.value;
    const characterCount = countWords(newText);
    onChange(e);
    if (characterCount <= maxCharacters) {
      setText(newText);

    }
  };

  const characterCount = countWords(value);

  return (
    <div className={`textarea-container ${customClass}`}>
      <textarea
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        rows= {row?row :"2" }
        name={name}
        style={className === "bt-0" ? { overflow: "hidden", resize: "none", borderTop: "0px" } : { overflow: "hidden", resize: "none" }}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${e.target.scrollHeight}px`;
        }}
        disabled={disabled}
      />
      <div className="character-count">
        {characterCount}/{maxCharacters}
      </div>
    </div>
  );
};

TextAreaWithWordCount.propTypes = {
  maxCharacters: PropTypes.number,
  placeholder: PropTypes.string,
  customClass: PropTypes.string,
  onChange: PropTypes.func
};

export default TextAreaWithWordCount;
