export const esgEndpoints = (build) => ({
       getEsgTemplateList:build.query({
           query:(arg)=>({
            url: `esg/list`,
            params: { ...arg }
           }),
           providesTags: ["Cards"]
       }),

       postEsgFormData: build.mutation({
        query: (postBody) => ({
          url: `esg`,
          method: "POST",
          body: postBody
        }),
    
        transformResponse: (response) => {
          return response;
        },
    
        invalidatesTags: ["Carddata"]
      }),

      getEsgDataEntries: build.query({
        query: (arg) => ({
          url: `esg`,
          params: { ...arg }
        }),
        providesTags: ["DataEntries"]
      }),



      updateEsgEntryData: build.mutation({
        query: (postBody) => ({
          url: `esg/update`,
          method: "PATCH",
          body: postBody
        }),
    
        invalidatesTags: [
          "DataEntries",
        ],
    
        transformResponse: (response) => {
          return response;
        }
      })

})