import React, { useEffect, useState } from "react";
import { DatePicker, Menu, Select } from "antd";
import { Avatar } from "@mui/material";
import CustomModal from "../../components/Modal/Modal";

import "./AuditLog.scss";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useLazyGetAuditLogDataQuery, useLazyGetAuditLogFilterDataQuery } from "../../state/api";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const AuditLog = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visible, setVisible] = useState(false);
  const [activeDropDown, setActiveDropDown] = useState();

  const [getAuditLogData, resultAuditLogData] = useLazyGetAuditLogDataQuery();
  const [getAuditLogFilterData, resulFilterData] = useLazyGetAuditLogFilterDataQuery();

  const [openModal, setopenModal] = useState(false);
  const [modalContent, setmodalContent] = useState("");
  const [ipAddress, setIpAddress] = useState();

  const [filterOption, setfilterOption] = useState();

  const [filterSelectData, setFilterSelectData] = useState({
    module: "all",
    userName: "all",
    activity: "all",
    startDate: "",
    endDate: "",
    year: dayjs()
  });

  useEffect(() => {
    getAuditLogFilterData();
  }, []);

  useEffect(() => {
    const isDefault = Object.values(filterSelectData).every((value) => value === "" || value === null);
    if (isDefault) {
      getAuditLogData();
    } else {
      getAuditLogData({
        action: filterSelectData.activity,
        tab: filterSelectData.module,
        user: filterSelectData.userName,
        startDate: filterSelectData.startDate ? filterSelectData.startDate : "",
        endDate: filterSelectData.endDate ? filterSelectData.endDate : "",
        year: filterSelectData.year ? dayjs(filterSelectData.year).format("YYYY") : "all"
      });
    }
  }, [filterSelectData]);

  useEffect(() => {
    if (resulFilterData?.data) {
      setfilterOption(resulFilterData.data.data[0]);
    }
  }, [resulFilterData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const tableHead = [
    {
      title: "Module",
      icon: "/images/icons/filter-icon.svg"
    },
    {
      title: "User Role",
      icon: "/images/icons/filter-icon.svg"
    },
    {
      title: "User Name",
      icon: "/images/icons/filter-icon.svg"
    },
    {
      title: "Timestamp",
      icon: "/images/icons/filter-icon.svg"
    },
    {
      title: "Activity",
      icon: "/images/icons/filter-icon.svg"
    },
    {
      title: "Summary",
      icon: ""
    },
 
    {
      title: "Year",
      icon: "/images/icons/filter-icon.svg"
    }
  ];

  const handleChange = (value, name) => {
    setFilterSelectData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };


  const showMore = (message) => {
    setopenModal(true);
    setmodalContent(message);
  };

  function getIPAddress() {
    let pc1 = new RTCPeerConnection();
    let pc2 = new RTCPeerConnection();

    pc1.onicecandidate = (event) => {
      if (event.candidate) {
        pc2.addIceCandidate(event.candidate);
      }
    };

    pc2.onicecandidate = (event) => {
      if (event.candidate) {
        pc1.addIceCandidate(event.candidate);
      }
    };

    pc1
      .createOffer()
      .then((offer) => {
        return pc1.setLocalDescription(new RTCSessionDescription({ type: "offer", sdp: offer.sdp }));
      })
      .then(() => {
        return pc2.setRemoteDescription(pc1.localDescription);
      })
      .then(() => {
        return pc2.createAnswer();
      })
      .then((answer) => {
        return pc2.setLocalDescription(new RTCSessionDescription({ type: "answer", sdp: answer.sdp }));
      })
      .then(() => {
        return pc1.setRemoteDescription(pc2.localDescription);
      })
      .then(() => {
        const lines = pc1.localDescription.sdp.split("\n");
        const ipRegex =
          /\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/;
        let ipAddress;

        lines.forEach((line) => {
          const match = line.match(ipRegex);
          if (match) {
            ipAddress = match[0];
          }
        });

        // return ipAddress;
        setIpAddress(ipAddress);
        // console.log(ipAddress); // prints the IP address
      })
      .catch((error) => console.error("Error:", error))
      .finally(() => {
        pc1.close();
        pc2.close();
      });
  }

  useEffect(() => {
    getIPAddress();
  }, []);

  // const hnadleDownloadData = () => {
  //   const csvString = convertToCSV(resultAuditLogData?.data?.data);
  //   downloadCSV(csvString, "auditlog-data.csv");
  // };

 

  return (
    <>
      {
        <CustomModal modalOpen={openModal} setModalOpen={setopenModal}>
          <div className="d-flex justify-content-end" style={{ marginTop: "-19px", marginRight: "-19px" }}>
            <img
              src="/images/icons/cross-icon.svg"
              alt=""
              className="cursor-pointer"
              onClick={() => setopenModal(false)}
              style={{ width: "48px", height: "48px" }}
            />
          </div>
          <div className="main-container">
            <div className="p-3 pt-0">
              <p>{modalContent}</p>
            </div>
          </div>
        </CustomModal>
      }
      <div>
        <div className="scopeDesc d-flex justify-content-between align-items-center">
          <div>
            <p className="page-header-text">Audit Log</p>
            <p className="fs-6 fw-normal" style={{ fontSize: "14px" }}>
              Monitor any changes made to content
            </p>
          </div>
{/* 
          <button
            type="button"
            style={{ width: "150px", height: "fit-content" }}
            onClick={hnadleDownloadData}
            className="btn btn-success"
          >
            Download
          </button> */}
        </div>
        <div className="d-flex justify-content-between mt-4">
          <Select
            showSearch
            value={filterSelectData.module ? filterSelectData.module : null}
            placeholder="Select Modules"
            name="module"
            optionFilterProp="module"
            size="large"
            onChange={(value) => handleChange(value, "module")}
            style={{ width: "200px" }}
          >
            <Select.Option value={"all"}>{"All Module"}</Select.Option>
            {filterOption?.tabs.map((item, ind) => (
              <Select.Option key={ind} value={item.tab}>
                {item.tab}
              </Select.Option>
            ))}
          </Select>
          <Select
            showSearch
            value={filterSelectData.userName ? filterSelectData.userName : null}
            placeholder="Select User Name"
            name="userName"
            optionFilterProp="userName"
            size="large"
            onChange={(value) => handleChange(value, "userName")}
            style={{ width: "200px" }}
          >
            <Select.Option value={"all"}>{"All User"}</Select.Option>
            {filterOption?.users.map((item, ind) => (
              <Select.Option key={ind} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          <RangePicker
            // style={{ width: "200px" }
            onChange={(dates) => {
              if (dates && dates.length === 2) {
                const [startDate, endDate] = dates;
                handleChange(startDate.format("YYYY-MM-DD"), "startDate");
                handleChange(endDate.format("YYYY-MM-DD"), "endDate");
              }else{
                handleChange("", "startDate");
                handleChange("", "endDate");
              }
            }}
          />

          <Select
            showSearch
            value={filterSelectData.activity ? filterSelectData.activity : null}
            placeholder="Select Activity"
            name="activity"
            optionFilterProp="activity"
            size="large"
            onChange={(value) => handleChange(value, "activity")}
            style={{ width: "200px" }}
          >
            <Select.Option value={"all"}>{"All Activity"}</Select.Option>
            {filterOption?.actions.map((item, ind) => (
              <Select.Option key={ind} value={item.action}>
                {item.action}
              </Select.Option>
            ))}
          </Select>

          <DatePicker
            picker="year"
            // defaultValue={dayjs()}
            value={filterSelectData.year ? filterSelectData.year : null}
            format={"YYYY"}
            onChange={(value) => handleChange(value, "year")}
            size="large"
            placeholder="Select Year"
            style={{ width: "  200px" }}
          />
        </div>
        {resultAuditLogData?.data?.data && resultAuditLogData?.data?.data.length > 0 ? (
          <div className="auitlog-table-container">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHead.map((item, index) => (
                      <TableCell>
                        <div className="d-flex gap-1 text-nowrap text-start">{item.title}</div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultAuditLogData?.data?.data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index + item} align="right">
                        <TableCell className="text-start">{item.tab}</TableCell>
                        <TableCell className="text-nowrap text-start">{item.user.userGroup.name}</TableCell>

                        <TableCell className="text-start">
                          <div className="d-flex gap-2">
                            <div className="d-flex gap-2 cursor-pointer">
                              <div
                                className="rounded-circle overflow-hidden"
                                style={{ maxWidth: "36px", maxHeight: "36px" }}
                              >
                                <Avatar alt="avatar" sx={{ width: 36, height: 36 }}>
                                  {item.name?.slice(0, 2)}
                                </Avatar>
                              </div>
                              <div className="d-flex flex-column justify-content-center">
                                {item.user.name && (
                                  <p
                                    className="text-start"
                                    style={{ fontWeight: "500", fontSize: "14px", marginBottom: "4px" }}
                                  >
                                    {item.user.name}
                                  </p>
                                )}
                                {item.user.email && (
                                  <p className="text-start" style={{ fontSize: "14px" }}>
                                    {item.user.email}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="text-start">
                          {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                        </TableCell>
                        <TableCell
                          className="text-start"
                          style={{ color: item.action === "DELETE" ? "#D32F2F" : "#129600" }}
                        >
                          {item.action}
                        </TableCell>

                        {item.message.length > 100 ? (
                          <TableCell className="text-start">
                            {item.message.slice(0, 100) + "..."}{" "}
                            <span className="text-primary cursor-pointer" onClick={() => showMore(item.message)}>
                              Read more...
                            </span>
                          </TableCell>
                        ) : (
                          <TableCell className="text-start">{item.message}</TableCell>
                        )}
                        {/* <TableCell className="text-start">{ipAddress}</TableCell> */}
                        <TableCell className="text-start">{dayjs(item.createdAt).format("YYYY")}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="mt-auto">
              <div className="d-flex justify-content-end align-items-center">
                <p className="text-dark fs-6 fw-normal" style={{ fontSize: "14px", marginRight: "-20px" }}>
                  Showing
                </p>
                <TablePagination
                  rowsPerPageOptions={false}
                  count={resultAuditLogData?.data?.data.length}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <div className="empty-container d-flex justify-content-center align-items-center flex-column gap-3 ">
              <img src="/empty-box.svg" alt="Empty image" style={{ width: "236px" }} />
              <p>Data not available</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AuditLog;
