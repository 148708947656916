export const dashboardEndpoints = (build) => ({
  getDashboardScopeData: build.query({
    query: (arg) => ({
      url: `dashboard/scope`,
      params: { ...arg }
    }),
    providesTags: ["Dashscope"]
  }),
  getAllScopes: build.query({
    query: () => ({
      url: `dashboard/scopes`
    }),
    providesTags: ["Scopelist"]
  }),
  getMonthlyScope: build.query({
    query: (arg) => ({
      url: `dashboard/month`,
      params: { ...arg }
    }),
    providesTags: ["Dashmonthscope"]
  }),
  getMonthlyFacility: build.query({
    query: (arg) => ({
      url: `dashboard/month/facility`,
      params: { ...arg }
    }),
    providesTags: ["Dashmonthfacility"]
  }),
  getTopFacility: build.query({
    query: (arg) => ({
      url: `dashboard/facility`,
      params: { ...arg }
    }),
    providesTags: ["Topfacility"]
  }),
  getTopCategory: build.query({
    query: (arg) => ({
      url: `dashboard/category`,
      params: { ...arg }
    }),
    providesTags: ["Topcategory"]
  }),
  getTopSubcategory: build.query({
    query: (arg) => ({
      url: `dashboard/subCategory`,
      params: { ...arg }
    }),
    providesTags: ["Topsubcategory"]
  }),
  getEmissionScopeCategory: build.query({
    query: (arg) => ({
      url: `dashboard/scope/category`,
      params: { ...arg }
    }),
    providesTags: ["Scopecategory"]
  }),
  getSpendData: build.query({
    query: (arg) => ({
      url: `dashboard/month/spend`,
      params: { ...arg }
    }),
    providesTags: ["SpendData"]
  }),

  getEmissionSummaryData:build.query({
    query: (arg) => ({
      url: `dashboard/summary`,
      params: { ...arg }
    }),
    providesTags: ["Summary"]
  })
});
