import { MoreVert } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import editIcon from "../../../../src/images/editIcon.svg";
import trash from "../../../../src/images/trash.svg";
import copy from "../../../../src/images/copy.svg";
import {
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import DeleteModal from "../../../components/ModalDelete";
import { useDeleteImporterMutation, useGetCountryListQuery, useGetImporterListQuery, useLazyGetUserDetailByIdQuery } from "../../../state/api";
import { AddImporterModal } from "./Component/AddImporterModal";
import "./Importer.scss";
import { styled } from "@mui/styles";
import { useSelector } from "react-redux";

const Importers = () => {
  const [addImporterModal, setAddImporterModal] = useState(false);
  const countryData = useGetCountryListQuery();
  const [ImporterData, setImporterData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentImporter, setcurrentImporter] = useState();
  const [pageLength, setPageLength] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [importerSearchTerm, setImporterSearchTerm] = useState("");
  const importerListData = useGetImporterListQuery({ pageLength, pageNo, search: importerSearchTerm });
  const [CountrySelected, setCountrySelected] = useState("all");
  const [deleteImporter] = useDeleteImporterMutation();
  const [editFlow, setEditFlow] = useState(false);

  const orgHeaders = ["Importer Name", "EORI Code", "Product Name", "Country", "City", "Address", "Status"];
  const menuOptions = [
    {
      icon: editIcon, // Assuming you have an EditIcon component
      text: "Edit Importer"
    },
    {
      icon: copy, // Assuming you have a DuplicateIcon component
      text: "Duplicate Importer"
    },
    {
      icon: trash, // Assuming you have a DeleteIcon component
      text: "Delete Importer"
    }
  ];

  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null)
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery()

  useEffect(() => {
    getUser({ id: userId._id }, true)
  }, [])

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data)
    }
  }, [userResult.data?.data])


  const StyledPaper = styled("div")({
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.16)",
    borderRadius: "8px"
  });
  useEffect(() => {
    if (!anchorEl && !addImporterModal && !isDeleteOpen) setcurrentImporter(null);
  }, [anchorEl, addImporterModal, isDeleteOpen]);

  useEffect(() => {
    if (CountrySelected === "all") {
      setImporterData(importerListData?.data?.data?.data);
    } else {
      let filteredData = importerListData?.data?.data?.data;
      if (CountrySelected !== "all") {
        filteredData = filteredData?.filter((item) => item.country === CountrySelected);
      }
      setImporterData(filteredData);
    }
  }, [importerSearchTerm, CountrySelected, addImporterModal, importerListData?.data?.data?.data]);

  useEffect(() => {
    setImporterData(importerListData?.data?.data?.data);
  }, [importerListData?.data?.data?.data, addImporterModal]);

  const handleSearchChange = (e) => {
    setImporterSearchTerm(e.target.value);
  };
  const handleOptionsOpen = (event, id) => {
    setcurrentImporter(id);
    setAnchorEl(event.currentTarget);
  };
  const handleOptions = (option) => {
    if (option === "Edit Importer") {
      setAddImporterModal(true);
      setEditFlow(true);
    }
    if (option === "Delete Importer") {
      setIsDeleteOpen(true);
    }
    if (option === "Duplicate Importer") {
      setIsDuplicate(true);
      setAddImporterModal(true);
    }
    setAnchorEl(null);
  };
  const handleProductDelete = () => {
    deleteImporter(currentImporter);
    setIsDeleteOpen(false);
  };

  const handleChangePage = (e) => {
    if (e?.target?.dataset?.testid === "KeyboardArrowRightIcon") {
      const maxPage = Math.ceil(importerListData?.data?.data?.totalCount / pageLength); // Calculate the maximum page number
      if (pageNo <= maxPage) {
        setPageNo(pageNo + 1);
      }
    }
    if (e?.target?.dataset?.testid === "KeyboardArrowLeftIcon" && pageNo > 0) {
      setPageNo(pageNo - 1);
    }
  };

  const tableRows = ImporterData?.map((item, index) => (
    <TableRow key={item._id}>
      <TableCell
        sx={{
          minWidth: "150px",
          width: "fit-content", // Ensures the TableCell expands to fit its content but remains at least 200px
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit" // Conditionally apply text color
        }}
        className="text-start"
      >
        {item.importerName}
      </TableCell>
      <TableCell
        sx={{
          minWidth: "150px",
          width: "fit-content", // Ensures the TableCell expands to fit its content but remains at least 200px
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit" // Conditionally apply text color
        }}
        className="text-center"
      >
        {item.eoriCode}
      </TableCell>
      <TableCell className="text-center">
        {item?.products?.length > 1 ? (
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "auto"
            }}
          >
            <Tooltip
              title={item?.products.map((item, index) => (
                <div>{item?.productName}</div>
              ))}
            >
              <div
                style={{
                  paddingLeft: ".7rem",
                  paddingRight: ".7rem",
                  paddingTop: ".3rem",
                  paddingBottom: ".3rem",
                  backgroundColor: "#F2F3F7",
                  color: item.status === "Inactive" ? "#C6CBD9" : "#535362",
                  borderRadius: "12px",
                  marginRight: ".3rem",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                Products{" "}
                <div
                  style={{
                    borderRadius: "50%",
                    // padding: ".5rem",
                    backgroundColor: "#7E7E8F",
                    color: "white",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: ".5rem"
                  }}
                >
                  {item?.products?.length}
                </div>
              </div>
            </Tooltip>
          </div>
        ) : (
          item?.products.map((products, index) => (
            <span
              key={index}
              style={{
                paddingLeft: ".7rem",
                paddingRight: ".7rem",
                paddingTop: ".3rem",
                paddingBottom: ".3rem",
                backgroundColor: "#F2F3F7",
                color: item.status === "Inactive" ? "#C6CBD9" : "#535362",
                borderRadius: "12px",
                marginRight: ".3rem"
              }}
            >
              {products?.productName}
            </span>
          ))
        )}
      </TableCell>

      <TableCell
        sx={{
          minWidth: "150px",
          width: "fit-content", // Ensures the TableCell expands to fit its content but remains at least 200px
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit" // Conditionally apply text color
        }}
        className="text-center"
      >
        {item?.country}
      </TableCell>
      <TableCell
        sx={{
          minWidth: "135px",
          width: "fit-content", // Ensures the TableCell expands to fit its content but remains at least 200px
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit" // Conditionally apply text color
        }}
        className="text-center"
      >
        {item?.city}
      </TableCell>
      <TableCell
        sx={{
          minWidth: "150px",
          width: "fit-content", // Ensures the TableCell expands to fit its content but remains at least 200px
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit" // Conditionally apply text color
        }}
        className="text-center"
      >
        {item?.address}
      </TableCell>
      <TableCell
        sx={{
          minWidth: "150px",
          width: "fit-content", // Ensures the TableCell expands to fit its content but remains at least 200px
          color: item.status === "Inactive" ? "#C6CBD9" : "inherit" // Conditionally apply text color
        }}
        className="text-center"
        style={{ maxWidth: "100px" }}
      >
        {item?.status === "Active" ? (
          <span
            style={{
              paddingLeft: ".7rem",
              paddingRight: ".7rem",
              backgroundColor: "#DAFCD6",
              color: "#22B60E",
              borderRadius: "12px",
              paddingTop: ".2rem",
              paddingBottom: ".2rem"
            }}
          >
            {item.status}
          </span>
        ) : item?.status === "Draft" ? (
          <span
            style={{
              paddingLeft: "1rem",
              paddingRight: "1rem",
              backgroundColor: "#FFF9C4",
              color: "#F57F17",
              borderRadius: "12px",
              paddingTop: ".2rem",
              paddingBottom: ".2rem"
            }}
          >
            {item.status}
          </span>
        ) : (
          <span
            style={{
              paddingLeft: ".7rem",
              paddingRight: ".7rem",
              color: "#C6CBD9"
            }}
          >
            {item.status}
          </span>
        )}
      </TableCell>
      {user?.userGroup?.permissions?.cbam?.editImporters &&
      <TableCell>
        <IconButton
          aria-label="more"
          id="long-button"
          key={item._id}
          aria-haspopup="true"
          onClick={(e) => handleOptionsOpen(e, item._id)}
        >
          <MoreVert />
        </IconButton>
        <StyledPaper>
          <Menu
            id="long-menu"
            sx={{ width: "auto" }}
            MenuListProps={{
              "aria-labelledby": "long-button"
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(false)}
            PaperProps={{
              style: {
                maxHeight: 40 * 4.5,
                width: "20ch"
              }
            }}
          >
            {menuOptions?.map((option) => (
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", paddingLeft: "1rem" }}>
                {" "}
                <img src={option.icon} alt="" />
                <MenuItem sx={{ marginLeft: "-.6rem" }} key={option} onClick={() => handleOptions(option.text)}>
                  {option.text}
                </MenuItem>
              </div>
            ))}
          </Menu>
        </StyledPaper>
      </TableCell>}
    </TableRow>
  ));

  const noDataMessage = (
    <TableRow>
      <TableCell colSpan={7}>
        {importerSearchTerm === ""
          ? "Click on Add new button to add a product in the list"
          : "No results found. Please search using different fields"}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <DeleteModal
        openModal={isDeleteOpen}
        setModalOpen={setIsDeleteOpen}
        currentItem={currentImporter}
        handleFileDelete={handleProductDelete}
        heading={"Are you sure you want to delete this Importer?"}
        itemName={ImporterData?.filter((item) => item._id === currentImporter)?.[0]?.importerName}
      />
      <AddImporterModal
        addImporterModal={addImporterModal}
        currentProduct={currentImporter}
        modalOpen={addImporterModal}
        setModalOpen={setAddImporterModal}
        productId={currentImporter}
        setProductId={setcurrentImporter}
        isDuplicate={isDuplicate}
        setIsDuplicate={setIsDuplicate}
        editFlow={editFlow}
      />
      <div className="scopeDesc">
        <p className="page-header-text">Importers List</p>
        <p style={{ fontSize: "16px" }}>Add list of all your importers below</p>
      </div>
      <div className="mt-3 report-detail-container">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-wrap gap-2 flex-fill" style={{ maxWidth: "45%" }}>
            <div className="me-10 flex-fill" style={{}}>
              <TextField
                id="search"
                type="search"
                label="Search"
                value={importerSearchTerm}
                onChange={handleSearchChange}
                // onKeyPress={handleKeyPress}
                sx={{ background: "white", border: "none", }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ cursor: "pointer" }}>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{
                  style: { top: "-6" }
                }}
                className="ps-search-bar"
              />
            </div>
            <div className="filters-container">
              <Select
                placeholder="Country Name"
                onChange={(e) => setCountrySelected(e)}
                optionFilterProp="facility"
                size="large"
              >
                <Select.Option value={"all"}>All</Select.Option>
                {countryData?.data?.data?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.name}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="ml-0">
            <button
              className="mx-0 btn btn-success"
              onClick={() => {
                setAddImporterModal(true);
              }}
            >
              + Add New
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <TableContainer style={{ background: "white" }}>
          <Table sx={{ minWidth: 650, minHeight: ImporterData?.length === 0 ? 600 : "" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {orgHeaders?.map((item, key) => (
                  <TableCell
                    key={key + "0"}
                    className={`fw-bold ${item === "" || item === "Importer Name" ? "text-start" : "text-center"}`}
                  >
                    {item}
                  </TableCell>
                ))}
                {
                  user?.userGroup?.permissions?.cbam?.editImporters &&
                  <TableCell>Action</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>{ImporterData?.length > 0 ? tableRows : noDataMessage}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={importerListData?.data?.data?.totalCount ? importerListData?.data?.data?.totalCount : 0}
                  rowsPerPageOptions={[5, 10, 25]}
                  page={pageNo ? pageNo : 0}
                  rowsPerPage={pageLength ? pageLength : 0}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={(e) => {
                    setPageLength(e.target.value);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Importers;
