import { configureStore } from "@reduxjs/toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import globalReducer from "./state";
import { api } from "./state/api";
import brsrReportReducer from "./state/brsrReportSlice";
import multipleCategoryReducer from "./state/multiplCategorySlice";
import socialReducer from "./state/SocialSlice";
import governanceReducer from "./state/govtSlice";
import esgTabsReducer from "./state/esgTabsSlice";

import  corruptionReducer  from "./state/antiCorruptionSlice";

const preloadedState = {};

const persistConfig = {
  key: "root",
  storage
};

//------Might uncomment later-------
// const rootReducer = combineReducers({
//   global: globalReducer,
//   multipleCategory: multipleCategoryReducer,
// });

const persistedReducer = persistReducer(persistConfig, globalReducer);

const store = configureStore({
  reducer: {
    global: persistedReducer,
    category: multipleCategoryReducer,
    report: brsrReportReducer,
    social: socialReducer,
    governance: governanceReducer,
    esgTabs: esgTabsReducer,
    corrupt:corruptionReducer,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
  devTools: process.env.NODE_ENV !== "production",
  preloadedState
});

export const persistor = persistStore(store);

// persistor.purge();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
