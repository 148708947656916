import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import imageCompression from "browser-image-compression";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import { apiUrl } from "../../../../components/ApiUrl.js";
import CustomModal from "../../../../components/Modal/Modal.jsx";
import { useSnackbar } from "../../../../components/snackbar/SnackbarContext.js";
import PdfPageDesign from "../../../../images/PdfPageDesign.svg";
import successIcon from "../../../../images/checkIcon.svg";
import deleteIcon from "../../../../images/deleteIcon.svg";
import pdfBottom from "../../../../images/pdfBottom.svg";
import jswIcon from "../../../../images/pdfJswLogo.svg";
import pdfRingImg from "../../../../images/pdfRing.svg";
import pdficon from "../../../../images/pdfIcon.svg";
import whiteFooter from "../../../../images/whiteFooterpdf.svg";
import {
  useCalculateEmissionMutation,
  useCreateInvoiceMutation,
  useGetImporterDetailsQuery,
  useGetImporterListQuery,
  useGetInvoiceQuery,
  useGetProductDetailsQuery,
  useMakePdfInvoiceMutation,
  usePatchInvoiceMutation
} from "../../../../state/api";
import "./modal.css";
const AddInvoiceModal = ({
  modalOpen,
  isGenerateForm,
  setModalOpen,
  invoiceId,
  setProductId,
  isDuplicate,
  setIsDuplicate
}) => {
  const initialForPdfData = {
    signatureImage: null,
    fullName: "",
    emailid: "",
    phoneNumber: "",
    position: "",
    place: "",
    date: ""
  };
  const [pdfPayload, setPdfPayload] = useState(initialForPdfData);
  const [signatureImage1, setSiagntureImage] = useState(null);
  const SignatureInputRef = useRef(null);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  // eslint-disable-next-line no-undef
  const initialInvoiceData = {
    importer: "",
    orders: [],
    invoiceNumber: "",
    status: "Draft",
    invoiceDate: "",
    invoiceUrl: ""
  };
  const [dataToshow, setDataToShow] = useState({
    importerToShow: ""
  });
  const [invoicePayload, setInvoicePayload] = useState(initialInvoiceData);
  const [importError, setImportError] = useState(false);
  const [invoiceName, setInvoiceName] = useState("");
  const [facilityError, setfacilityError] = useState(false);
  const [productNameError, setproductNameError] = useState(false);
  const importerListDataForDropdown = useGetImporterListQuery({ pageLength: 9999, pageNo: 0, search: "" });
  const [ImporterListForDropdownMenu, setImportrLostfroDropdownMenu] = useState([]);
  const [ImporterChooseId, setImporterChooseId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const { data: ImporterUniqueData } = useGetImporterDetailsQuery(ImporterChooseId, {
    skip: !ImporterChooseId
  });
  const headers = ["ProductName", "Weight (in tons)", "Facility Name", "Action"];
  const [TableData, setTableData] = useState([]);
  const [orderArray, setorderArray] = useState([]);
  const [InvoiceErrorState, setStateofInvoiceError] = useState(false);
  const [InvoiceError, setInvoiceError] = useState({
    importerError: false,
    ordersError: false,
    invoiceNumberError: false,
    invoiceDateError: false
  });
  const { openSnackbar } = useSnackbar();
  const currentDate = new Date().toLocaleDateString("en-IN");
  const TodaysDate = new Date();
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = TodaysDate.toLocaleDateString("en-IN", options);

  console.log(formattedDate); // Output will be in DDMMYYYY format

  const fileInputRef = useRef(null);
  const [productListOFimporter, setProductListOfImporter] = useState([]);
  const [productidToFetchFaculty, setproductidToFetchFaculty] = useState("");
  const [facilityArray, setFacilityArray] = useState([]);
  const [totalWeight, setTotalWeight] = useState();

  const { data: fetchedProduct } = useGetProductDetailsQuery(productidToFetchFaculty, {
    skip: !productidToFetchFaculty
  });
  const [emissionData, setEmissionData] = useState({
    totalEmission: "",
    directEmission: "",
    indirectEmission: ""
  });
  const { data: InvoiceDataById, refetch } = useGetInvoiceQuery(invoiceId, {
    skip: !invoiceId
  });
  const [singleObjectforTable, setSingleObjectFortABLE] = useState({
    productName: "",
    weightInTon: 0,
    facilityName: ""
  });
  const [singleObjectforCalculation, setsingleObjectforCalculation] = useState({
    product: "",
    weight: 0,
    facility: ""
  });
  const [calculateEmission] = useCalculateEmissionMutation();
  const [createInvoice] = useCreateInvoiceMutation();
  const [patchInvoice] = usePatchInvoiceMutation();
  const [MakeInvoicePdf] = useMakePdfInvoiceMutation();

  // pdfDownloadModalState
  const [downloadOpen, setDownloadOpen] = useState(false);

  const [pdfPayloadError, setPdfPayloadError] = useState({
    signatureImageError: false,
    // fullNameError: false,
    emailidError: false
    // phoneNumberError: false,
    // positionError: false,
    // placeError: false,
    // dateError: false
  });
  // pdfDownloadModalFunc
  const pdfDownloadedFunc = async () => {
    console.log("pdfPayload", pdfPayload);
    // Initialize an error object with all fields set to false
    let errorObject = {
      signatureImageError: pdfPayload?.signatureImage?.length > 2,
      emailidError: pdfPayload?.emailid?.length > 7
    };

    // Reverse the boolean values
    errorObject.signatureImageError = !errorObject.signatureImageError;
    errorObject.emailidError = !errorObject.emailidError;

    // Update the state with the new error object
    setPdfPayloadError(errorObject);
    // Check if any errors were found
    const isComplete = pdfPayload?.signatureImage?.length > 2 && pdfPayload?.emailid?.length > 7;
    console.log("isComplete", isComplete);
    if (isComplete) {
      const response = await MakeInvoicePdf({ invoiceId, pdfPayload });
      console.log("responseresponse", response?.data?.data);
      if (response?.data?.success) {
        setModalOpen(false);
        setDownloadOpen(true);

        // Assuming response?.data?.data contains the URL of the PDF
        const pdfUrl = response?.data?.data;
        // const pdfViewerUrl = `pdfViewer.html?pdfUrl=${encodeURIComponent(pdfUrl)}`;
        // window.open(pdfViewerUrl, "_blank");
        // Open the PDF URL in a new tab
        window.open(pdfUrl, "_blank");
      } else {
        console.log("payload is not filled : ", pdfPayloadError);
      }
    }
  };

  useEffect(() => {
    console.log("pdfPayloadError", pdfPayloadError, "pdfPayload", pdfPayload);
  }, [pdfPayloadError, pdfPayload]);

  useEffect(() => {
    const sortedActiveListWithIdandLabel = importerListDataForDropdown?.data?.data?.data
      ?.filter((item) => item.status === "Active") // Filter items with status "Active"
      .map((item) => {
        return { id: item?._id, label: item.importerName };
      });
    if (sortedActiveListWithIdandLabel) {
      setImportrLostfroDropdownMenu(sortedActiveListWithIdandLabel);
    }
  }, [importerListDataForDropdown]);

  useEffect(() => {
    if (invoiceId) {
      refetch();
      console.log("InvoiceDataByddId", InvoiceDataById, InvoiceDataById);
    }
  }, [invoiceId]);

  useEffect(() => {
    if (!invoiceId) {
      setTableData([]);
      setInvoicePayload(initialInvoiceData);
    }
  }, [modalOpen]);

  const [productArrrayforPdf, setProductArrayForPdf] = useState([]);
  const [facilityArrayForPdfState, setfacilityArrayForPdf] = useState([]);

  useEffect(() => {
    if (invoiceId) {
      console.log("InvoiceDataById", InvoiceDataById?.data);
      const transformedData = InvoiceDataById?.data?.orders.map((item) => ({
        product: item.product._id,
        facility: item.facility._id,
        weight: item.weight
      }));
      const facilityArray = InvoiceDataById?.data?.orders?.map((item) => item?.facility);
      const productsArray = InvoiceDataById?.data?.orders.map((order, index) => ({
        ...order.product,
        facility: facilityArray,
        weight: InvoiceDataById?.data?.orders?.[index]?.weight
      }));
      const facilityArrayForPdf = InvoiceDataById?.data?.orders?.map((order) => order?.facility);
      setfacilityArrayForPdf(facilityArrayForPdf);
      console.log("facilityArrayForPdf", facilityArrayForPdf);
      setProductArrayForPdf(productsArray);
      const transformedDataforShowing = InvoiceDataById?.data?.orders.map((item) => ({
        productName: item.product.productName,
        weightInTon: item.weight,
        facilityName: item.facility.name
      }));
      setTableData(transformedDataforShowing);
      setInvoicePayload((prevState) => ({
        ...prevState, // Correctly spread the previous state to preserve other fields
        invoiceNumber: InvoiceDataById?.data?.invoiceNumber, // Update the invoiceNumber field with the new value
        status: InvoiceDataById?.data?.status,
        importer: InvoiceDataById?.data?.importer?._id, // Update the invoiceNumber field with the new value
        orders: transformedData,
        invoiceDate: InvoiceDataById?.data?.invoiceDate,
        invoiceUrl: InvoiceDataById?.data?.invoiceUrl
      }));
      setSelectedDate(dayjs(InvoiceDataById?.data?.invoiceDate).tz("Asia/Kolkata"));
      setImporterChooseId(InvoiceDataById?.data?.importer?._id);
      setorderArray(transformedData);
      setDataToShow((prevState) => ({
        ...prevState,
        importerToShow: InvoiceDataById?.data?.importer?.importerName
      }));
      setTotalWeight(InvoiceDataById?.data?.netWeight);
    }
  }, [InvoiceDataById, modalOpen]);

  useEffect(() => {
    const payload = {
      orders: orderArray
    };
    setInvoicePayload((prevState) => ({
      ...prevState, // Spread the previous state to preserve other fields
      orders: orderArray // Update the order field with the new orderArray
    }));

    // Define an async function inside useEffect
    const fetchEmission = async () => {
      try {
        const response = await calculateEmission(payload);
        console.log("Response from calculateEmission:", response?.data?.data);
        setEmissionData((prevState) => ({
          ...prevState, // Correctly spread the previous state to preserve other fields
          totalEmission: response?.data?.data?.totalEmission,
          directEmission: response?.data?.data?.directEmission,
          indirectEmission: response?.data?.data?.indirectEmission
        }));

        // Handle the response as needed
      } catch (error) {
        console.error("Error fetching emission:", error);
        // Handle the error as needed
      }
    };

    // Immediately invoke the async function
    fetchEmission();
  }, [orderArray]); // Dependency array includes orderArray

  const handleFieldChange = (e) => {
    const data = e.target.value;
    const name = e.target.name;
    setPdfPayload((prevState) => ({
      ...prevState,
      [name]: data // Use square brackets to dynamically set the property name
    }));
  };

  const handlePdfChangeChange = (date) => {
    // Check if the date is valid
    if (!isNaN(date)) {
      const utcDate = date.toISOString();
      console.log("handlePdfChangeChange", utcDate);
      setPdfPayload((prevState) => ({
        ...prevState,
        date: utcDate // Correctly update the date property
      }));
    } else {
      console.error("Invalid date provided to handlePdfChangeChange");
      // Handle the invalid date case as needed
    }
  };

  useEffect(() => {
    console.log("pdfPayload", pdfPayload);
  }, [pdfPayload]);

  useEffect(() => {
    console.log("TableData", TableData);
    // Calculate the sum of weightInTon values
    const sum = TableData?.reduce((accumulator, currentRow) => {
      // Convert weightInTon to a number if it's not already
      const weight = Number(currentRow.weightInTon);
      // Add the weight to the accumulator
      return accumulator + weight;
    }, 0); // Start with an initial value of 0

    // Update the total weight state
    setTotalWeight(sum);
  }, [TableData]);

  useEffect(() => {
    console.log("ImporterChooseId", ImporterChooseId);
    if (ImporterUniqueData) {
      console.log("ImporterUniqueData", ImporterUniqueData?.data?.products);
      setProductListOfImporter(ImporterUniqueData?.data?.products);
      const sortedActiveListWithIdandLabel = ImporterUniqueData?.data?.products
        ?.filter((item) => item.status === "Active") // Filter items with status "Active"
        .map((item) => {
          return { id: item?._id, label: item.productName };
        });
      console.log("sortedActiveListWithIdandLabel", sortedActiveListWithIdandLabel);
      setProductListOfImporter(sortedActiveListWithIdandLabel);
    }
  }, [ImporterChooseId, ImporterUniqueData]);

  useEffect(() => {
    // Use the spread operator to create a new object with the existing InvoiceError state
    let errors = { ...InvoiceError };

    // Check if each field in invoicePayload is not an empty string
    if (invoicePayload?.importer?.trim() !== "") {
      errors.importerError = false;
    }
    if (invoicePayload?.orders?.length > 0) {
      errors.ordersError = false;
    }
    if (invoicePayload?.invoiceNumber?.trim() !== "") {
      errors.invoiceNumberError = false;
    }
    if (invoicePayload?.invoiceDate?.trim() !== "") {
      errors.invoiceDateError = false;
    }
    // Update the InvoiceError state using the spread operator
    setInvoiceError((prevState) => ({ ...prevState, ...errors }));
  }, [invoicePayload]);

  useEffect(() => {
    setImportError(false);
    setfacilityError(false);
    setproductNameError(false);
  }, [modalOpen]);

  useEffect(() => {
    if (productidToFetchFaculty) {
      console.log("fetchedProduct", productidToFetchFaculty);
    }
    if (fetchedProduct) {
      console.log("fetchedProductdata", fetchedProduct?.data?.facility);
      const sorted = fetchedProduct?.data?.facility?.map((item) => {
        return { id: item._id, label: item.name };
      });
      setFacilityArray(sorted);
    }
  }, [productidToFetchFaculty, fetchedProduct]);

  const handleFacilityInput = (event, value) => {
    console.log("on facul;ty change : ", event, value);
    const facilityNameValue = value?.label;
    // Update singleObjectForTable immutably
    setSingleObjectFortABLE((prevState) => ({
      ...prevState, // Spread the previous state to preserve other fields
      facilityName: facilityNameValue // Update the facilityName field with the new value
    }));
    setsingleObjectforCalculation((prevState) => ({
      ...prevState, // Spread the previous state to preserve other fields
      facility: value?.id // Update the facilityName field with the new value
    }));
  };

  const handleImporterChange = (event, value) => {
    setImporterChooseId(value?.id);
    // Correctly update the invoicePayload state
    setInvoicePayload((prevState) => ({
      ...prevState, // Spread the previous state to preserve other fields
      importer: value?.id // Update the importer field with the new value
    }));
  };

  const handleProductChange = (event, value) => {
    console.log("event and value are : ", event, value?.id);
    setproductidToFetchFaculty(value?.id);
    // Update singleObjectForTable immutably
    setSingleObjectFortABLE((prevState) => ({
      ...prevState, // Spread the previous state to preserve other fields
      productName: value?.label // Update the productName field with the new value
    }));
    setsingleObjectforCalculation((prevState) => ({
      ...prevState, // Spread the previous state to preserve other fields
      product: value?.id // Update the facilityName field with the new value
    }));
  };

  useEffect(() => {
    console.log("singleObjectforTable", singleObjectforTable);
  }, [singleObjectforTable]);

  const handleDateChange = (date) => {
    console.log("Selected date is:", date);
    // Convert the date to UTC format
    if (!isNaN(date)) {
      const utcDate = new Date(date).toISOString();

      setInvoicePayload((prevState) => ({
        ...prevState, // Spread the previous state to preserve other fields
        invoiceDate: utcDate // Update the invoiceDate field with the new UTC date
      }));
    } else {
      console.error("Invalid date provided to handleDateChange");
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log("setInvoiceName", file?.name);
    setInvoiceName(file?.name);
    try {
      const compressedFile = await imageCompression(file, {
        maxWidthOrHeight: 800, // Adjust as needed
        useWebWorker: true,
        maxSizeMB: 1
      });

      let formData = new FormData();
      formData.append("files", compressedFile);

      const url = `${apiUrl}/evidence/upload`;

      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData
      });

      const responseData = await response.json();

      if (response?.status) {
        console.log("Updated file URL is:", responseData?.data?.[0]?.location);
        setInvoicePayload((prevState) => ({
          ...prevState, // Spread the previous state to preserve other fields
          invoiceUrl: responseData?.data?.[0]?.location // Update the invoiceNumber field with the new value
        }));

        // Update state or handle response as needed
      } else {
        console.log("Upload failed:", responseData.error);
        // Handle upload failure
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  const handlWeightChange = (e) => {
    const weightInTonValue = e.target.value;
    console.log("handlWeightChange", weightInTonValue);
    // Update singleObjectForTable immutably
    setSingleObjectFortABLE((prevState) => ({
      ...prevState, // Spread the previous state to preserve other fields
      weightInTon: weightInTonValue // Update the weightInTon field with the new value
    }));
    setsingleObjectforCalculation((prevState) => ({
      ...prevState, // Spread the previous state to preserve other fields
      weight: Number(weightInTonValue)
    }));
  };

  const handleAdd = () => {
    // Create a new array that includes all existing items and the new item
    const updatedData = [...TableData, singleObjectforTable];
    // Update the state with the new array
    const updatedCalculationData = [...orderArray, singleObjectforCalculation];
    setTableData(updatedData);
    setorderArray(updatedCalculationData);
    // Optionally, reset the singleObjectForTable state to its initial state
    // setSingleObjectFortABLE({ productName: "", weightInTon: 0, facilityName: "" });
  };

  const handleDelete = (index) => {
    setTableData((prevData) => prevData.filter((_, i) => i !== index));
    setorderArray((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleInvoiceNumberChange = (e) => {
    console.log("handleInvoiceNumberChange", e?.target.value);
    // Correctly update the invoicePayload state
    setInvoicePayload((prevState) => ({
      ...prevState, // Spread the previous state to preserve other fields
      invoiceNumber: e?.target.value // Update the invoiceNumber field with the new value
    }));
  };

  const handleStatusChange = (e) => {
    console.log("handleInvoiceNumberChange", e?.target.value);
    // Correctly update the invoicePayload state
    setInvoicePayload((prevState) => ({
      ...prevState, // Spread the previous state to preserve other fields
      status: e?.target.value // Update the invoiceNumber field with the new value
    }));
  };

  const validateInvoiceFields = (payload) => {
    let errors = {
      importerError: false,
      ordersError: false,
      invoiceNumberError: false,
      invoiceDateError: false
    };

    if (!payload.importer) {
      errors.importerError = true;
    }
    if (payload.orders.length === 0) {
      errors.ordersError = true;
    }
    if (!payload.invoiceNumber) {
      errors.invoiceNumberError = true;
    }
    if (!payload.invoiceDate) {
      errors.invoiceDateError = true;
    }

    return errors;
  };
  const handleSubmitInvoice = async () => {
    // Validate the fields and update the InvoiceError state accordingly
    const errors = validateInvoiceFields(invoicePayload);
    setInvoiceError(errors);

    // Update InvoiceErrorState based on the presence of errors
    const hasErrors = Object.values(errors).some((error) => error);
    setStateofInvoiceError(hasErrors);

    // Only proceed with the API call if there are no errors
    if (!hasErrors) {
      setStateofInvoiceError(false);
      console.log("payload is : ", invoicePayload);
      if (invoiceId && isDuplicate === false) {
        const response = await patchInvoice({ invoiceId, invoicePayload });
        console.log("response", response);
        if (response?.data?.success) {
          openSnackbar(response?.data?.message, "success");
        }
      } else {
        const response = await createInvoice(invoicePayload);
        console.log("response", response);
        if (response?.data?.success) {
          openSnackbar(response?.data?.message, "success");
        }
      }
      setModalOpen(false);
    } else {
      console.log("huhuhhu".errors);
    }
  };
  const handleSigatureFileChange = async (event) => {
    const file = event.target.files[0];
    try {
      const compressedFile = await imageCompression(file, {
        maxWidthOrHeight: 800, // Adjust as needed
        useWebWorker: true,
        maxSizeMB: 1
      });
      console.log("compressedFile", compressedFile);
      let formData = new FormData();
      formData.append("files", compressedFile);
      const url = `${apiUrl}/evidence/upload`;

      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData
      });
      const responseData = await response.json();

      if (responseData?.success) {
        console.log("uifbjkdsv", responseData);
        console.log("Updated sinature URL is:", responseData);

        setPdfPayload((prevState) => ({
          ...prevState, // Spread the previous state to preserve other fields
          signatureImage: responseData?.data?.[0]?.location // Update the invoiceNumber field with the new value
        }));
      } else {
        console.log("Upload failed:", response);
        // Handle upload failure
      }
    } catch (error) {
      console.error("Error:fvdsfv", error);
      // Handle error
    }
    const imagePreviewUrl = URL.createObjectURL(file);
    setSiagntureImage(imagePreviewUrl);
  };

  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    if (facilityArrayForPdfState?.length > 0) {
      setPageNumber(facilityArrayForPdfState?.length + 3);
    }
  }, [facilityArrayForPdfState]);

  return (
    <>
      <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} outSideClose={true}>
        {!isGenerateForm ? (
          <Box sx={{ minWidth: 700, minHeight: 700 }}>
            <div className="d-flex" style={{ display: "flex", justifyContent: "", flexDirection: "column" }}>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {invoiceId ? (isDuplicate ? "Duplicate Invoice" : "Edit Invoice") : "Add New Invoice"}
              </Typography>
              {InvoiceErrorState && (
                <dive
                  style={{
                    marginTop: ".5rem",
                    width: "70%",
                    backgroundColor: "red",
                    height: "2rem",
                    borderRadius: ".25rem",
                    color: "#ffff",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem", // Added a value for paddingRight to avoid potential issues
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex", // Added display:flex to use justifyContent and alignItems
                    textAlign: "center" // Center align the text within the div
                  }}
                >
                  <div>
                    <ReportGmailerrorredOutlinedIcon />
                    &nbsp;Error: Please fill the mandatory fields to add new invoice.
                  </div>
                </dive>
              )}
            </div>
            <Grid container columns={10} spacing={2} className="mt-2">
              <Grid item xs={10}>
                <p className="labelTextModal">
                  Choose Importer<span style={{ color: "red" }}>*</span>
                </p>
                {invoiceId ? (
                  <Autocomplete
                    id="importer"
                    disablePortal
                    options={ImporterListForDropdownMenu}
                    value={invoiceId ? dataToshow?.importerToShow : ""}
                    onChange={handleImporterChange}
                    autoComplete="off"
                    renderInput={(params) => (
                      <TextField
                        style={{ border: importError ? "1px solid red" : "" }}
                        placeholder={!importError ? "Type or search Importer" : "Please fill in this field"}
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    id="importer"
                    disablePortal
                    options={ImporterListForDropdownMenu}
                    // value={invoiceId ? dataToshow?.importerToShow : ""}
                    onChange={handleImporterChange}
                    popupIcon={<KeyboardArrowDownIcon />}
                    autoComplete="off"
                    renderInput={(params) => (
                      <TextField
                        placeholder={
                          !InvoiceError.importerError ? "Type or search Importer" : "Importer Name can’t be empty"
                        }
                        style={{
                          border: InvoiceError.importerError ? "1px solid red" : "",
                          color: InvoiceError.importerError ? "red" : ""
                        }}
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={5}>
                <p className="labelTextModal">
                  Choose Product<span style={{ color: "red" }}>*</span>
                </p>
                <div style={{ width: "100%", display: "flex", gap: 2, position: "relative" }}>
                  <div style={{ width: "75%" }}>
                    <Autocomplete
                      id="productList"
                      disablePortal
                      // sx={{ borderTopRightRadius: "0" }}
                      options={productListOFimporter}
                      onChange={handleProductChange}
                      popupIcon={<KeyboardArrowDownIcon />}
                      renderInput={(params) => (
                        <TextField
                          // sx={{ borderTopRightRadius: "0" }}
                          style={{ border: false ? "1px solid red" : "", width: "100%" }}
                          placeholder={true ? "Search Product" : "CN Code can’t be empty"}
                          fullWidth
                          // label="Products"
                          {...params}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused, &:hover": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #D2D2D2" // Remove border
                                }
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "25%", position: "absolute", right: ".7rem", height: "100%" }}>
                    <input
                      type="text"
                      id="custom-placeholder-input"
                      placeholder="wt in ton"
                      onChange={handlWeightChange}
                      value={singleObjectforTable?.weightInTon ? singleObjectforTable?.weightInTon : ""}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        outline: "none",
                        height: "100%",
                        padding: ".5rem",
                        backgroundColor: "#F2F3F7",
                        border: "1px solid #c4c4c4",
                        borderTopLeftRadius: "0",
                        // outline: "none",
                        borderRadius: "5px"
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid sx={{ paddingLeft: ".4rem" }} item xs={5}>
                <div style={{ width: "100%", display: "flex", gap: 8 }}>
                  <div style={{ width: "80%" }}>
                    <p className="labelTextModal">
                      Facility<span style={{ color: "red" }}>*</span>
                    </p>
                    <Autocomplete
                      id="facility"
                      disablePortal
                      options={facilityArray}
                      popupIcon={<KeyboardArrowDownIcon />}
                      onChange={handleFacilityInput}
                      renderInput={(params) => (
                        <TextField
                          style={{ border: facilityError ? "1px solid red" : "" }}
                          placeholder={!facilityError ? "Choose Facility" : "Facility Name can't be empty"}
                          name="facility"
                          fullWidth
                          {...params}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused, &:hover": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #D2D2D2" // Remove border
                                }
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "stretch",
                      marginBottom: ".2rem"
                    }}
                  >
                    <Button variant="contained" onClick={handleAdd} color="primary">
                      Add
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={10} md={10}>
                <div>
                  <TableContainer sx={{ maxHeight: 180 }} style={{ background: "#F2F3F7" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead sx={{}}>
                        <TableRow>
                          {headers.map((header, index) => (
                            <TableCell
                              key={index}
                              className="fw-medium text-center"
                              style={{ fontSize: "12px", background: "#F2F3F7", color: "#16161E" }}
                            >
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {TableData.length === 0 ? (
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              style={{
                                fontSize: "12px",
                                // border: InvoiceError.ordersError ? "1px solid red" : "",
                                color: InvoiceError.ordersError ? "red" : "",
                                textAlign: "center",
                                color: "#16161E90"
                              }}
                            >
                              Map product, facility to add multiple products in one invoice
                              <br />
                              {/* <button onClick={handleAddProduct}>Add Product</button> */}
                            </TableCell>
                          </TableRow>
                        ) : (
                          TableData.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ fontSize: "12px", color: "#16161E" }}>{row.productName}</TableCell>
                              <TableCell style={{ fontSize: "12px", color: "#16161E" }}>{row.weightInTon}</TableCell>
                              <TableCell style={{ fontSize: "12px", color: "#16161E" }}>{row.facilityName}</TableCell>
                              <TableCell className="sticky-invoiceTable-cell">
                                {row.action}
                                <img
                                  src={deleteIcon}
                                  alt="icon"
                                  width={20}
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => handleDelete(index)}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
              <Grid item xs={10} md={10}>
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <div style={{ color: "#1F93EF", width: "14%", fontSize: "12px" }}>Invoice Details</div>
                  <div style={{ width: "86%", borderBottom: "1px solid #1F93EF" }}></div>
                </div>
              </Grid>
              <Grid item xs={10} md={5}>
                <p className="labelTextModal">
                  Invoice Number<span style={{ color: "red" }}>*</span>
                </p>
                <TextField
                  name="Invoice Number"
                  placeholder={
                    !InvoiceError.invoiceNumberError ? "Enter Invoice Number" : "Invoice Number can’t be empty"
                  }
                  style={{
                    border: InvoiceError.invoiceNumberError ? "1px solid red" : "",
                    color: InvoiceError.invoiceNumberError ? "red" : ""
                  }}
                  value={invoicePayload?.invoiceNumber}
                  onChange={handleInvoiceNumberChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={10} md={5}>
                <p className="labelTextModal">
                  Invoice Date<span style={{ color: "red" }}>*</span>
                </p>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      placeholder={
                        !InvoiceError.invoiceDateError ? "Enter Invoice Date" : "Importer Name can’t be empty"
                      }
                      style={{
                        border: InvoiceError.invoiceDateError ? "1px solid red" : "",
                        color: InvoiceError.invoiceDateError ? "red" : ""
                      }}
                      value={selectedDate}
                      onChange={(date) => {
                        // Format the date using Day.js
                        handleDateChange(date);
                      }}
                      slotProps={{
                        textField: {
                          sx: {
                            // border: dateError ? "1px solid red" : "",
                            width: "100%",
                            border: InvoiceError.invoiceDateError ? "1px solid red" : "",
                            color: InvoiceError.invoiceDateError ? "red" : ""
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item xs={10}>
                <p className="labelTextModal">
                  Net weight of consignment (in tons)
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <TextField
                  placeholder={!productNameError ? "Enter Net weight of consignment  " : "Please fill in this field"}
                  name="productName"
                  style={{ border: productNameError ? "1px solid red" : "", color: productNameError ? "red" : "" }}
                  value={totalWeight}
                  // onChange={handleFieldChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={10}>
                <p className="labelTextModal">Upload Invoice (optional)</p>
                <div
                  style={{
                    border: "1px solid #e8e8e8",
                    padding: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer" // Make the cursor a pointer when hovering over the div
                  }}
                  // onClick={handleDivClick} // Add onClick handler to trigger file input click
                >
                  {/* <div style={{ fontSize: "14px", color: "#707070", marginRight: "10px" }}>Drag & Drop OR</div> */}
                  <div style={{ display: "flex", alignItems: "center", columnGap: "15px" }}>
                    <div
                      style={{
                        fontSize: "16px",
                        background: "transparent",
                        border: "0",
                        boxShadow: "none",
                        color: "#9C9C9C",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      {/* {invoiceName && invoiceName.length > 2 ? invoiceName : <>Drag and Drop &nbsp;&nbsp;OR</>} */}
                      {/* {invoiceName && invoiceName.length > 2 && (
                        <CloseIcon
                          onClick={() => {
                            setInvoiceName("");
                            setInvoicePayload((prevState) => ({
                              ...prevState, // Spread the previous state to preserve other fields
                              invoiceUrl: "" // Update the invoiceNumber field with the new value
                            }));
                          }}
                          style={{ marginLeft: "5px" }}
                        />
                      )} */}
                    </div>

                    <Button
                      component="label"
                      variant="contained"
                      tabIndex={-1}
                      style={{
                        fontSize: "12px",
                        background: "#F1F1F1",
                        color: "#4E4E4E",
                        fontWeight: "600",
                        border: "0.5px solid #0003",
                        boxShadow: "none"
                      }}
                    >
                      Upload file
                      <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                    </Button>
                  </div>
                </div>
              </Grid>
              {invoiceName && invoiceName?.trim() !== "" ? (
                <Grid item xs={10}>
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      backgroundColor: "#c2c2c2",
                      borderRadius: "8px",
                      display: "flex ",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: ".34rem"
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        width: "100%",
                        padding: ".4rem",
                        display: "flex",
                        gap: 4,
                        justifyContent: "space-between"
                      }}
                    >
                      <div style={{ display: "flex", gap: 4 }}>
                        <div>
                          <img src={pdficon} alt="" />
                        </div>
                        <div>{invoiceName}</div>
                      </div>

                      <div>
                        <img
                          onClick={() => {
                            setInvoiceName("");
                            setInvoicePayload((prevState) => ({
                              ...prevState, // Spread the previous state to preserve other fields
                              invoiceUrl: "" // Update the invoiceNumber field with the new value
                            }));
                          }}
                          style={{ cursor: "pointer" }}
                          src={deleteIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              ) : (
                <div></div>
              )}

              <Grid item xs={10}>
                <p className="labelTextModal">
                  Invoice Status<span style={{ color: "red" }}>*</span>
                </p>
                <div>
                  <select
                    style={{
                      backgroundColor: invoicePayload?.status === "Active" ? `#DAFCD6` : "",
                      color: invoicePayload?.status === "Active" ? `#22B60E` : "",
                      borderRadius: "10px",
                      borderColor: "#c2c2c2"
                    }}
                    value={invoicePayload?.status}
                    onChange={handleStatusChange}
                    name="status"
                    // style={{ border: statusError ? "1px solid red" : "" }}
                    className="form-select modalInput"
                    aria-label=".form-select-lg example"
                  >
                    <option style={{ color: "black" }} value="Draft">
                      Draft
                    </option>
                    <option style={{ color: "black" }} value="Active">
                      Active
                    </option>
                    <option style={{ color: "black" }} value="Inactive">
                      Inactive
                    </option>
                  </select>
                </div>
              </Grid>

              <Grid item xs={10} md={10}>
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <div style={{ color: "#1F93EF", width: "14%", fontSize: "12px", marginRight: ".5rem" }}>
                    Emission&nbsp;Details
                  </div>
                  <div style={{ width: "86%", borderBottom: "1px solid #1F93EF" }}></div>
                </div>
              </Grid>

              <Grid item xs={10} md={5}>
                <p className="labelTextModal">
                  Direct Emissions
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <TextField
                  placeholder={!productNameError ? "Direct Emissions" : "Please fill in this field"}
                  name="Direct Emissions"
                  style={{ border: productNameError ? "1px solid red" : "", color: productNameError ? "red" : "" }}
                  value={emissionData?.directEmission}
                  // onChange={handleFieldChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={10} md={5}>
                <p className="labelTextModal">
                  Indirect Emissions
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <TextField
                  placeholder={!productNameError ? "Indirect Emissions" : "Please fill in this field"}
                  name="Indirect Emissions"
                  style={{ border: productNameError ? "1px solid red" : "", color: productNameError ? "red" : "" }}
                  value={emissionData?.indirectEmission}
                  // onChange={handleFieldChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={10}>
                <p className="labelTextModal">
                  Embedded Emissions
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <TextField
                  placeholder={!productNameError ? "Embedded Emissions" : "Please fill in this field"}
                  name="Embedded Emissions"
                  style={{ border: productNameError ? "1px solid red" : "", color: productNameError ? "red" : "" }}
                  value={emissionData?.totalEmission}
                  // onChange={handleFieldChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={5}>
                <button onClick={handleSubmitInvoice} className="btn btn-success">
                  Save Invoice
                </button>
              </Grid>
              <Grid item xs={5}>
                <button
                  className="btn btn-outline-success"
                  onClick={() => {
                    setModalOpen(false);
                    setInvoicePayload(initialInvoiceData);
                    setProductId(null);
                    setIsDuplicate(false);
                    setStateofInvoiceError(false);
                  }}
                >
                  Cancel
                </button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              maxWidth: "1200px",
              display: "flex",
              flexDirection: "column",
              width: "75vw",
              height: "82vh",
              maxHeight: "750px",
              position: "relative"
            }}
          >
            <div style={{ height: "100%" }}>
              <div
                style={{ paddingBottom: ".5rem", height: "8%" }}
                className="d-flex justify-content-between align-items-center"
              >
                <div
                  className=" d-flex flex-row column-gap-4 align-items-center justify-content-between"
                  style={{
                    fontSize: "16px"
                  }}
                >
                  <div>
                    <span className="fw-medium">Importer Name</span> : {InvoiceDataById?.data?.importer?.importerName}
                  </div>
                  <div>
                    <span className="fw-medium">Invoice no</span>: {InvoiceDataById?.data?.invoiceNumber}
                  </div>
                  <div>
                    <span className="fw-meidum">Date</span>: {currentDate}
                  </div>
                </div>
                <div style={{ display: "flex", gap: "10px", marginRight: "1rem" }}>
                  <button
                    className="btn btn-outline-success"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Go Back
                  </button>
                  <button className="btn btn-success" onClick={pdfDownloadedFunc}>
                    Download
                  </button>
                </div>
              </div>
              <div
                style={{
                  height: "95%",
                  overflow: "auto",
                  margin: "0px 0",
                  background: "#E2E2EA",
                  position: "relative"
                }}
              >
                <div
                  style={{
                    margin: "0 auto",
                    padding: "15px",
                    paddingTop: "0px",
                    width: "700px"
                  }}
                >
                  {/* the main pdf layout here */}
                  <div
                    style={{
                      margin: "0 auto",
                      padding: "15px",
                      paddingTop: "0px",
                      width: "700px"
                    }}
                  >
                    <div className="border">
                      <div style={{ position: "relative", overflow: "hidden" }}>
                        {" "}
                        {/* Parent div with relative position */}
                        <div style={{ position: "relative", zIndex: 1 }}>
                          {" "}
                          {/* First div with relative position */}
                          <div style={{ backgroundColor: "#0D47A1", height: "auto" }}>
                            <div
                              style={{
                                paddingTop: "2rem",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingLeft: "1.5rem",
                                paddingRight: "1.5rem"
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "500",
                                  fontSize: "1.5rem",
                                  color: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "1rem",
                                  background: "#FCFCFC20",
                                  padding: "0.5rem 1.5rem",
                                  borderRadius: "99px"
                                }}
                              >
                                <img
                                  style={{ height: "4rem", width: "4rem" }}
                                  src={InvoiceDataById?.data?.organization?.logo}
                                  alt=""
                                />
                              </div>
                              <div style={{ border: "1px solid #A2A2A2", width: "10rem" }}></div>
                              <div style={{ fontWeight: "500", fontSize: "1.5rem", color: "white" }}>{currentDate}</div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                height: "auto",
                                paddingRight: "",
                                paddingLeft: "17rem",
                                paddingTop: "10rem",
                                paddingBottom: "16rem",
                                gap: 130,
                                justifyContent: "space-between"
                              }}
                            >
                              <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                <div style={{ fontWeight: "600", fontSize: "45px", color: "white" }}>CBAM REPORT</div>
                                <div style={{ fontWeight: "500", fontSize: "24px", color: "white" }}>
                                  Invoice no. {InvoiceDataById?.data?.invoiceNumber}
                                </div>
                              </div>

                              <div style={{ display: "flex", flexDirection: "column", gap: 58 }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                                  <div style={{ fontWeight: "500", fontSize: "18px", color: "#A9D6B9" }}>
                                    Importer Name
                                  </div>
                                  <div style={{ fontWeight: "600", fontSize: "24px", color: "white" }}>
                                    {/* DSM Pvt Ltd{" "} */}
                                    {InvoiceDataById?.data?.importer?.importerName}
                                  </div>
                                </div>

                                <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                                  <div style={{ fontWeight: "500", fontSize: "18px", color: "#A9D6B9" }}>
                                    Exporter Name
                                  </div>
                                  <div style={{ fontWeight: "600", fontSize: "24px", color: "white" }}>
                                    {" "}
                                    {InvoiceDataById?.data?.organization?.name}{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <img
                          src={PdfPageDesign}
                          className="w-100"
                          alt=""
                          style={{ position: "absolute", top: 40, left: -70, zIndex: 2, transform: "translateX(-50%)" }}
                        />
                      </div>

                      {/* Second Page */}
                      <div className="bg-light" style={{ position: "relative", overflow: "hidden" }}>
                        <div className="px-3">
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <img src={jswIcon} alt="icon" />
                            <div className=" rounded-5 rounded-top-0 pageNo">02</div>
                          </div>
                          <div>
                            <div className="pdfHeading mb-3">Operating Company Details</div>
                            <div className="tableContainer">
                              <table className="w-100 cellText12">
                                <tr className="tableRow">
                                  <td className="tableCell w-50 px-3 py-3">1. Operating Company Name</td>
                                  <td className="w-50 px-4  py-3"> {InvoiceDataById?.data?.organization?.name} </td>
                                </tr>
                                <tr className="w-100 ">
                                  <td className="tableCell w-50 px-3 py-3">2. Operating Company UUID</td>
                                  <td className="w-50 px-4 py-3"> {InvoiceDataById?.data?.organization?._id} </td>
                                </tr>
                              </table>
                            </div>
                            <div className="pdfHeading mt-5">List of Products</div>
                            <div className="pdfSubHeading my-3"></div>
                            <div className="tableContainer">
                              <table className="w-100 cellText10">
                                <tr className="tableRow">
                                  <td style={{ fontWeight: "600" }} className="tableCell px-3 py-4 width20">
                                    Product CN Code
                                  </td>
                                  <td style={{ fontWeight: "600" }} className="tableCell px-3 py-4 width20">
                                    Product Name
                                  </td>
                                  <td style={{ fontWeight: "600" }} className="tableCell px-3 py-4 width20">
                                    Product Code
                                  </td>
                                  <td style={{ fontWeight: "600" }} className="tableCell px-3 py-4 width20">
                                    &nbsp;Weight(tons)
                                  </td>
                                  <td style={{ fontWeight: "600" }} className="px-3 py-2 width20">
                                    Facility Name
                                  </td>
                                </tr>
                                {productArrrayforPdf?.map((item, index) => {
                                  return (
                                    <tr className="tableRow">
                                      <td className="tableCell px-3 py-2 width20 text-center">{item?.cncode}</td>
                                      <td className="tableCell px-3 py-2 width20">{item?.productName}</td>
                                      <td className="tableCell px-3 py-2 width20 text-center">{item?.cncode}</td>
                                      <td className="tableCell px-3 py-2 width20 text-center">{item?.weight}</td>
                                      <td className="px-3 py-2 width20">
                                        {item?.facility?.map((item, index) => {
                                          return <div>{item?.name}</div>;
                                        })}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                        </div>
                        <img src={pdfBottom} alt="icon" className="w-100 mt-4 mb-1" />
                        <img
                          src={pdfRingImg}
                          alt="img"
                          style={{ position: "absolute", top: "0", left: "-22rem", opacity: "0.4" }}
                        />
                      </div>

                      {/* Third Page */}
                      {/* <div className="bg-light" style={{ position: "relative", overflow: "hidden" }}>
                        <div className=" px-3">
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <img src={jswIcon} alt="icon" />
                            <div className=" rounded-5 rounded-top-0 pageNo">03</div>
                          </div>
                          <div className="pdfSubHeading mb-3 mt-2">Facility 1 Details</div>
                          <div className="tableContainer">
                            <table className="w-100 cellText12" style={{ fontSize: "14px" }}>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">1. Company Name</td>
                                <td className="w-50 px-4  py-3"></td>
                              </tr>
                              <tr className="w-100 ">
                                <td className="tableCell w-50 px-3 py-3">2. Company UUID</td>
                                <td className="w-50 px-4 py-3"></td>
                              </tr>
                            </table>
                          </div>
                          <div className="pdfSubHeading mb-3" style={{ marginTop: "4rem" }}>
                            Authorized person Details
                          </div>
                          <div className="tableContainer">
                            <table className="w-100 cellText12" style={{ fontSize: "14px" }}>
                              <tr className="tableRow">
                                <td style={{ fontWeight: "600" }} className="tableCell px-3 py-4 width20">
                                  Product CN Code
                                </td>
                                <td style={{ fontWeight: "600" }} className="tableCell px-3 py-4 width20">
                                  Product Name
                                </td>
                                <td style={{ fontWeight: "600" }} className="tableCell px-3 py-4 width20">
                                  Product Code
                                </td>
                                <td style={{ fontWeight: "600" }} className="tableCell px-3 py-4 width20">
                                  &nbsp;Weight(tons)
                                </td>
                                <td style={{ fontWeight: "600" }} className="px-3 py-2 width20">
                                  Facility Name
                                </td>
                              </tr>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">15. Position of the Authorized Person</td>
                                <td className="w-50 px-4 py-3"></td>
                              </tr>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">16. Place of Authorized Person</td>
                                <td className="w-50 px-4 py-3"></td>
                              </tr>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">17. Email of Authorized Person</td>
                                <td className="w-50 px-4 py-3"></td>
                              </tr>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">18. Telephone Number</td>
                                <td className="w-50 px-4 py-3"></td>
                              </tr>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">19. Mobile Number</td>
                                <td className="w-50 px-4 py-3"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <img src={pdfBottom} alt="icon" className="w-100 mt-4 mb-1" />
                        <img
                          src={pdfRingImg}
                          alt="img"
                          style={{ position: "absolute", top: "0", left: "-22rem", opacity: "0.4" }}
                        />
                      </div> */}

                      {/* Fourth Page */}
                      {facilityArrayForPdfState?.map((item, index) => {
                        return (
                          <div className="bg-light" style={{ position: "relative", overflow: "hidden" }}>
                            <div className="d-flex justify-content-between align-items-center mb-4 px-4">
                              <img src={jswIcon} alt="icon" />
                              <div className="rounded-5 rounded-top-0 pageNo">
                                {index + 3 < 10 ? `0${index + 3}` : (index + 3).toString()}
                              </div>
                            </div>

                            <div className="px-3">
                              <div className="pdfSubHeading mb-3 mt-2">Facility {index + 1} Details</div>
                              <div className="tableContainer">
                                <table className="w-100 cellText12" style={{ fontSize: "14px" }}>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">1. Facility Name</td>
                                    <td className="w-50 px-4  py-3">{item?.name}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">2. Facility Internal Code</td>
                                    <td className="w-50 px-4 py-3">{item?.facilityCode}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">3. Facility Internal Name</td>
                                    <td className="w-50 px-4 py-3">{item?.internalName || ""}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">4. Facility UUID</td>
                                    <td className="w-50 px-4 py-3">{item.uuid || ""}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">5. Full Address</td>
                                    <td className="w-50 px-4 py-3">{item?.address}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">6. City</td>
                                    <td className="w-50 px-4 py-3">{item?.area || ""}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">7. PO Box</td>
                                    <td className="w-50 px-4 py-3">{item?.poBox || ""}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">8. Zip Code</td>
                                    <td className="w-50 px-4 py-3">{item?.zipCode}</td>
                                  </tr>
                                  <tr className="tableRow ">
                                    <td className="tableCell w-50 px-3 py-3">9. Country</td>
                                    <td className="w-50 px-4 py-3">{item?.country?.name}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">10. UNLOCODE</td>
                                    <td className="w-50 px-4 py-3">{item?.unlocode}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">11. Latitude</td>
                                    <td className="w-50 px-4 py-3">{item?.location?.lat}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">12. Longitude</td>
                                    <td className="w-50 px-4 py-3">{item?.location?.long}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">13. Economic Activity</td>
                                    <td className="w-50 px-4 py-3">{item?.economicActivity}</td>
                                  </tr>
                                </table>
                              </div>
                              <div className="pdfSubHeading mb-3" style={{ marginTop: "4rem" }}>
                                Authorized person Details
                              </div>
                              <div className="tableContainer">
                                <table className="w-100 cellText12" style={{ fontSize: "14px" }}>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">14. Name of Authorized Person</td>
                                    <td className="w-50 px-4  py-3">{item?.authName}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">15. Position of the Authorized Person</td>
                                    <td className="w-50 px-4 py-3">{item?.authPosition}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">16. Place of Authorized Person</td>
                                    <td className="w-50 px-4 py-3">{item?.authPlace}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">17. Email of Authorized Person</td>
                                    <td className="w-50 px-4 py-3">{item?.authEmail}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">18. Telephone Number</td>
                                    <td className="w-50 px-4 py-3">{item?.authTelephone}</td>
                                  </tr>
                                  <tr className="tableRow">
                                    <td className="tableCell w-50 px-3 py-3">19. Mobile Number</td>
                                    <td className="w-50 px-4 py-3">{item?.authPhone}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <img src={pdfBottom} alt="icon" className="w-100 mt-4 mb-1" />
                            <img
                              src={pdfRingImg}
                              alt="img"
                              style={{ position: "absolute", top: "10rem", left: "-22rem", opacity: "0.4" }}
                            />
                          </div>
                        );
                      })}

                      {/* Fifth Page */}
                      <div className="bg-light" style={{ position: "relative", overflow: "hidden" }}>
                        <div className="px-3" style={{ height: "60rem" }}>
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <img src={jswIcon} alt="icon" />
                            <div className="rounded-5 rounded-top-0 pageNo">
                              {pageNumber.toString().padStart(2, "0")}
                            </div>
                          </div>

                          <div className="pdfHeading mb-3">Invoice and Emission Details</div>
                          <div className="tableContainer">
                            <table className="w-100 cellText12" style={{ fontSize: "14px" }}>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">1. Invoice Number</td>
                                <td className="w-50 px-4  py-3">{InvoiceDataById?.data?.invoiceNumber}</td>
                              </tr>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">2. Weightage of Consignment</td>
                                <td className="w-50 px-4 py-3">{InvoiceDataById?.data?.netWeight}</td>
                              </tr>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">3. Total Direct Emissions (tCO2e)</td>
                                <td className="w-50 px-4 py-3">{InvoiceDataById?.data?.directEmission}</td>
                              </tr>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">4. Total Indirect Emissions (tCO2e)</td>
                                <td className="w-50 px-4 py-3">{InvoiceDataById?.data?.indirectEmission}</td>
                              </tr>
                              <tr className="tableRow">
                                <td className="tableCell w-50 px-3 py-3">5. Total Embedded Emissions (tCO2e)</td>
                                <td className="w-50 px-4 py-3">{InvoiceDataById?.data?.totalEmission}</td>
                              </tr>
                              {/* <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">6. Post Code</td>
                          <td className="w-50 px-4  py-3"></td>
                        </tr>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">7. PO Box</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">8. Country</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">9. City</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">10. UNLOCODE</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">11. Latitude</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">12. Longitude</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr> */}
                              {/* <tr className="w-100 ">
                          <td className="tableCell w-50 px-3 py-3">13. Economic Activity</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr> */}
                            </table>
                          </div>
                        </div>
                        <img src={pdfBottom} alt="icon" className="w-100 mt-4 mb-1" />
                        <img
                          src={pdfRingImg}
                          alt="img"
                          style={{ position: "absolute", top: "0", left: "-22rem", opacity: "0.4" }}
                        />
                      </div>

                      {/* Fifth Page */}
                      {/* <div className="bg-light">
                       <div className="px-3" style={{ paddingBottom: "20rem" }}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <img src={jswIcon} alt="icon" />
                      <div className=" rounded-5 rounded-top-0 pageNo">05</div>
                    </div>
                    <div className="tableContainer">
                      <table className="w-100 cellText12" style={{ fontSize: "14px" }}>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">1. Name of Authorized Person</td>
                          <td className="w-50 px-4  py-3"></td>
                        </tr>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">2. Position of the Authorized Person</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">3. Place of Authorized Person</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">4. Email of Authorized Person</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr>
                        <tr className="tableRow">
                          <td className="tableCell w-50 px-3 py-3">5. Telephone Number</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr>
                        <tr className="w-100 ">
                          <td className="tableCell w-50 px-3 py-3">5. Mobile Number</td>
                          <td className="w-50 px-4 py-3"></td>
                        </tr>
                        </table>
                          </div>
                         </div>
                          <img src={pdfBottom} alt="icon" className="w-100 mt-4 mb-1" />
                          </div> */}

                      {/* Sixth Page */}
                      <div className="bg-light" style={{ position: "relative", overflow: "hidden" }}>
                        <div className="d-flex justify-content-between px-3 align-items-center mb-4">
                          <img src={jswIcon} alt="icon" />
                          <div className=" rounded-5 rounded-top-0 pageNo">0{pageNumber + 1}</div>
                        </div>
                        <p style={{ fontSize: "20px", fontWeight: "600", color: "#0d6efd" }} className="px-3">
                          Acknowledgement and Signature
                        </p>
                        <p style={{ color: "#505050", fontSize: "15px", marginTop: "5px" }} className="px-3">
                          By signing this form, I acknowledge that the information I've given the data in this form is
                          accurate.
                        </p>
                        <div style={{ marginTop: "10px", background: "#0D47A1" }}>
                          {/* //input fields  */}
                          <div style={{ padding: "20px", zIndex: 5 }}>
                            <Grid>
                              <div style={{ fontWeight: "500", marginBottom: "4px", color: "white" }}>
                                Place of Signature <span>*</span>
                              </div>
                              <div
                                style={{
                                  padding: "10px 15px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  background: "white",
                                  border: pdfPayloadError?.signatureImageError ? "1px solid red" : "1px solid #e8e8e8",
                                  color: pdfPayloadError?.signatureImageError ? "red" : ""
                                  // placeholder={
                                  //   !pdfPayloadError.emailidError ? "Enter Invoice Date" : "Email Id can not be empty."
                                  // }
                                  // style={{
                                  //   border: pdfPayloadError?.emailidError ? "1px solid red" : "",
                                  //   color: pdfPayloadError?.emailidError ? "red" : ""
                                  // }}
                                }}
                              >
                                {signatureImage1 ? (
                                  <img
                                    style={{ width: "5rem", height: "3rem" }}
                                    src={signatureImage1}
                                    alt="Signature"
                                  />
                                ) : (
                                  <p style={{ color: "#9A9AAF", fontSize: "16px", fontWeight: "500" }}>
                                    {pdfPayloadError?.signatureImageError ? "Sign Can not be empty" : "Sign here"}
                                  </p>
                                )}

                                <div>
                                  <Button
                                    component="label"
                                    className="btn btn-outline-success"
                                    style={{
                                      fontSize: "12px",
                                      border: "1px solid #0d6efd",
                                      borderRadius: "12px",
                                      fontWeight: "600",
                                      width: "8rem"
                                    }}
                                  >
                                    Upload file
                                    <input
                                      type="file"
                                      // onClick={(event) => (event.currentTarget.value = "")}
                                      ref={SignatureInputRef}
                                      style={{ display: "none" }}
                                      onChange={handleSigatureFileChange}
                                    />
                                  </Button>
                                </div>
                              </div>
                            </Grid>
                            <Grid sx={{ marginTop: "10px" }}>
                              <div style={{ fontWeight: "500", marginBottom: "4px", color: "white" }}>Full Name</div>
                              <TextField
                                // placeholder={!productNameError ? "Enter Invoice Date" : "Please fill in this field"}
                                // name="Full Name"
                                // style={{
                                //   border: productNameError ? "1px solid red" : "",
                                //   color: productNameError ? "red" : ""
                                // }}
                                onChange={handleFieldChange}
                                value={pdfPayload?.fullName}
                                name="fullName"
                                fullWidth
                                sx={{
                                  background: "white"
                                }}
                              />
                            </Grid>
                            <Grid sx={{ marginTop: "10px" }}>
                              <div style={{ fontWeight: "500", marginBottom: "4px", color: "white" }}>
                                Email Id<span>*</span>
                              </div>
                              <TextField
                                placeholder={
                                  !pdfPayloadError.emailidError ? "Enter Invoice Date" : "Email Id can not be empty."
                                }
                                style={{
                                  border: pdfPayloadError?.emailidError ? "1px solid red" : "",
                                  color: pdfPayloadError?.emailidError ? "red" : ""
                                }}
                                onChange={handleFieldChange}
                                value={pdfPayload?.emailid}
                                name="emailid"
                                fullWidth
                                sx={{
                                  background: "white"
                                }}
                              />
                            </Grid>
                            <Grid sx={{ marginTop: "10px" }}>
                              <div style={{ fontWeight: "500", marginBottom: "4px", color: "white" }}>Phone No.</div>
                              <TextField
                                // placeholder={!productNameError ? "Enter Invoice Date" : "Please fill in this field"}
                                // name="Full Name"
                                // style={{
                                //   border: productNameError ? "1px solid red" : "",
                                //   color: productNameError ? "red" : ""
                                // }}
                                // value={productDetails?.productName}
                                // onChange={handleFieldChange}
                                onChange={handleFieldChange}
                                value={pdfPayload?.phoneNumber}
                                name="phoneNumber"
                                fullWidth
                                sx={{
                                  background: "white"
                                }}
                              />
                            </Grid>
                            <Grid sx={{ marginTop: "10px", zIndex: "5" }}>
                              <div style={{ fontWeight: "500", marginBottom: "4px", color: "white" }}>
                                Postion in the company
                              </div>
                              <TextField
                                // placeholder={!productNameError ? "Enter Invoice Date" : "Please fill in this field"}
                                // name="Full Name"
                                // style={{
                                //   border: productNameError ? "1px solid red" : "",
                                //   color: productNameError ? "red" : ""
                                // }}
                                // value={productDetails?.productName}
                                // onChange={handleFieldChange}
                                onChange={handleFieldChange}
                                value={pdfPayload?.position}
                                name="position"
                                fullWidth
                                sx={{
                                  background: "white"
                                }}
                              />
                            </Grid>
                            <Grid sx={{ marginTop: "10px" }}>
                              <div style={{ fontWeight: "500", marginBottom: "4px", color: "white" }}>Place</div>
                              <TextField
                                // placeholder={!productNameError ? "Enter Invoice Date" : "Please fill in this field"}
                                // name="Full Name"
                                // style={{
                                //   border: productNameError ? "1px solid red" : "",
                                //   color: productNameError ? "red" : ""
                                // }}
                                // value={productDetails?.productName}
                                // onChange={handleFieldChange}
                                onChange={handleFieldChange}
                                value={pdfPayload?.place}
                                name="place"
                                fullWidth
                                sx={{
                                  background: "white"
                                }}
                              />
                            </Grid>
                            <Grid sx={{ marginTop: "10px" }}>
                              <div style={{ fontWeight: "500", marginBottom: "4px", color: "white" }}>Date</div>
                              <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    onChange={handlePdfChangeChange}
                                    inputProps={{
                                      readOnly: true
                                    }}
                                    // name="date"
                                    slotProps={{
                                      textField: {
                                        sx: {
                                          // border: dateError ? "1px solid red" : "",
                                          width: "100%",
                                          background: "white"
                                        }
                                      }
                                    }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </Grid>
                          </div>
                          <img src={whiteFooter} alt="whitefooter" className="w-100" style={{ marginTop: "2rem" }} />
                        </div>
                        {/* <img
                          src={pdfRingImg}
                          alt="img"
                          style={{ position: "absolute", top: "0", left: "-22rem", opacity: "0.2", zIndex: 3 }}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <iframe
                      src={`https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf`}
                      title="PDF Display"
                      style={{ width: "100%", height: "800px", border: "none" }}
                    /> */}
                  </div>
                  {/* the main pdf layout ends herre */}
                </div>
                {/* <button
                  className="btn btn-outline-success"
                  style={{
                    width: "4rem",
                    position: "fixed",
                    bottom: "1rem",
                    left: "2rem",
                    zIndex: "99"
                  }}
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  Go Back
                </button> */}
              </div>
            </div>
          </Box>
        )}
      </CustomModal>
      <CustomModal modalOpen={downloadOpen} setModalOpen={setDownloadOpen} outSideClose={true}>
        <Box sx={{ padding: "10px 0" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
              alignItems: "center",
              width: "80%",
              margin: "0px auto",
              textAlign: "center"
            }}
          >
            <img src={successIcon} alt="icon" />
            <p style={{ fontSize: "24px", fontWeight: "500" }}>
              Invoice no._{formattedDate}_{InvoiceDataById?.data?.importer?.importerName} has been downloaded
              successfully!
            </p>
            <div>
              <button
                className="btn btn-outline-success"
                style={{
                  width: "5rem",
                  margin: "5px auto"
                }}
                onClick={() => {
                  setDownloadOpen(false);
                }}
              >
                Go Back
              </button>
            </div>
          </div>
        </Box>
      </CustomModal>
    </>
  );
};
export default AddInvoiceModal;
