// Snackbar.js
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useState } from 'react';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CustomSnackbar({ open, onClose, message, severity, autoHideDuration = 5000 }) {
  const [snackbarOpen, setSnackbarOpen] = useState(open);

  useEffect(() => {
    setSnackbarOpen(open);
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, autoHideDuration);
      return () => clearTimeout(timer);
    }
  }, [open, onClose, autoHideDuration]);

  return (
    <Snackbar open={snackbarOpen} onClose={onClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
      <div>
        <Alert onClose={onClose} severity={severity}>
          {message}
        </Alert>
      </div>
    </Snackbar>
  );
}

export default CustomSnackbar;
